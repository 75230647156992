import { createAction } from '@reduxjs/toolkit';
import PermissionDeniedError from '../../../utils/error/PermissionDeniedError';
import UnauthorizedError from '../../../utils/error/UnauthorizedError';
import { newGenericError } from '../error/newGenericError';

export const accessDenied = (url) =>
  newGenericError(new PermissionDeniedError(), { url });

export const apiRequestError = createAction('error/api/request', (error) => ({
  error,
}));

export const unauthorized = (url) =>
  newGenericError(new UnauthorizedError(), { url });
