import { useCallback, useEffect } from 'react';

export function useHotKeys(hotKeys) {
  const keyUpHandler = useCallback(
    (event) => {
      const cb = hotKeys[event.code];
      if (typeof cb !== 'function') {
        return undefined;
      }
      event.stopPropagation();
      event.preventDefault();
      cb();
      return false;
    },
    [hotKeys],
  );
  useEffect(() => {
    window.addEventListener('keyup', keyUpHandler);
    return () => window.removeEventListener('keyup', keyUpHandler);
  }, [keyUpHandler]);
}
