class ApiError extends Error {
  constructor({ apierror }) {
    super(apierror.message);

    Object.entries(apierror).forEach(([key, value]) => {
      this[key] = value;
    });
    this.name = 'ApiError';

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export default ApiError;
