import { useCallback } from 'react';
import Button from '@ingka/button';
import { useDispatch } from 'react-redux';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import styles from './RemovalInfo.module.scss';
import { AppThunkDispatch } from '../../store';
import SSRIcon from '@ingka/ssr-icon';
import { openModal } from '../../store/actions/modal';

const RemovalInfo = ({ catalogId }: { catalogId: string }) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const openUpdatesDialog = useCallback(
    () => dispatch(openModal('CatalogRemovalInfoDialog', { catalogId })),
    [dispatch, catalogId],
  );
  return (
    <div className={styles.wrapper}>
      <SSRIcon paths={informationCircle} />
      <div>New version from PIA</div>
      <Button
        size="small"
        type="emphasised"
        text="Review updates"
        onClick={openUpdatesDialog}
      />
    </div>
  );
};

export default RemovalInfo;
