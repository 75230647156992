import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTreeDataByCatalogId } from '../../store/slices/tree';
import { findFirstNode } from './helpers';
import { shortcutKeyBindings } from './helpers/shortcutKeyBindings';
import { useHoverActionsNodeConfig } from './HoverActions/useHoverActionsNodeConfig';

const dummy = { node: { id: '', catalogId: '' } };

export function useTreeShortcutKeys(catalogId) {
  const [activeId, setActiveId] = useState(null);
  const keyBindings = useKeyBindings(shortcutKeyBindings);

  const treeData = useSelector((state) =>
    selectTreeDataByCatalogId(state, catalogId),
  );
  const treeNodeItem = useMemo(
    () =>
      findFirstNode({
        treeData,
        searchMethod: (ni) => ni.node.id === activeId,
        ignoreCollapsed: true,
      }) || dummy,
    [treeData, activeId],
  );
  const { actions } = useHoverActionsNodeConfig(treeNodeItem?.node);

  const onKeyDown = useCallback(
    (e) => {
      if (!activeId) {
        return;
      }

      Object.entries(keyBindings).forEach(([actionName, match]) => {
        if (!match(e)) {
          return;
        }
        if (typeof actions[actionName] !== 'function') {
          return;
        }
        Function.prototype.apply.call(actions[actionName], null);
      });
    },
    [activeId, actions, keyBindings],
  );

  return [onKeyDown, setActiveId];
}

function useKeyBindings(keyMappings) {
  return useMemo(() => {
    function matcher(props, e) {
      return Object.entries(props).every(([prop, value]) => e[prop] === value);
    }
    return Object.entries(keyMappings).reduce(
      (bindings, [actionName, props]) => ({
        ...bindings,
        [actionName]: matcher.bind(null, props),
      }),
      {},
    );
  }, [keyMappings]);
}
