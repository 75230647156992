import React, { Component } from 'react';
import { ENV_TEST } from '../../utils/env';
import { PROPTYPE_CHILDREN_TYPE } from '../../utils/proptypes';
import styles from './ErrorBoundary.module.scss';
import UpdateApplication from '../UpdateApplication';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, reload: false };
  }

  componentDidCatch(error, errorInfo) {
    if (error?.message?.includes('Failed to fetch dynamically imported module')) {
      this.setState({
        ...this.state,
        reload: true,
      });

      return;
    }
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { errorInfo, error, reload } = this.state;
    const { children } = this.props;
    if (ENV_TEST) {
      throwTestError(errorInfo, error);
    }

    if (reload) {
      return <UpdateApplication />;
    }
    if (errorInfo || error) {
      return (
        <div>
          <h2 className={styles.title}>Something went wrong.</h2>
          <details className={styles.detailsWrapper}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

function throwTestError(errorInfo, error) {
  if (error) {
    throw error;
  } else if (errorInfo) {
    throw new Error(errorInfo);
  }
}

ErrorBoundary.propTypes = {
  children: PROPTYPE_CHILDREN_TYPE.isRequired,
};

export default ErrorBoundary;
