import { createSlice } from '@reduxjs/toolkit';
import { getDraftStatus } from '../../actions/api/catalogs';
import { removeCatalogs } from '../../actions/catalog';
import type { RootState } from '../../index';

interface DraftStatusState {
  catalogId?: string | null;
  isProcessingDraftCatalog?: boolean;
  status?: string | null;
  hasDraft?: boolean;
  previousStatus?: string | null;
}

const draftstatusSlice = createSlice({
  name: 'draftstatus',
  initialState: {} as DraftStatusState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getDraftStatus.fulfilled, (state, action) => {
        const previousStatus = state?.status;
        const status = action.payload;
        return { ...status, previousStatus };
      })
      .addCase(removeCatalogs, (state, action) => {
        const catalogIds = action.payload as string[];
        return catalogIds.some((catalogId) => catalogId === state?.catalogId)
          ? {}
          : state;
      }),
});

export default draftstatusSlice.reducer;

export const selectDraftStatus = (state: RootState) => state.draftstatus;
