import { z } from 'zod';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { editableProps } from '../../../utils/category';
import { extendFromEntries } from '../../../utils/object';
import { DELETE, GET, POST, PUT } from '.';
import { categoryFromDto, categoryToDto } from '../../mappers/dtoMappers';
import { selectMarketProps } from '../../selectors/market';
import { Category } from '../../../entities/Category';
import {
  CategoryCreateSchema,
  CategoryHistoryPaginatedSchema,
  CategorySchema,
  ImageOverridesSchema,
} from '../../../schemas/categories';
import type { RootState } from '../..';

export const getCategory = createAsyncThunk<
  {
    catalogId: string;
    category: Category;
    languageCode: string;
  },
  { categoryId: string; catalogId: string },
  { state: RootState }
>('category/getCategory', async ({ categoryId, catalogId }, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { languageCode, retailUnit } = selectMarketProps(getState());
  const categoryDto = await dispatch(
    GET({
      path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}`,
      label: `Get Category with id: ${categoryId} in Catalogue with id: ${catalogId}`,
    }),
  );
  const parsedCategory = CategorySchema.parse(categoryDto);
  const category = categoryFromDto(parsedCategory);
  return { catalogId, category, languageCode };
});

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (
    {
      categoryId,
      catalogId,
      parentCategoryId,
      redirectedCategoryId,
    }: Category & { redirectedCategoryId: string },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      DELETE({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}`,
        data: { parentCategoryId, redirectedCategoryId },
        label: `Delete Category with id: ${categoryId} from Catalogue with id: ${catalogId}`,
      }),
    );
  },
);

export const createCategory = createAsyncThunk(
  'category/createCategory',
  async ({ catalogId, parentId, ...categoryProps }: Category, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const categoryDto = await dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories`,
        data: {
          ...categoryToDto(categoryProps),
          parentCategoryId: Number(parentId),
        },
        label: `Create Category: ${categoryProps.globalCategoryName} in Catalogue with id:${catalogId}`,
      }),
    );

    const parsedCategory = CategoryCreateSchema.parse(categoryDto);

    return { catalogId, category: parsedCategory, languageCode };
  },
);

export const moveCategory = createAsyncThunk(
  'category/moveCategory',
  async (
    {
      catalogId,
      payload,
    }: {
      catalogId: string;
      payload: {
        categoryId: string;
        rank?: number;
        oldParentCategoryId?: number;
        newParentCategoryId?: number;
        placeAfterCategoryId?: number;
      };
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/move`,
        data: payload,
        label: `Move CategoryCatalogue with id: ${catalogId}`,
      }),
    );
  },
);

export const sortMarketCategory = createAsyncThunk(
  'category/sortMarketCategory',
  async (
    {
      catalogId,
      payload,
    }: {
      catalogId: string;
      payload?: {
        categoryId: string;
        rank: number;
        parentCategoryId: number;
      }[];
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/market/sort`,
        data: payload,
        label: `Move CategoryCatalogue with id: ${catalogId}`,
      }),
    );
  },
);

export const updateCategoryMainParent = createAsyncThunk(
  'category/updateCategoryMainParent',
  async (
    {
      categoryId,
      catalogId,
      mainParentCategoryId,
    }: { categoryId: string; mainParentCategoryId: string; catalogId: string },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);

    const responseDto = await dispatch(
      PUT({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/main-parent/${mainParentCategoryId}`,
        label: `Update category ${categoryId} main parent id  ${mainParentCategoryId} in Catalogue with id: ${catalogId}`,
      }),
    );
    const parsedCategory = CategorySchema.parse(responseDto);
    const category = categoryFromDto(parsedCategory);
    return { catalogId, category, languageCode };
  },
);

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async ({ catalogId, categoryId, ...categoryData }: Category, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const categoryDto = Object.entries(
      categoryToDto({ ...categoryData, categoryId }),
    )
      .filter(([prop]) => editableProps.includes(prop))
      .reduce(extendFromEntries, {});
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const responseDto = await dispatch(
      PUT({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}`,
        data: categoryDto,
        label: `Update Category with id: ${categoryId} in Catalogue with id: ${catalogId}`,
      }),
    );
    const parsedCategory = CategorySchema.parse(responseDto);
    const category = categoryFromDto(parsedCategory);
    return { catalogId, category, languageCode };
  },
);

export const getCategoryHistory = createAsyncThunk<
  z.infer<typeof CategoryHistoryPaginatedSchema>,
  { categoryId: string; pageNo: number },
  { state: RootState }
>(
  'category/getCategoryHistory',
  async (
    { categoryId, pageNo }: { categoryId: string; pageNo: number },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    const historyEvents = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/categories/${categoryId}/events?pageNo=${pageNo}`,
        label: `Fetch history for category: ${categoryId}`,
      }),
    );
    return CategoryHistoryPaginatedSchema.parse(historyEvents);
  },
);

export const getOverriddenImages = createAsyncThunk(
  'category/getOverriddenImages',
  async (
    {
      retailUnit,
      languageCode,
      catalogId,
    }: { retailUnit: string; languageCode: string; catalogId: string },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;
    const result = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/image/override`,
        label: `Fetch image overridden`,
      }),
    );
    return ImageOverridesSchema.parse(result);
  },
);
