import {
  combineReducers,
  configureStore,
  isPlain,
  SerializableStateInvariantMiddlewareOptions,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit';
import DefaultTreeConfig from '../utils/tree/config/DefaultTreeConfig';
import listenerMiddleware from './middlewares';
import * as reducers from './reducers';
import { navigation } from './middlewares/navigation';

const rootReducer = combineReducers({
  ...reducers,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        isSerializable: (value) =>
          typeof value === 'function' ||
          value instanceof Blob ||
          value instanceof DefaultTreeConfig ||
          value instanceof Error ||
          value instanceof RegExp ||
          value instanceof String ||
          isPlain(value),
        warnAfter: 128,
      } as SerializableStateInvariantMiddlewareOptions,
    })
      .prepend(listenerMiddleware.middleware)
      .concat(navigation),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, UnknownAction>;
