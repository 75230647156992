import { functionify } from '../function';

export const regExpTextField = /[<*]|&5+/;

function getCustomValidationMessage(text, regExp, messageCreator = functionify) {
  return messageCreator(regExp.exec(text));
}

export function getGenericValidationMessage(text) {
  const messageCreator = (matches) =>
    matches?.length ? `Text must not include ${matches.join('\u2004')}` : undefined;
  return getCustomValidationMessage(text, regExpTextField, messageCreator);
}
