import { downloadBlob } from '../../utils/data/utils';
import { analyticsEvent } from '../actions/analytics/analytics';
import { requestExcel } from '../actions/api/export';
import { selectCurrentMarketCode } from '../slices/intl';

export const requestExcelPendingListenerOptions = {
  actionCreator: requestExcel.pending,
  effect: (action, listenerApi) => {
    const { dispatch, getState } = listenerApi;
    const {
      meta: {
        arg: { catalogId, categoryId, includeItems },
      },
    } = action;
    const market = selectCurrentMarketCode(getState());
    dispatch(
      analyticsEvent({
        category: 'Export',
        action: 'Export excel',
        label: `${
          categoryId ? `Category ${categoryId} in ` : ''
        }Catalog ${catalogId} with items ${includeItems} for market ${market}`,
      }),
    );
  },
};

export const fileDownloadRequestFulfilledListenerOptions = {
  type: 'api/request/file/fulfilled',
  effect: (action) => {
    downloadBlob(action.payload);
  },
};
