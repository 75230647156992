import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class MarketTreeConfig extends DefaultTreeConfig {
  constructor(props: TreeConfigProps) {
    super(props);

    this.editable = props.isEditorForCurrentMarket;
    this.newCategory = this.editable;
  }
}
