import { useEffect } from 'react';
import { addListener, removeListener } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export function useListenerMiddleware(listenerOptions) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addListener(listenerOptions));
    return () => dispatch(removeListener(listenerOptions));
  }, [dispatch, listenerOptions]);
}
