export function getCountedRelations(status) {
  const relObj = getIdsFromStatus(status);
  const func = (key) => {
    const count = status.filter((s) =>
      s.paths.some((p) => p.some((i) => i === key)),
    ).length;
    relObj[key] = count;
  };
  Object.keys(relObj).forEach(func);
  return relObj;
}

export function getIdsFromStatus(status) {
  return status.reduce(
    (ids, category) =>
      Object.assign(
        ids,
        category.paths.reduce(
          (parents, path) => Object.assign(parents, getIdsFromPath(path)),
          {},
        ),
      ),
    {},
  );
}

export function getIdsFromPath(path) {
  const ids = {};
  for (let i = 0; i < path.length; i += 1) {
    const parent = path[i];
    ids[parent] = 1;
  }
  return ids;
}
