import { walkTree } from '../../components/Tree/helpers';
import { arrayify } from '../array';
import {
  CATEGORY_TYPE_ACCESSORIES,
  CATEGORY_TYPE_FURNITURE,
  CATEGORY_TYPE_SYSTEM,
} from '../category/categoryTypes';
import {
  STATE_AUTOCATEGORISABLE,
  STATE_CATEGORY_TYPE_ACCESSORIES,
  STATE_CATEGORY_TYPE_FURNITURE,
  STATE_CATEGORY_TYPE_SYSTEM,
  STATE_CATEGORY_TYPE_UNDEFINED,
  STATE_INVALID_CATEGORY_IMAGE,
  STATE_NEW,
  STATE_UNPUBLISHED,
} from '../category/states';
import { compactItemNo, isItemNo } from '../data/utils';
import { toRegExpSafeString } from '../string';
import { getNodeDataContent, getNodeDataId } from './treeNode';

export const SEARCH_BY = {
  FLAG: 'FLAG',
  TEXT: 'TEXT',
};

export const customSearchMethod = ({
  node,
  // path,
  // treeIndex,
  searchQuery,
}) => {
  if (!searchQuery || !searchQuery.by || !searchQuery.regexp) {
    return false;
  }
  if (searchQuery.by === SEARCH_BY.TEXT) {
    const toTest = [node.title];
    if (node.category) {
      toTest.push(
        node.category.categoryId || '',
        node.category.externalCategoryId || '',
        node.category.globalCategoryName || '',
      );
    } else if (node.item) {
      toTest.push(node.item.itemNo || '');
      toTest.push(
        ...arrayify(node.item.itemCountrySpecificList).map((i) => i.itemNo),
      );
    } else {
      return false;
    }
    return toTest.some((str) => searchQuery.regexp.test(str));
  }

  if (searchQuery.by === SEARCH_BY.FLAG && node.category) {
    if (searchQuery.condition === STATE_NEW) {
      return !!node.category.newCategory;
    }
    if (searchQuery.condition === node.category.publishState) {
      return (
        !node.category.newCategory &&
        node.category.publishState === STATE_UNPUBLISHED
      );
    }

    if (searchQuery.condition === STATE_AUTOCATEGORISABLE) {
      return node.category.autoCategorisable;
    }

    if (searchQuery.condition === STATE_INVALID_CATEGORY_IMAGE) {
      return (
        node.invalidCategoryImage &&
        (!!node.category.childItemCount || !!node.category.childCategoryCount)
      );
    }
    if (searchQuery.condition === STATE_CATEGORY_TYPE_ACCESSORIES) {
      return node.category.categoryType === CATEGORY_TYPE_ACCESSORIES;
    }
    if (searchQuery.condition === STATE_CATEGORY_TYPE_FURNITURE) {
      return node.category.categoryType === CATEGORY_TYPE_FURNITURE;
    }
    if (searchQuery.condition === STATE_CATEGORY_TYPE_SYSTEM) {
      return node.category.categoryType === CATEGORY_TYPE_SYSTEM;
    }

    if (searchQuery.condition === STATE_CATEGORY_TYPE_UNDEFINED) {
      return node.category.categoryType === null;
    }

    return searchQuery.condition === node.category.translateState;
  }
  return false;
};

export const getFirstXSearchResults = (treeData, searchCondition, nbrOfResults) => {
  const results = [];
  const find = (nodeItem) => {
    if (
      customSearchMethod({ node: nodeItem.node, searchQuery: searchCondition }) &&
      results.every((r) => r.id !== getNodeDataId(nodeItem.node))
    ) {
      const data = getNodeDataContent(nodeItem.node);
      if (data) {
        results.push({
          id: getNodeDataId(nodeItem.node),
          title: nodeItem.node.title,
          type: nodeItem.node.type,
          data,
        });
      }
    }
    return results.length < nbrOfResults;
  };
  walkTree({
    callback: find,
    ignoreCollapsed: false,
    treeData,
  });
  return results;
};

function getSearchRegExp(condition) {
  const query = isItemNo(condition) ? compactItemNo(condition) : condition;
  return new RegExp(toRegExpSafeString(query), 'i');
}

export function toSearchQuery(searchCondition) {
  return {
    ...searchCondition,
    regexp: searchCondition.condition && getSearchRegExp(searchCondition.condition),
  };
}
