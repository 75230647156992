export function clearData(storage, key) {
  storage.removeItem(key);
}

export function readData(storage, key) {
  const json = storage.getItem(key);
  const data = json && JSON.parse(json);

  return data;
}

export function storeData(storage, key, data) {
  const json = JSON.stringify(data);
  storage.setItem(key, json);
}
