import {
  isAutomatedCatalog,
  isCatalogDraft,
  isMasterCatalog,
  STATUS,
} from '../../../utils/catalog';
import { createShallowEqualResultSelector } from '../utils';
import { selectCatalog, selectCatalogs } from '.';

export const selectDraftProductsCatalog = createShallowEqualResultSelector(
  selectCatalogs,
  (catalogs) => catalogs.find((catalog) => isMasterCatalog(catalog, STATUS.DRAFT)),
);

export const selectIsCatalogDraft = createShallowEqualResultSelector(
  selectCatalog,
  (catalog) => isCatalogDraft(catalog),
);

export const selectIsCatalogAutomated = createShallowEqualResultSelector(
  selectCatalog,
  (catalog) => isAutomatedCatalog(catalog),
);
