import { walkTree } from '.';
import { NodeBase, NodeBaseItem } from '../../../entities/Node';

export function getUnfoldedNodeIds(treeData: NodeBase[]) {
  const ids: string[] = [];
  const callback = ({ node }: NodeBaseItem) => {
    if (node.expanded) {
      ids.push(node.id);
    }
  };
  walkTree({ callback, ignoreCollapsed: true, treeData });
  return ids;
}
