import { useAllIndicators } from '../../../../hooks/useIndicators';
import StatusBadges from '../../../StatusBadges';
import { createContentNode } from '../createContentNode';
import Title from '../Title';
import { NodeBase } from '../../../../entities/Node';
import { LegacyRef, forwardRef } from 'react';

const Catalog = forwardRef(function Catalog(
  {
    node: { catalogId, catalog, title },
  }: {
    node: NodeBase;
  },
  ref: LegacyRef<HTMLDivElement>,
) {
  const { badges, summaries } = useAllIndicators(catalogId, catalog);
  return (
    <div ref={ref} className="u-flex">
      <StatusBadges className="" badges={badges} summaries={summaries} compact />
      <Title title={title} />
    </div>
  );
});

export default createContentNode(Catalog);
