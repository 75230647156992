import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GLOBAL, makeMarketCode } from '../../../utils/market';
import { createReferenceEqualSelector } from '../../selectors/utils';

interface IntlState {
  languageCode: string;
  retailUnit: string;
  marketCode?: string;
}

const initialState: IntlState = {
  languageCode: '',
  retailUnit: '',
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    setMarket: {
      prepare({
        retailUnit,
        languageCode,
      }: {
        retailUnit: string;
        languageCode: string;
      }) {
        return {
          meta: null,
          error: null,
          payload: {
            retailUnit,
            languageCode,
            marketCode: makeMarketCode(retailUnit, languageCode) as string,
          },
        };
      },
      reducer(state: IntlState, action: PayloadAction<IntlState>) {
        const { languageCode, marketCode, retailUnit } = action.payload;
        return {
          ...state,
          languageCode,
          marketCode,
          retailUnit,
        };
      },
    },
  },
});

export const { setMarket } = intlSlice.actions;

export default intlSlice.reducer;

export const intlCreateSelector = (state: { intl: IntlState }) => state.intl;

export const selectCurrentLanguageCode = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => state.languageCode,
);

export const selectCurrentMarketCode = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => state.marketCode || `${GLOBAL}-en`,
);
export const selectCurrentRetailUnit = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => state.retailUnit,
);

export const selectCurrentRetailCode = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => (state.retailUnit === GLOBAL ? 'GBL' : state.retailUnit),
);
export const selectIsGlobalMarket = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => state.retailUnit === GLOBAL,
);
export const selectIsRetailMarket = createReferenceEqualSelector(
  intlCreateSelector,
  (state) => state.retailUnit !== GLOBAL,
);
