import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUncategorizedItems } from '../../../components/Tree/helpers/uncategorized';
import { createUncategorizedNodes } from '../../../utils/item/createUncategorizedNodes';
import { getCatalogId } from '../../../utils/tree/ids';
import { selectCategoriesForPredictions } from '../../selectors/category/predictions';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { selectTreeState } from '../../selectors/tree/data';
import { updateData } from '../tree';

export const toggleSuggestions = createAsyncThunk(
  'tree/toggleSuggestions',
  async ({ tree, showPredictions }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState();
    const treeData = selectTreeDataByTreeId(state, tree);
    const catalogId = getCatalogId(tree);
    const uncategorizedItems = getUncategorizedItems(treeData);
    const categories = selectCategoriesForPredictions(state);
    const expandedNodes = selectTreeState(state, catalogId);
    const nodes = createUncategorizedNodes({
      catalogId,
      categories,
      items: uncategorizedItems,
      showPredictions,
      expandedIds: expandedNodes,
    });
    return dispatch(updateData(tree, nodes));
  },
);
