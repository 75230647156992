import { addDays } from 'date-fns/addDays';

import { toDatePickerValue } from '../../../../utils/date';
import { INTERVALS } from '../../../actions/reports/notvalidforstrucutreitems/constants';

export function getDatesFromInterval(
  interval: number,
  fromDate: string,
  toDate: string,
) {
  if (interval === INTERVALS.CUSTOM && fromDate && toDate) {
    return [toDatePickerValue(fromDate), toDatePickerValue(toDate)];
  }
  const dateVal = new Date();
  const today = new Date(
    dateVal.getFullYear(),
    dateVal.getMonth(),
    dateVal.getDate(),
  );
  switch (interval) {
    case INTERVALS.LAST_DAY: {
      const from = addDays(today, -1);
      return [toDatePickerValue(from), toDatePickerValue(today)];
    }
    case INTERVALS.LAST_MONTH: {
      const from = addDays(today, -30);
      return [toDatePickerValue(from), toDatePickerValue(today)];
    }
    case INTERVALS.LAST_WEEK: {
      const from = addDays(today, -7);
      return [toDatePickerValue(from), toDatePickerValue(today)];
    }
    default:
      return [toDatePickerValue(today), toDatePickerValue(today)];
  }
}
