import { createAction } from '@reduxjs/toolkit';
import { arrayify } from '../../../utils/array';

export const deleteCategories = createAction(
  'tree/deleteCategories',
  (tree, categories) => ({
    meta: { tree },
    payload: { categories: arrayify(categories) },
  }),
);
