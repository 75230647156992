import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { CircleHalf } from '../../Icons/paths';
import styles from './ToggleThemeButton.module.scss';
import { analyticsEvent } from '../../../store/actions/analytics/analytics';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const ToggleThemeButton = function ToggleThemeButton({ className }) {
  const dispatch = useDispatch();
  const [darkThemePersisted, setDarkThemePersisted] = useLocalStorage(
    'rc-user-settings-dark-theme',
    undefined,
  );

  const darkTheme =
    darkThemePersisted === undefined
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
      : darkThemePersisted;

  const [theme, setTheme] = useState(darkTheme);
  const toggleTheme = useCallback(() => {
    setDarkThemePersisted(!theme);
    dispatch(
      analyticsEvent({
        category: 'Theme',
        action: 'Toggle theme',
        label: `Toggled theme to ${theme ? 'dark' : 'light'}`,
      }),
    );
    setTheme(!theme);
  }, [theme, dispatch, setDarkThemePersisted]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.classList.remove('dark', 'light');
    body.classList.add(theme ? 'dark' : 'light');
  }, [theme]);

  return (
    <Tooltip
      interactiveCustomTrigger
      tooltipText="Toggle theme"
      position="bottom"
      className={className}
    >
      <Button
        className={cx(styles.theme, className)}
        onClick={toggleTheme}
        iconOnly
        size="small"
        aria-label="Toggle theme"
        ssrIcon={CircleHalf}
        type="tertiary"
      />
    </Tooltip>
  );
};

ToggleThemeButton.propTypes = {
  className: PropTypes.string,
};

export default ToggleThemeButton;
