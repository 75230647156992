import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { AppThunkDispatch } from '../../store';
import { setAuthContext } from '../../store/slices/auth0/auth0';
import { setRoles } from '../../store/slices/roles';
import { AUTH0_CLIENT_ID } from '../../utils/auth0/constants';
import { bootstrapApplication } from '../../store/actions/bootstrap';
import { IS_DEV } from '../../utils/env';
import { getUser } from '../../store/actions/api/user';
import { selectAppHasFailed } from '../../store/slices/app';
import FakeHeader from '../Header/FakeHeader';
import { AppStartFailed } from './AppStartFailed';

const AUTH0_ACCESSTOKEN_FIRSTNAME = 'https://accounts.ikea.com/givenname';
const AUTH0_ACCESSTOKEN_LASTNAME = 'https://accounts.ikea.com/lastname';
const AUTH0_ACCESSTOKEN_NETWORKID = 'https://accounts.ikea.com/networkid';
const AUTH0_ACCESSTOKEN_ROLES = 'https://accounts.ikea.com/groups';
const AUTH0_ACCESSTOKEN_EMAIL = 'https://accounts.ikea.com/email';

type AccessToken = JwtPayload & {
  [AUTH0_ACCESSTOKEN_EMAIL]: string;
  [AUTH0_ACCESSTOKEN_FIRSTNAME]: string;
  [AUTH0_ACCESSTOKEN_ROLES]: string[];
  [AUTH0_ACCESSTOKEN_LASTNAME]: string;
  [AUTH0_ACCESSTOKEN_NETWORKID]: string;
};

export type AuthUser = {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  sub: string;
};

export const Auth0Wrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const auth0 = useAuth0<AuthUser>();
  const appStartFailed = useSelector(selectAppHasFailed);

  const {
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    user,
    getIdTokenClaims,
  } = auth0;

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const getToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const decodedAccessToken = await jwtDecode<AccessToken>(accessToken);
          await dispatch(setAuthContext(auth0));

          if (IS_DEV) {
            const user = await dispatch(getUser()).unwrap();
            dispatch(setRoles(user.groups));
          } else {
            await dispatch(setRoles(decodedAccessToken[AUTH0_ACCESSTOKEN_ROLES]));
          }

          await dispatch(bootstrapApplication(window.location.href));
        } catch (error) {
          // failed or invalid access token get refresh token, logout
          auth0.logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
            clientId: AUTH0_CLIENT_ID,
          });
        }
      };
      getToken();
    }
  }, [
    user,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    dispatch,
    auth0,
    getIdTokenClaims,
  ]);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname + window.location.search,
      },
    });
    return null;
  }

  if (appStartFailed) return <AppStartFailed />;

  if (!isAuthenticated || isLoading) return <FakeHeader />;

  return children;
};
