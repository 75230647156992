import * as STATES from '../../utils/category/states';
import { AutoCategorisedBadge } from './AutoCategorisedBadge';
import {
  CategoryAccessoryBadge,
  CategoryFurnitureBadge,
  CategorySystemBadge,
  CategoryUndefined,
} from './CategoryTypeBadge';
import { InvalidImageBadge } from './InvalidImageBadge';
import * as PublishBadges from './PublishBadges';
import * as TranslationBadges from './TranslationBadges';

export default {
  [STATES.STATE_NOT_AVAILABLE_TO_MARKET]: PublishBadges.NotAvailable,
  [STATES.STATE_NEW]: PublishBadges.New,
  [STATES.STATE_UNPUBLISHED]: PublishBadges.Hide,
  [STATES.STATE_UNTRANSLATED]: TranslationBadges.Untranslated,
  [STATES.STATE_NOT_VERIFIED]: TranslationBadges.NotVerified,
  [STATES.STATE_NOT_VERIFIED_AUTO_TRANSLATED]: TranslationBadges.AutoTranslated,
  [STATES.STATE_NOT_VERIFIED_RENAMED]: TranslationBadges.NotVerifiedRenamed,
  [STATES.STATE_AUTOCATEGORISABLE]: AutoCategorisedBadge,
  [STATES.STATE_CATEGORY_TYPE_ACCESSORIES]: CategoryAccessoryBadge,
  [STATES.STATE_CATEGORY_TYPE_SYSTEM]: CategorySystemBadge,
  [STATES.STATE_CATEGORY_TYPE_FURNITURE]: CategoryFurnitureBadge,
  [STATES.STATE_CATEGORY_TYPE_UNDEFINED]: CategoryUndefined,
  [STATES.STATE_INVALID_CATEGORY_IMAGE]: InvalidImageBadge,
};
