import { getNodeDataId } from '../../../utils/tree/treeNode';
import { removeData, storeData } from '../pond';

export const createLoadingActionsFromKey = (key) => ({
  startLoading: () => storeData({ key, data: true }),
  stopLoading: () => removeData({ key }),
});

export const createLoadingActionsFromNode = (node) =>
  createLoadingActionsFromKey(getNodeDataId(node));
