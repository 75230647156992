class CreateDraftCatalogError extends Error {
  constructor(error) {
    super(
      `${error.message}\n\nIf you do not see the Draft Catalogue in the Catalogue selector, please try to reload the application and look again.`,
    );

    Object.entries(error).forEach(([key, value]) => {
      this[key] = value;
    });
    this.name = 'CreateDraftCatalogError';

    Object.setPrototypeOf(this, CreateDraftCatalogError.prototype);
  }
}

export default CreateDraftCatalogError;
