import { createAsyncThunk } from '@reduxjs/toolkit';
import { CENTRUM_URL } from '../../../utils/env';
import ApiError from '../../../utils/error/ApiError';
import NotImplementedError from '../../../utils/error/NotImplementedError';
import RequestFailedError from '../../../utils/error/RequestFailedError';
import { log } from '../../../utils/log';
import { extendDeep } from '../../../utils/object';
import { API_REQUEST, API_FILE_REQUEST } from '../../constants/api';
import { accessDenied, apiRequestError, unauthorized } from './errors';
import {
  HEADER_ACCEPT,
  HEADER_AUTHORIZATION,
  HEADER_CONTENT_TYPE,
} from './helpers/headers';
import {
  METHOD_TYPE_DELETE,
  METHOD_TYPE_GET,
  METHOD_TYPE_POST,
  METHOD_TYPE_PUT,
} from './helpers/methods';
import { MIME_TYPE_JSON } from './helpers/mime-types';
import {
  parseContentDispositionHeader,
  parseContentTypeHeader,
} from './helpers/parsers';
import { AUTH0_CLIENT_ID } from '../../../utils/auth0/constants';

export const DELETE = createApiRequest(METHOD_TYPE_DELETE);
export const FILE = createApiRequest(METHOD_TYPE_GET, API_FILE_REQUEST);
export const GET = createApiRequest(METHOD_TYPE_GET);
export const POST = createApiRequest(METHOD_TYPE_POST);
export const PUT = createApiRequest(METHOD_TYPE_PUT);

const defaultHeaders = {
  [HEADER_ACCEPT]: MIME_TYPE_JSON,
  [HEADER_CONTENT_TYPE]: MIME_TYPE_JSON,
};

const getAuthHeaders = async (state) => {
  const { client } = state.auth0Client;
  try {
    const accessToken = await client.getAccessTokenSilently();
    return { [HEADER_AUTHORIZATION]: `Bearer ${accessToken}` };
  } catch (error) {
    return {};
  }
};

function createApiRequest(method, requestType = API_REQUEST) {
  const thunk = createAsyncThunk(
    `api/request/${
      requestType !== API_FILE_REQUEST ? method.toLowerCase() : 'file'
    }`,
    async (payload, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      const {
        path,
        data = null,
        ignoreError = false,
        formData = false,
        onSuccess = null,
        onFailure = () => {},
        headers = defaultHeaders,
        label,
      } = payload;
      log(label);
      const authHeaders = await getAuthHeaders(getState());
      const opts = extendDeep(
        {
          body: formData ? data : JSON.stringify(data),
          headers,
          method,
        },
        authHeaders,
        'headers',
      );

      if (method === METHOD_TYPE_GET) {
        delete opts.body;
      }

      return request({
        dispatch,
        getState,
        opts,
        path,
      })
        .then((responseData) => {
          if (onSuccess) {
            dispatch(onSuccess(responseData));
          }

          return responseData;
        })
        .catch((error) => {
          if (!ignoreError) {
            dispatch(apiRequestError(error));
          }
          onFailure(error);

          return Promise.reject(error);
        });
    },
    {
      getPendingMeta() {
        return { method, requestType };
      },
    },
  );

  return (args) => async (dispatch) => dispatch(thunk(args)).unwrap();
}

function request({ dispatch, getState, opts, path }) {
  return fetch(`${CENTRUM_URL}${path}`, opts)
    .then((response) => {
      const { contentType, isBlob, isJson, isText } = parseContentTypeHeader(
        response.headers,
      );

      if (isText && !response.ok) {
        return response.text().then((text) => Promise.reject(new Error(text)));
      }

      if (!response.ok) {
        return response
          .json()
          .catch(() => Promise.reject(new RequestFailedError(response)))
          .then((error) => {
            if (error.apierror) {
              return Promise.reject(new ApiError(error));
            }
            return Promise.reject(new Error(error.message));
          });
      }

      return new Promise((resolve, reject) => {
        if (response.status === 204) {
          resolve();
        } else if (isJson) {
          resolve(response.json());
        } else if (isBlob) {
          resolve(response.blob());
        } else if (isText) {
          resolve(response.text());
        } else if (!contentType) {
          resolve();
        } else {
          reject(new NotImplementedError('Unknown response'));
        }
      }).then((content) => {
        const { filename } = parseContentDispositionHeader(response.headers);
        if (filename && isBlob) {
          return new File([content], filename, {
            type: (content && content.type) || contentType,
          });
        }
        return content;
      });
    })
    .catch((error) => {
      const state = getState();
      const { client } = state.auth0Client;
      if (error.response && error.response.status === 401) {
        dispatch(unauthorized(window.location.pathname));
        return Promise.reject(error);
      }
      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(path));
        client.logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
          clientId: AUTH0_CLIENT_ID,
        });
      }

      return Promise.reject(error);
    });
}
