class NotImplementedError extends Error {
  constructor(extMessage) {
    super(`Not implemented: ${extMessage}`);

    this.name = 'NotImplementedError';

    Object.setPrototypeOf(this, NotImplementedError.prototype);
  }
}

export default NotImplementedError;
