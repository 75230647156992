import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { ReadOnlyObject } from '../../../utils/object/ReadOnlyObject';
import {
  createShallowEqualResultSelector,
  createStructuredSelector,
  getCatalogIdFromTreeId,
  getIdFromArgument,
} from '../utils';
import { selectSelectedNodeItem, selectSelectedNodeItems } from '.';

const selectedNodeItemsSelector = createStructuredSelector({
  selectedNodeItem: selectSelectedNodeItem,
  selectedNodeItems: selectSelectedNodeItems,
});

export const selectedTreeNodeSelector = createShallowEqualResultSelector(
  selectSelectedNodeItem,
  (selectedNodeItem) => {
    const [catalogId, nodeItem] = keyValueToEntries(selectedNodeItem);
    return {
      catalogId,
      node: nodeItem && nodeItem.node,
      nodeItem,
    };
  },
);

export const selectedNodeItemSelector = createShallowEqualResultSelector(
  selectSelectedNodeItem,
  (selectedNodeItem) => {
    const [, nodeItem] = keyValueToEntries(selectedNodeItem);
    return nodeItem;
  },
);

export const selectedTreeNodesSelector = createShallowEqualResultSelector(
  selectedNodeItemsSelector,
  ({ selectedNodeItem, selectedNodeItems }) => {
    const [catalogId, nodeItem] = keyValueToEntries(selectedNodeItem);
    const [, nodeItems = ReadOnlyArray.Empty] = keyValueToEntries(selectedNodeItems);
    return {
      catalogId,
      nodeItems: [...nodeItems],
      selectedNodeItem: nodeItem,
    };
  },
);

export const selectedTreeNodesByTreeIdSelector = createShallowEqualResultSelector(
  selectedNodeItemsSelector,
  getCatalogIdFromTreeId,
  ({ selectedNodeItem, selectedNodeItems }, catalogId) => ({
    selectedNodeItem: selectedNodeItem?.[catalogId],
    nodeItems: selectedNodeItems?.[catalogId],
  }),
);

function selectedKeys({ selectedNodeItem, selectedNodeItems }, catalogId) {
  const nodeItem = selectedNodeItem?.[catalogId];
  const nodeItems = selectedNodeItems?.[catalogId];
  if (nodeItems?.length) {
    return nodeItems.map((ni) => ni.node.id);
  }
  if (nodeItem) {
    return [nodeItem.node.id];
  }
  return ReadOnlyArray.Empty;
}

export const selectedTreeNodeKeysByCatalogIdSelector =
  createShallowEqualResultSelector(
    selectedNodeItemsSelector,
    getIdFromArgument,
    selectedKeys,
  );

export const selectedTreeNodeKeysByTreeIdSelector = createShallowEqualResultSelector(
  selectedNodeItemsSelector,
  getCatalogIdFromTreeId,
  selectedKeys,
);

function keyValueToEntries(obj) {
  return Object.entries(obj || ReadOnlyObject.Empty)[0] || ReadOnlyArray.Empty;
}

export const selectAlterSuggestionItemIds = createShallowEqualResultSelector(
  selectedNodeItemsSelector,
  (state, node) => node,
  ({ selectedNodeItems }, node) => {
    if (node.itemNo) {
      return [node.item.itemId];
    }
    const items = new Set();
    const [, nodeItems] = keyValueToEntries(selectedNodeItems);
    nodeItems?.forEach((ni) => {
      items.add(ni.node.item.itemId);
    });
    return Array.from(items);
  },
);
