import React from 'react';
import cx from 'classnames';
import Tooltip from '@ingka/tooltip';
import { TreeNodeProps } from 'rc-tree';
import { Cabinet, Folder, Processor } from '..';
import calendar from '@ingka/ssr-icon/paths/calendar';

import shoppingBag from '@ingka/ssr-icon/paths/shopping-bag';
import link from '@ingka/ssr-icon/paths/link';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_DATE_GROUP,
  NODE_TYPE_SUGGESTION,
} from '../../../utils/types/nodeTypes';
import styles from './NodeIcon.module.scss';
import { destructId } from '../../../utils/tree/treeNode';
import FolderFilled from '../../../svg/FolderFilled';
import { NodeBase } from '../../../entities/Node';
import SSRIcon from '@ingka/ssr-icon';

const nodeIcons: {
  [key: string]: React.ElementType;
} = {
  [NODE_TYPE_CATALOG]: Cabinet,
  [NODE_TYPE_CATEGORY]: Folder,
  [NODE_TYPE_DATE_GROUP]: () => <SSRIcon paths={calendar} />,
  [NODE_TYPE_SUGGESTION]: Processor,
};

const IconComponent = function IconComponent({
  node,
  ...props
}: {
  node: NodeBase;
}) {
  const getIcon = () => {
    if (node.type === NODE_TYPE_CATEGORY) {
      const categoryMainParentId = node.category.mainParentCategoryId;
      const currectCategoryParentId = destructId(node?.parentId).value;
      if (categoryMainParentId && categoryMainParentId !== currectCategoryParentId) {
        // category is "related" category
        return (
          <Tooltip
            interactiveCustomTrigger
            tooltipText="Related Category"
            position="trailing"
          >
            <SSRIcon paths={link} className={styles.relatedCategory} />
          </Tooltip>
        );
      }

      if (categoryMainParentId && categoryMainParentId === currectCategoryParentId) {
        return (
          <Tooltip
            interactiveCustomTrigger
            tooltipText="Main Category"
            position="trailing"
          >
            <FolderFilled />
          </Tooltip>
        );
      }
    }
    if (node.type === NODE_TYPE_CATALOG && node?.catalog?.isMissing) {
      return <SSRIcon paths={shoppingBag} />;
    }

    const Icon = nodeIcons[node.type];

    return (
      <div className={styles.nodeIcon}>
        <Icon {...props} node={node} />
      </div>
    );
  };

  return <div className={cx(styles.container)}>{getIcon()}</div>;
};
const NodeIcon = function NodeIcon({ data, ...props }: TreeNodeProps) {
  const node = data as NodeBase;
  const type = node?.type || '';

  if (!data) return null;

  if (nodeIcons[type]) {
    return <IconComponent {...props} node={node} />;
  }
  return null;
};

export default NodeIcon;
