import { removeNodesFromTree } from '../../../components/Tree/helpers';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { selectedTreeNodeSelector } from '../../selectors/tree/node';
import { updateData } from '../tree';
import { resetSelectedNode } from '../../slices/tree';

export const removeNodes = (tree, paths) => async (dispatch, getState) => {
  const currentTreeData = selectTreeDataByTreeId(getState(), tree);
  const newTree = removeNodesFromTree({ paths, treeData: currentTreeData });
  await dispatch(updateData(tree, newTree.treeData));
  const { nodeItem } = selectedTreeNodeSelector(getState());
  if (nodeItem && paths.includes(nodeItem.path)) {
    await dispatch(resetSelectedNode());
  }
};

export const removeNode = (tree, path) => removeNodes(tree, [path]);
