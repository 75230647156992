import { includes } from '../../../utils/array';
import { makeRetailUnitRoleName, ROLE_GLOBAL } from '../../../utils/roles/roles';
import { createReferenceEqualSelector } from '../utils';
import { selectCurrentRetailUnit } from '../../slices/intl';
import { selectRoles } from '../../slices/roles';
import type { RootState } from '../..';

export const selectHasRoles = createReferenceEqualSelector(
  selectRoles,
  (_, roles) => roles,
  (userProfileRoles, roles) => includes(userProfileRoles, roles),
);

export const selectHasGlobalEditorRole = createReferenceEqualSelector(
  (state) => state.roles,
  (roles) => roles.includes(ROLE_GLOBAL),
);

export const selectHasRetailUnitRole = (state: RootState, retailUnit: string) =>
  selectHasRoles(state, [makeRetailUnitRoleName(retailUnit)]);

export const selectIsEditorForCurrentMarket = createReferenceEqualSelector(
  selectRoles,
  selectCurrentRetailUnit,
  (userProfileRoles, retailUnit) =>
    !!retailUnit && includes(userProfileRoles, [makeRetailUnitRoleName(retailUnit)]),
);
