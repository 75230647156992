import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { DataChart } from '../../Icons/paths';
import { openModal } from '../../../store/actions/modal';
import { AppThunkDispatch } from '../../../store';

export const ReportsLink = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const onClick = () => {
    dispatch(openModal('ReportsDialog'));
  };
  return (
    <Tooltip
      interactiveCustomTrigger
      tooltipText="Select reports type"
      position="bottom"
    >
      <Button
        type="tertiary"
        aria-label="Reports"
        iconOnly
        size="small"
        onClick={onClick}
        ssrIcon={DataChart}
      />
    </Tooltip>
  );
};
