import { MISSING_ITEMS_CATALOG } from '../../catalog/missingitems';
import { NOT_VALID_FOR_STRUCURE_CATALOG } from '../../catalog/notvalidforstructure';
import { UNCATEGORIZED_CATALOG } from '../../catalog/uncategorized';
import { formatItemNo } from '../../data/utils';
import { getItemId } from '../../item';
import { ITEM_TYPE_ART } from '../../types/itemTypes';
import { NODE_TYPE_ITEM } from '../../types/nodeTypes';
import { MISSING_ITEM } from '../../../store/constants/importitems';
import { ItemDTO } from '../../../store/mappers/dtoMappers';
import { createId } from '../treeNode';

export type ItemNode = ReturnType<typeof createItemNode>;

export function countrySpecificToItemNodes({
  catalogId,
  items = [],
  level,
  parentId,
  isLeaf,
  isInDraft,
  isInSuggestion,
}: {
  catalogId: string;
  items: {
    itemNo: string;
    itemType: string;
    itemNameOfficial: string;
    itemName?: string;
  }[];
  level: number;
  parentId: string;
  isLeaf: boolean;
  isInSuggestion?: boolean;
  isInDraft?: boolean;
}) {
  if (!items) return [];

  return items.map((item) => ({
    id: createId({ parentId, type: NODE_TYPE_ITEM, contentId: getItemId(item) }),
    key: createId({ parentId, type: NODE_TYPE_ITEM, contentId: getItemId(item) }),
    itemType: item.itemType,
    itemNoFormated: formatItemNo(item.itemNo),
    itemNo: item.itemNo,
    title: item.itemName ?? MISSING_ITEM,
    role: 'treeitem',
    type: 'item',
    item,
    children: [],
    expanded: false,
    isLeaf,
    isChildItem: true,
    level,
    parentId,
    catalogId,
    isUncategorized: [
      UNCATEGORIZED_CATALOG.catalogId,
      MISSING_ITEMS_CATALOG.catalogId,
    ].includes(catalogId),
    isInDraft,
    isInSuggestion,
  }));
}

export function toItemNodes({
  catalogId,
  items = [],
  level,
  parentId,
  isLeaf,
  isInDraft,
  isInSuggestion,
}: {
  catalogId: string;
  items?: ItemDTO[];
  level: number;
  parentId: string;
  isLeaf?: boolean;
  isInSuggestion?: boolean;
  isInDraft?: boolean;
}) {
  if (!items) return [];

  return items.map((item) =>
    createItemNode({
      catalogId,
      isLeaf,
      item,
      level,
      parentId,
      isInDraft,
      isInSuggestion,
    }),
  );
}

export function toNotValidForStructureNodes(parentId: string, items: ItemDTO[]) {
  return items.map((item) =>
    createItemNode({
      catalogId: NOT_VALID_FOR_STRUCURE_CATALOG.catalogId,
      item,
      level: 1,
      parentId,
    }),
  );
}

export function toUncategorizedItemNodes(
  parentId: string,
  level: number,
  items: (ItemDTO & { inDraft: boolean })[],
) {
  return items.map((item) =>
    createItemNode({
      catalogId: UNCATEGORIZED_CATALOG.catalogId,
      isInDraft: item.inDraft,
      isInSuggestion: level > 1,
      item,
      level,
      parentId,
    }),
  );
}

const toItemChildrenNode = ({
  items,
  catalogId,
  isLeaf,
  level,
  parentId,
  isInDraft,
  isInSuggestion,
  isUncategorized,
}: {
  items: {
    itemName: string;
    itemNo: string;
    itemType: string;
    itemNameOfficial: string;
    mainParent?: string | null | undefined;
    isLeaf?: boolean;
    isUncategorized?: boolean;
  }[];
  catalogId: string;
  isLeaf: boolean;
  level: number;
  parentId: string;
  isInSuggestion?: boolean;
  isInDraft?: boolean;
  isUncategorized?: boolean;
}) =>
  items.map((item) => ({
    id: createId({ parentId, type: NODE_TYPE_ITEM, contentId: getItemId(item) }),
    key: createId({ parentId, type: NODE_TYPE_ITEM, contentId: getItemId(item) }),
    itemType: item.itemType,
    itemNoFormated: formatItemNo(item.itemNo),
    itemNo: item.itemNo,
    title: item.itemName ?? MISSING_ITEM,
    role: 'treeitem',
    type: 'item',
    item,
    children: [],
    expanded: false,
    isLeaf,
    isChildItem: true,
    level,
    parentId,
    catalogId,
    isUncategorized,
    isInDraft,
    isInSuggestion,
  }));

function createItemNode({
  catalogId,
  isInDraft,
  isLeaf,
  isInSuggestion,
  item,
  level,
  parentId,
}: {
  catalogId: string;
  item: ItemDTO;
  level: number;
  parentId: string;
  isLeaf?: boolean;
  isInSuggestion?: boolean;
  isInDraft?: boolean;
}) {
  const { itemName, itemNo, itemType, itemCountrySpecificList = [] } = item;

  const leaf = isLeaf ?? (ITEM_TYPE_ART && !item.countrySpecificItemCount);
  const countrySpecificItems = itemCountrySpecificList
    .filter((f) => !!f.itemNameOfficial)
    .map((m) => ({
      ...m,
      itemName: m.itemNameOfficial,
      mainParent: item.mainParent,
      isLeaf: true,
      isUncategorized: false,
    }));

  const contentId = getItemId(item);
  const id = createId({ parentId, type: NODE_TYPE_ITEM, contentId });

  const isUncategorized = [
    UNCATEGORIZED_CATALOG.catalogId,
    MISSING_ITEMS_CATALOG.catalogId,
  ].includes(catalogId);

  return {
    catalogId,
    id,
    key: id,
    allowChildItems: true,
    item,
    itemNo,
    itemNoFormated: formatItemNo(itemNo),
    itemType,
    type: 'item',
    title: itemName ?? MISSING_ITEM,
    level,
    role: 'treeitem',
    isInSuggestion,
    isInDraft,
    isLeaf: leaf,
    parentId,
    isUncategorized,
    children: toItemChildrenNode({
      catalogId,
      isLeaf: true,
      items: countrySpecificItems,
      level: level + 1,
      parentId,
      isInDraft,
      isUncategorized,
    }),
  };
}
