import cx from 'classnames';
import DataPlate from '../DataPlate/DataPlate';
import styles from './ItemNoPlate.module.scss';
import Tooltip from '@ingka/tooltip';

type ItemNoPlateProps = {
  showRelations?: boolean;
  isMissingItem?: boolean;
  itemNoFormated: string;
  isMainItem?: boolean;
};

const ItemNoPlate = function ItemNoPlate({
  showRelations = false,
  isMissingItem = false,
  isMainItem = false,
  itemNoFormated,
}: ItemNoPlateProps) {
  return (
    <>
      {showRelations ? (
        <Tooltip
          position="trailing"
          interactiveCustomTrigger
          tooltipText={`${isMainItem ? 'Main' : 'Related'} item`}
        >
          <DataPlate
            className={cx(styles.itemNo, {
              [styles.missing]: isMissingItem,
              [styles.relatedItem]: showRelations && !isMainItem,
              [styles.mainItem]: showRelations && isMainItem,
            })}
          >
            {itemNoFormated}
          </DataPlate>
        </Tooltip>
      ) : (
        <DataPlate
          className={cx(styles.itemNo, {
            [styles.missing]: isMissingItem,
            [styles.relatedItem]: showRelations && !isMainItem,
            [styles.mainItem]: showRelations && isMainItem,
          })}
        >
          {itemNoFormated}
        </DataPlate>
      )}
    </>
  );
};

export default ItemNoPlate;
