import { fetchCatalogs } from '../catalog/fetchCatalogs';
import { setMarket } from '../intl';
import { closeModal, openModal } from '.';
import { resetPanes } from '../../slices/pane';
import { analyticsEvent } from '../analytics/analytics';

export const closeMarketSelector = () => closeModal();

export const selectMarketAndClose =
  (retailUnit, languageCode) => async (dispatch) => {
    dispatch(
      analyticsEvent({
        category: 'Market Selector',
        action: 'Change market',
        label: `Switched to ${retailUnit} with language code ${languageCode}`,
      }),
    );
    dispatch(resetPanes());
    await dispatch(setMarket({ retailUnit, languageCode }));
    await dispatch(fetchCatalogs());
    return dispatch(closeModal());
  };

export const openMarketSelector = () => openModal('MarketDialog');
