import { z } from 'zod';
import { PaginationSchema } from './general';

export const ItemHistory = z.array(
  z.object({
    eventTimestamp: z.string(),
    userName: z.string().nullable(),
    action: z.string(),
    categoryName: z.string().nullable(),
    externalCategoryId: z.string().nullable(),
  }),
);

const itemCountrySpecificList = z
  .array(
    z.object({
      itemNo: z.string(),
      itemType: z.string(),
      itemName: z.string().optional(),
      itemNameOfficial: z.string(),
    }),
  )
  .nullable()
  .optional();

export const AutoCategorizedItemSchema = z.object({
  itemNo: z.string(),
  itemType: z.string(),
  itemNameOfficial: z.string(),
  homeFurnishingBusinessName: z.string().nullable(),
  homeFurnishingBusinessNo: z.string().nullable(),
  categoryId: z.number(),
  externalCategoryId: z.string(),
  categoryName: z.string(),
  eventTimestamp: z.string(),
  source: z.string(),
  itemCountrySpecificList: z.array(z.string()).nullable(),
});

export const ItemDetails = z.object({
  itemNo: z.string(),
  itemType: z.string(),
  itemNameOfficial: z.string(),
  startDateSale: z.string().nullable(),
  endDateSale: z.string().nullable(),
  productName: z.string().nullable(),
  productTypeName: z.string().nullable(),
  homeFurnishingBusinessName: z.string().nullable(),
  homeFurnishingBusinessNo: z.string().nullable(),
  productAreaName: z.string().nullable(),
  productAreaNo: z.string().nullable(),
  imageName: z.string().nullable(),
  validForStructure: z.boolean(),
  showable: z.boolean(),
  itemStyleGroup: z.string().nullable(),
  mainParentDTOs: z
    .array(
      z.object({
        parentCategoryId: z
          .number()
          .transform((val) => `${val}`)
          .nullable(),
        parentCategoryName: z.string().nullable(),
        parentExternalCategoryId: z.string().nullable(),
        mainParent: z.boolean(),
      }),
    )
    .nullable(),
  itemContentDTOList: z
    .array(
      z.object({
        itemNo: z.string(),
        itemType: z.string().nullable(),
        itemName: z.string().nullable(),
        itemQuantity: z.number(),
      }),
    )
    .nullable(),
  itemCountrySpecificList,
});

export const ItemSchema = z.object({
  itemNo: z.string(),
  itemType: z.string().nullable(),
  itemName: z.string().nullable(),
  countrySpecificItemCount: z.number().nullable().optional(),
  itemCountrySpecificList,
  productImageCategoryIds: z
    .array(z.number().transform((val) => `${val}`))
    .nullable()
    .optional(),
  mainParent: z
    .number()
    .transform((val) => `${val}`)
    .optional()
    .nullable(),
});

export const ItemsSchema = PaginationSchema.extend({
  content: z.array(ItemSchema),
});

export const AutoCategorizedItemsReport = PaginationSchema.extend({
  content: z.array(AutoCategorizedItemSchema),
});

export const ItemHistorySchema = PaginationSchema.extend({
  content: ItemHistory,
});

export const UncategorizedItemsSchema = z.array(
  z.object({
    itemNo: z.string(),
    itemType: z.string(),
    itemName: z.string(),
    productName: z.string().nullable(),
    salesStartDate: z.string().nullable(),
    suggestedCategories: z
      .array(
        z.object({
          externalCategoryId: z.string(),
          categoryId: z.number().transform((val) => `${val}`),
          globalCategoryName: z.string(),
        }),
      )
      .nullable(),
    inDraft: z.boolean(),
  }),
);

export const AddItemsSchema = z.object({
  addedItems: z.array(
    z
      .object({
        itemName: z.string(),
        itemNo: z.string(),
        itemType: z.string(),
        mainParent: z
          .number()
          .transform((val) => `${val}`)
          .optional()
          .nullable(),
      })
      .nullable(),
  ),
  existingItems: z.array(
    z
      .object({
        itemName: z.string(),
        itemNo: z.string(),
        itemType: z.string(),
      })
      .nullable(),
  ),
  notValidForStructureItems: z.array(
    z
      .object({
        itemName: z.string(),
        itemNo: z.string(),
        itemType: z.string(),
      })
      .nullable(),
  ),
});

export const AddItemsToMultipleSchema = z.array(AddItemsSchema);

export const MissingItemsSchema = z.object({
  categories: z
    .array(
      z.object({
        categoryId: z.number().transform((val) => `${val}`),
        childCategories: z.array(z.number()).nullable(),
        childItems: z.array(z.string()).nullable(),
        externalCategoryId: z.string(),
        globalCategoryName: z.string(),
      }),
    )
    .nullable(),
  items: z.array(ItemSchema).nullable(),
  topCategories: z.array(z.number()).nullable(),
});

export const ItemRelationsSchema = z.object({
  catalogueList: z.array(
    z
      .number()
      .transform((val) => `${val}`)
      .nullable(),
  ),
  categoryWithCountrySpecificItemList: z.array(
    z.object({
      categoryId: z.number().transform((val) => `${val}`),
      itemCountrySpecificList,
    }),
  ),
  itemName: z.string().nullable(),
  itemNo: z.string(),
  itemType: z.string().nullable(),
  notValidForStructure: z.boolean(),
  structureReasonText: z.string().nullable(),
  productImageCategoryIds: z
    .array(z.number().transform((val) => `${val}`))
    .nullable()
    .optional(),
  uncategorised: z.boolean(),
  mainParent: z
    .number()
    .transform((val) => `${val}`)
    .nullable()
    .optional(),
});

export const MarketSalesDateSchema = z.array(
  z.object({
    startDateSale: z.string().optional(),
    retailUnit: z.string(),
    showable: z.boolean(),
    endDateSale: z.string().optional(),
  }),
);

export const NotValidForStructureItemsSchema = PaginationSchema.extend({
  content: z.array(
    z.object({
      itemName: z.string(),
      itemNo: z.string(),
      itemType: z.string(),
      structureReasonText: z.string().nullable(),
    }),
  ),
});

export const NotValidForStructureReportsSchema = PaginationSchema.extend({
  content: z.array(
    z.object({
      itemNo: z.string(),
      itemType: z.string(),
      itemName: z.string(),
      homeFurnishingBusinessNo: z.string().nullable(),
      newValidForStructure: z.boolean(),
      newStructureReasonText: z.string(),
      oldValidForStructure: z.boolean(),
      oldStructureReasonText: z.string().nullable(),
      updatedDate: z.string(),
    }),
  ),
});

export const ItemGroupingReportsSchema = z.object({
  totalItemCount: z.number(),
  totalPercentageGrouped: z.number(),
  artPercentageGrouped: z.number(),
  sprPercentageGrouped: z.number(),
  hfbReportDTOList: z.array(
    z.object({
      hfbNo: z.string(),
      hfbName: z.string().nullable(),
      totalItemCount: z.number(),
      totalPercentageGrouped: z.number(),
      artPercentageGrouped: z.number(),
      sprPercentageGrouped: z.number(),
    }),
  ),
});

export const ItemImage = z.object({
  itemNo: z.string(),
  productName: z.string(),
  productType: z.string(),
  imageName: z.string(),
});
