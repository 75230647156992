import cx from 'classnames';
import { arrayify } from '../../../utils/array';
import { functionify } from '../../../utils/function';
import { extendDeep } from '../../../utils/object';
import { createUpdatedNode, ROOT_NODE_ID } from '../../../utils/tree/node';
import {
  addNodeUnderParent,
  changeNodeAtPath,
  find,
  getNodeAtPath,
  insertNode,
  isDescendant,
  map,
  removeNode,
  toggleExpandedForAll,
  walk,
} from '../../../utils/tree/tree-data-utils';
import {
  comparePathedIds,
  getContentIdPairsFromPathId,
  getContentIdFromProperty,
  getPathById,
} from '../../../utils/tree/treeNode';
import {
  GROUP_TYPES,
  NODE_TYPE_CATALOG,
  NODE_TYPE_MULTI_SELECT,
} from '../../../utils/types/nodeTypes';

export { isDescendant };

export function getNodeKey({ node }) {
  return node.id;
}

export function addNodesInTree(newNodes, treeData, options = {}) {
  return arrayify(newNodes)
    .reduce((nodes, node) => [...nodes, ...getAllReferences({ treeData, node })], [])
    .reduce(
      (previousTreeObject, newNode) => {
        const path = getPathById(newNode.id);
        const isNew = !getNodeInTree({
          treeData: previousTreeObject.treeData,
          path,
        });
        const data = {
          ...options,
          getNodeKey,
          ignoreCollapsed: false,
          newNode,
          treeData: previousTreeObject.treeData,
        };
        if (isNew) {
          data.parentKey =
            newNode.parentId !== ROOT_NODE_ID ? newNode.parentId : undefined;
          const index = Number(options.childIndex);
          if (Number.isNaN(index)) {
            return addNodeUnderParent(data);
          }
          return insertNodeAtChildIndex(data);
        }
        data.path = path;
        return { treeData: changeNodeAtPath(data) };
      },
      { treeData },
    );
}

function getAllReferences({ treeData, node }) {
  const parents = getParentNodeItemsInTree({ treeData, node }).map(
    (parentNodeItem) =>
      createUpdatedNode({
        node,
        nextParentNode: parentNodeItem.node,
      }),
  );
  if (parents.length) {
    return parents;
  }
  return [node];
}

export function getNodeInTree({ treeData, path }) {
  const nodeInfo = getNodeAtPath({
    treeData,
    path,
    getNodeKey,
    ignoreCollapsed: false,
  });
  return nodeInfo && { path, ...nodeInfo };
}

export function getNodesInTree({ treeData, paths }) {
  return paths.reduce((result, path) => {
    const nodeInfo = getNodeInTree({ treeData, path });
    return nodeInfo ? [...result, nodeInfo] : result;
  }, []);
}

export function getChildNodesInTree({ treeData, node }) {
  const paths = findNodes({
    treeData,
    searchMethod: (ni) => ni.node.parentId === node.id,
  }).matches.map((m) => m.path);
  return getNodesInTree({ treeData, paths });
}

export function getParentNodeItemsInTree({ treeData, node }) {
  const { parentId } = node;
  const paths = findNodes({
    treeData,
    searchMethod: (ni) => comparePathedIds(ni.node.id, parentId),
  }).matches.map((m) => m.path);
  return getNodesInTree({ treeData, paths });
}

export function insertNodeAfter({ afterNodeItem, newNode, treeData }) {
  const { path } = afterNodeItem;
  const { treeIndex } = getNodeInTree({ treeData, path });
  return insertNodeInTree({
    newNode,
    treeData,
    treeIndex: treeIndex + 1,
    expandParent: false,
  });
}

export function insertNodeAtChildIndex({ childIndex, newNode, treeData }) {
  const path = getPathById(newNode.parentId);
  const { node, treeIndex } = getNodeInTree({ treeData, path });
  const atNode =
    childIndex > 0 &&
    node.children[Math.min(Math.max(0, childIndex - 1), node.children.length - 1)];
  const atIndex = atNode
    ? getNodeInTree({ treeData, path: getPathById(atNode.id) }).treeIndex
    : treeIndex;

  return insertNodeInTree({
    newNode,
    treeData,
    treeIndex: atIndex + 1,
    expandParent: false,
  });
}

function insertNodeInTree({ newNode, treeData, treeIndex, expandParent = true }) {
  return insertNode({
    depth: newNode.level,
    expandParent,
    getNodeKey,
    ignoreCollapsed: false,
    minimumTreeIndex: treeIndex,
    newNode,
    treeData,
  });
}

export function updateNodeInTree({ node, path, treeData }) {
  return changeNodeAtPath({
    getNodeKey,
    newNode: node,
    path,
    treeData,
  });
}

export function updateNodesInTree({ nodeItems, treeData }) {
  return nodeItems.reduce(
    (tree, { node, path }) => updateNodeInTree({ node, path, treeData: tree }),
    treeData,
  );
}

export function updateMatchingNodesInTree(searchMethod, treeData, data) {
  const result = findNodes({ treeData, searchMethod });
  const func = functionify(data);
  return updateNodesInTree({
    nodeItems: result.matches.map((ni) => extendDeep(ni, func(ni), 'node')),
    treeData,
  });
}

export function removeNodeFromTree({ path, treeData }) {
  if (!getNodeInTree({ path, treeData })) {
    return { treeData };
  }
  return removeNode({
    getNodeKey,
    path,
    treeData,
  });
}

export function removeNodesFromTree({ paths, treeData }) {
  const allReferencesPaths = paths.reduce((all, path) => {
    const parts = [...path];
    const nodeId = parts.pop();
    const parentId = parts.pop();
    const [key, value] = getContentIdPairsFromPathId(nodeId);
    const searchMethod =
      key === 'type' ||
      GROUP_TYPES.includes(key.slice(0, -2)) ||
      value === NODE_TYPE_MULTI_SELECT
        ? (ni) => ni.node.id === nodeId
        : (ni) => {
            const contentId =
              key === 'categoryId' && value === 'NEW-CATEGORY'
                ? getContentIdPairsFromPathId(ni.node.id, key)[1]
                : getContentIdFromProperty(ni, key);
            return (
              contentId === value && comparePathedIds(ni.node.parentId, parentId)
            );
          };
    return [
      ...all,
      ...findNodes({
        treeData,
        searchMethod,
      }).matches.map((m) => m.path),
    ];
  }, []);

  return allReferencesPaths.reduce(
    (tree, path) => {
      const {
        treeData: newTreeData,
        node,
        treeIndex,
      } = removeNodeFromTree({ path, treeData: tree.treeData });
      if (node) {
        tree.treeData = newTreeData;
        tree.nodes.push(node);
        tree.treeIndexes.push(treeIndex);
      }
      return tree;
    },
    { nodes: [], treeData, treeIndexes: [] },
  );
}

export function findNodes({
  treeData,
  searchFocusOffset,
  searchMethod,
  searchQuery,
}) {
  return find({
    getNodeKey,
    treeData,
    searchQuery,
    searchMethod,
    searchFocusOffset,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: false,
  });
}

export function findFirstNode({ treeData, searchMethod, ignoreCollapsed }) {
  let foundItem;
  const callback = (nodeItem) => {
    if (searchMethod(nodeItem)) {
      foundItem = nodeItem;
    }
    return !foundItem;
  };
  walk({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed,
  });

  return foundItem;
}

export function getNodeItemsBetween({ treeData, fromId, toId }) {
  const nodeItems = [];
  let skip = true;
  let foundFirst = false;
  let foundLast = false;
  const callback = (nodeItem) => {
    const { node } = nodeItem;
    foundFirst = foundFirst || (skip && (node.id === fromId || node.id === toId));
    foundLast =
      foundFirst &&
      (foundLast || (!skip && (node.id === fromId || node.id === toId)));
    if (foundFirst) {
      skip = foundLast;
      nodeItems.push(nodeItem);
    }
    return !foundLast;
  };
  walk({
    treeData,
    getNodeKey,
    callback,
  });

  return nodeItems;
}

export function mapNodeItems({ treeData, callback, ignoreCollapsed = true }) {
  return map({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed,
  });
}

export function setExpandedPropOnAllNodes({ treeData, expanded = false }) {
  return toggleExpandedForAll({ treeData, expanded });
}

export function toggleExpandedPropOnNode({ treeData, node }) {
  const expanded = !node.expanded;
  const path = getPathById(node.id);
  return updateNodeInTree({ treeData, path, node: { ...node, expanded } });
}

export function walkTree({ treeData, callback, ignoreCollapsed }) {
  return walk({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed,
  });
}

export function withSearchResults({
  treeData,
  searchMatches,
  searchFocusId,
  expandMatches,
}) {
  return treeData.map((item) => {
    const isMatch = searchMatches.some((sm) => sm.node.id === item.id);
    const isFocused = searchFocusId === item.id;
    const expanded = expandMatches
      ? item.type === NODE_TYPE_CATALOG
      : !!item.expanded;
    const className = cx({
      ...toClassNamesObject(item.className),
      'rc-tree-treenode-searchhit': isMatch,
      'rc-tree-treenode-searchfocus': isFocused,
    });
    if (item.children) {
      const children = withSearchResults({
        treeData: item.children,
        searchMatches,
        searchFocusId,
        expandMatches,
      });
      return {
        ...item,
        className,
        children,
        expanded:
          expanded ||
          (expandMatches &&
            children.some(
              (c) => c.expanded || c.className.match(/rc-tree-treenode-searchhit/),
            )),
      };
    }

    return { ...item, className, expanded: item.type === NODE_TYPE_CATALOG };
  });
}

export function withSuggestions({ treeData, matches, focusId, expandMatches }) {
  return treeData.map((item) => {
    const isMatch = matches.some((sm) => sm.node.id === item.id);
    const isFocused = focusId === item.id;
    const expanded = expandMatches
      ? item.type === NODE_TYPE_CATALOG
      : !!item.expanded;
    const className = cx({
      ...toClassNamesObject(item.className),
      suggestion: isMatch,
      'suggestion-focus': isFocused,
    });
    if (item.children) {
      const children = withSuggestions({
        treeData: item.children,
        matches,
        focusId,
        expandMatches,
      });
      return {
        ...item,
        className,
        children,
        expanded:
          expanded ||
          (expandMatches &&
            children.some((c) => c.expanded || c.className.match(/suggestion/))),
      };
    }

    return { ...item, className, expanded: item.type === NODE_TYPE_CATALOG };
  });
}

function toClassNamesObject(classNames = '') {
  return classNames
    .split(/\s+/)
    .filter((cn) => cn.length)
    .reduce((cns, cn) => Object.assign(cns, { [cn]: true }), {});
}
