import Badge from './Badge';
import styles from './StatusBadges.module.scss';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import { RemoveDuplicate } from '../Icons';

export const Deleted = function Deleted({ compact }: { compact: boolean }) {
  return (
    <Badge
      className={styles.deleted}
      compact={compact}
      iconPath={trashCan}
      label="Deleted"
      tooltip="Deleted"
    />
  );
};

export const RemovedDoubleExposure = function RemovedDoubleExposure({
  compact,
}: {
  compact: boolean;
}) {
  return (
    <Badge
      className={styles.deleted}
      compact={compact}
      CustomIcon={RemoveDuplicate}
      label="Removed double exposure"
      tooltip="Removed double exposure"
    />
  );
};

export const DeletedCount = function DeletedCount({
  compact,
  count,
}: {
  compact: boolean;
  count: number;
}) {
  return (
    <Badge
      className={styles.deletedInverted}
      compact={compact}
      label={`${count} deleted`}
      labelCompact={count.toString()}
      tooltip="Deleted"
    />
  );
};
