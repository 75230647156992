import cx from 'classnames';
import styles from './DataPlate.module.scss';

const DataPlate = function DataPlate({
  className,
  ...props
}: {
  className: string;
  children: React.ReactNode;
}) {
  return <span className={cx(styles.plate, className)} {...props} />;
};
export default DataPlate;
