import { createListenerMiddleware } from '@reduxjs/toolkit';
import { errorListenerOptions } from '../slices/error/errorListenerOptions';
import { catalogReceivedListenerOptions } from '../slices/catalog/listeners';
import { fileDownloadRequestFulfilledListenerOptions } from './export';
import type { AppDispatch, RootState } from '..';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>();

startAppListening(fileDownloadRequestFulfilledListenerOptions);
// @ts-expect-error infer issue in rtk
startAppListening(errorListenerOptions);
startAppListening(catalogReceivedListenerOptions);

export default listenerMiddleware;
