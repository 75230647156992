import { getCatalogId } from '../../../utils/tree/ids';
import { NODE_TYPE_MULTI_SELECT } from '../../../utils/types/nodeTypes';
import { selectedTreeNodesSelector } from '../../selectors/tree/node';
import { copyNode } from '../tree';

export const nodeMenuCopy = (tree, nodeItem) => async (dispatch, getState) => {
  if (!tree || !nodeItem) {
    return undefined;
  }

  const { catalogId, nodeItems } = selectedTreeNodesSelector(getState());
  const items = [];
  if (nodeItem.node.type === NODE_TYPE_MULTI_SELECT) {
    items.push(...nodeItems);
  } else if (
    getCatalogId(tree) !== catalogId ||
    !nodeItems.length ||
    nodeItems.every((ni) => ni.node.id !== nodeItem.node.id)
  ) {
    items.push(nodeItem);
  } else {
    items.push(...nodeItems);
  }

  return dispatch(copyNode(tree, items));
};
