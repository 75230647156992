import React, { LegacyRef, forwardRef, useMemo } from 'react';
import cx from 'classnames';
import ItemNoPlate from '../../../ItemNoPlate';
import { createContentNode } from '../createContentNode';
import Title from '../Title';
import styles from './Item.module.scss';
import { getLeafCategoryForItem } from '../../../../utils/tree/treeNode';
import { NodeItem } from '../../../../entities/Node';
import { useSelector } from 'react-redux';
import { selectIsGlobalMarket } from '../../../../store/slices/intl';
import { RootState } from '../../../../store';
import { selectCatalog } from '../../../../store/slices/catalog';

export const ItemComponent = forwardRef(function ItemNode(
  { node, hover = true }: { node: NodeItem; hover?: boolean },
  ref: LegacyRef<HTMLDivElement>,
) {
  const leafCategoryId = getLeafCategoryForItem(node.parentId);
  const isGlobalMarket = useSelector(selectIsGlobalMarket) as boolean;
  const catalog = useSelector((state: RootState) =>
    selectCatalog(state, node.catalogId),
  );

  const isProductsCatalog = catalog?.globalCatalogIdentifier === 'products';

  const { isInDraft, item, itemNoFormated, title } = node;
  const memoTitle = useMemo(() => {
    const itemCount = item.childItemCount;
    return `${title}${itemCount ? ` (${itemCount})` : ''}`;
  }, [item, title]);

  return (
    <div
      ref={ref}
      className={cx(styles.titleWrapper, {
        'u-color-lightest': isInDraft,
        'u-line-through': isInDraft,
        [styles.singleItem]: node.isLeaf && hover,
      })}
    >
      <ItemNoPlate
        showRelations={
          isGlobalMarket && node.item.mainParent !== undefined && isProductsCatalog
        }
        isMainItem={leafCategoryId === node.item.mainParent}
        itemNoFormated={itemNoFormated || ''}
        isMissingItem={item.isMissing}
      />
      {node.item.itemType === 'SPR' && <div className={styles.sprItem}>SPR</div>}
      <Title className={styles.ellipsis} title={memoTitle} />
    </div>
  );
});

export default createContentNode(ItemComponent);
