import React, { PropsWithChildren } from 'react';
import SkapaHyperlink from '@ingka/hyperlink';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './Link.module.scss';

type LinkProps = {
  asButton?: boolean;
  asIcon?: boolean;
  label: string;
  href: string;
  newWindow?: boolean;
  rel?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Link = ({
  asButton,
  asIcon,
  className,
  onClick,
  label,
  href,
  newWindow,
  rel,
  ...props
}: PropsWithChildren<LinkProps>) => (
  <SkapaHyperlink
    onClick={onClick}
    aria-label={label}
    className={cx(
      {
        [styles.button]: asButton,
        [styles.icon]: asButton && asIcon,
      },
      className,
    )}
    colour={asButton ? 'black' : undefined}
    button={asButton}
    url={href}
    newWindow={newWindow}
    rel={newWindow ? rel || 'noreferrer' : undefined}
    {...props}
  />
);

Link.propTypes = {
  asButton: PropTypes.bool,
  asIcon: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  newWindow: PropTypes.bool,
  rel: PropTypes.string,
};

export default Link;
