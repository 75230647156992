import { useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import history from '@ingka/ssr-icon/paths/history';
import information from '@ingka/ssr-icon/paths/information-circle';
import image from '@ingka/ssr-icon/paths/image';
import list from '@ingka/ssr-icon/paths/list';
import linkin from '@ingka/ssr-icon/paths/link';
import { selectIsGlobalMarket } from '../../../store/slices/intl';
import { analyticsEvent } from '../../../store/actions/analytics/analytics';
import type { AppThunkDispatch } from '../../../store';
import { ANALYTICS_PROPERTIES_PANE } from '../../../store/actions/analytics/analyticsCategories';
import styles from '../PropertiesPane.module.scss';
import SSRIcon from '@ingka/ssr-icon';

export type TabTypes =
  | 'History'
  | 'Details'
  | 'Images'
  | 'ItemStartEnd'
  | 'Relations';

const TabIcons: {
  [KEY in TabTypes]: { icon: JSX.Element; title: string; globalOnly?: boolean };
} = {
  Details: {
    icon: <SSRIcon paths={information} className={styles.tabIcon} />,
    title: 'Details',
  },
  History: {
    icon: <SSRIcon paths={history} className={styles.tabIcon} />,
    title: 'History',
  },
  Images: {
    icon: <SSRIcon paths={image} className={styles.tabIcon} />,
    title: 'Images',
  },
  ItemStartEnd: {
    icon: <SSRIcon paths={list} className={styles.tabIcon} />,
    title: 'Market',
    globalOnly: true,
  },
  Relations: {
    icon: <SSRIcon paths={linkin} className={styles.tabIcon} />,
    title: 'Parents',
  },
};

export const PropertyTab = ({
  type,
  activeType,
  setActiveTab,
}: {
  activeType: TabTypes;
  setActiveTab: (type: TabTypes) => void;
  type: TabTypes;
}) => {
  const isGlobal = useSelector(selectIsGlobalMarket) as boolean;
  const dispatch = useDispatch<AppThunkDispatch>();

  const onClick = useCallback(() => {
    setActiveTab(type);
    dispatch(
      analyticsEvent({
        category: ANALYTICS_PROPERTIES_PANE,
        action: `Tab clicked`,
        label: `Properties pane tab ${type} selected`,
      }),
    );
  }, [type, setActiveTab, dispatch]);

  if (TabIcons[type]?.globalOnly && !isGlobal) return null;

  return (
    <button
      key={type}
      type="button"
      aria-label={`Select to open ${type} tab`}
      role="tab"
      className={cx({
        tabs__tab: true,
        'tabs__tab--active': activeType === type,
        [styles.tab]: activeType !== type,
        [styles.tabActive]: activeType === type,
      })}
      onClick={onClick}
    >
      <div className={styles.tabItem}>
        {TabIcons[type].icon}
        <div>{TabIcons[type].title}</div>
      </div>
    </button>
  );
};
