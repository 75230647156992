import { extend } from '../../../utils/object';
import {
  selectCurrentLanguageCode,
  selectCurrentMarketCode,
  selectCurrentRetailUnit,
} from '../../slices/intl';
import { selectAvailableMarkets } from '../../slices/market';
import { createShallowEqualResultSelector } from '../utils';

export { selectAvailableMarkets };

export const selectMarketProps = createShallowEqualResultSelector(
  selectCurrentLanguageCode,
  selectCurrentMarketCode,
  selectCurrentRetailUnit,
  (languageCode, marketCode, retailUnit) => ({
    languageCode,
    marketCode,
    retailUnit,
  }),
);

export const nodeTranslationsLanguageCodesSelector =
  createShallowEqualResultSelector(
    selectCurrentRetailUnit,
    selectAvailableMarkets,
    (state, nodeTranslations) => nodeTranslations || {},
    (retailUnit, markets, nodeTranslations) => {
      const languageCodes = markets
        .find((m) => m.retailUnit === retailUnit)
        ?.languageCodes?.reduce(
          (languages, code) => Object.assign(languages, { [code]: '' }),
          {},
        );
      return extend(languageCodes, nodeTranslations);
    },
  );
