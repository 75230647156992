import React from 'react';
import cx from 'classnames';
import ReasonCodes from './banners/ReasonCodes';
import styles from './SmallBanner.module.scss';

const banners: { [key: string]: React.ComponentType } = {
  reasonCodes: ReasonCodes,
};

const SmallBanner = function SmallBanner({
  node: { bannerId },
}: {
  node: { bannerId: string };
}) {
  const BannerContent = banners[bannerId];

  return (
    !!BannerContent && (
      <div className={cx(styles.banner)}>
        <BannerContent />
      </div>
    )
  );
};

export default SmallBanner;
