import { arrayify } from '../array';
import { isUnpublished, isUntranslated } from '../category';
import { ITEM_TYPE_SPR } from '../types/itemTypes';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NON_NODE_ITEM_TYPES,
} from '../types/nodeTypes';
import {
  validateGlobal as validateCatalogGlobal,
  validateMarket as validateCatalogMarket,
} from '../validation/catalog';
import {
  validateGlobal as validateCategoryGlobal,
  validateMarket as validateCategoryMarket,
} from '../validation/category';

const idSeparator = ':';

export const createId = ({ parentId, type, contentId = type }) => {
  const parent = parentId ? `${parentId}${idSeparator}` : '';
  const content = `${type}_${contentId}`;
  return parent + content;
};

export const destructId = (nodeId) => {
  if (!nodeId || !nodeId.length) {
    return {};
  }
  let splitNode = nodeId.split(idSeparator).pop();
  if (!splitNode) {
    splitNode = nodeId;
  }
  const [type, id] = splitNode.split('_');

  return {
    [type === NODE_TYPE_CATALOG ? 'catalogId' : `${type}Id`]: id,
    value: id,
  };
};

export const getLeafCategoryForItem = (parentId) => {
  if (!parentId) return '';

  const indexTerm = 'category_';
  const start = parentId.lastIndexOf(indexTerm);
  const end = parentId.lastIndexOf(':');
  // create new string from last 'category_' and last ':'.
  // if end is smaller than start, its not a grouped item
  const lastCategory =
    start > -1
      ? parentId.substring(
          start + indexTerm.length,
          end > start ? end : parentId.length,
        )
      : '';

  return lastCategory;
};

export const getPathById = (id) =>
  id
    .split(idSeparator)
    .reduce(
      (path, _, idx, ids) => [...path, ids.slice(0, idx + 1).join(idSeparator)],
      [],
    );

export function isNodeExpandable(node) {
  return (
    !node.isNew &&
    (node.type !== NODE_TYPE_ITEM ||
      node.itemType === ITEM_TYPE_SPR ||
      node.item.countrySpecificItemCount) &&
    !NON_NODE_ITEM_TYPES.includes(node.type)
  );
}

export function hasLoadMoreItems(node) {
  if (!node.category?.categoryId || !node.expanded) {
    return false;
  }
  return node.category.childItemCount > node.children?.length;
}

export function hasPasteOption(node) {
  return [NODE_TYPE_CATALOG, NODE_TYPE_CATEGORY].includes(node.type);
}

export function hasSameContent(nodeItem, nodeItems) {
  const [key, value] = getContentIdPairsFromPathId(nodeItem.node.id);
  return arrayify(nodeItems).some((ni) => destructId(ni.node.id)[key] === value);
}

export function getCatalogTitle(catalog, languageCode) {
  return (
    catalog.catalogIdentifierTranslations?.[languageCode] ||
    catalog.globalCatalogIdentifier
  );
}

export function getCategoryTitle(category, languageCode = 'en') {
  const globalCategoryName = category.globalCategoryName || '';
  const title =
    category.categoryNameTranslationsVerified?.[languageCode] || globalCategoryName;
  if (category.categoryNameTranslationApproved) {
    return category.categoryNameTranslations?.[languageCode] || title;
  }
  return title;
}

export function validateCatalog({ catalog }, isGlobal, isDirty) {
  if (!isDirty) {
    return {
      isInvalid: false,
    };
  }
  const errors = isGlobal
    ? validateCatalogGlobal(catalog)
    : validateCatalogMarket(catalog);
  return {
    isInvalid: Object.values(errors).some((e) => getBooleanValue(e)),
    errors,
  };
}

export function validateCategory({ category, isNew = false }, isGlobal, isMaster) {
  const errors = isGlobal
    ? validateCategoryGlobal(category, isNew, isMaster)
    : validateCategoryMarket(category);
  return {
    isInvalid: Object.values(errors).some((e) => getBooleanValue(e)),
    errors,
  };
}

export function hasUnpublished(node) {
  return isUnpublished(node.category);
}

export function hasUntranslated(node) {
  return isUntranslated(node.category);
}

function getBooleanValue(val) {
  if (typeof val === 'boolean') {
    return val;
  }
  if (typeof val === 'object') {
    return Object.values(val).some((v) => getBooleanValue(v));
  }
  return !!val;
}

export function getNodeDataContent(node) {
  switch (node.type) {
    case NODE_TYPE_CATALOG:
      return node.catalog;
    case NODE_TYPE_CATEGORY:
      return node.category;
    case NODE_TYPE_ITEM:
      return node.item;

    default:
      return null;
  }
}

export function getNodeDataId(node) {
  let dataId;
  switch (node.type) {
    case NODE_TYPE_CATALOG:
      dataId = node.catalog.catalogId;
      break;
    case NODE_TYPE_CATEGORY:
      dataId = node.category.categoryId;
      break;
    case NODE_TYPE_ITEM:
      dataId = node.item.itemId;
      break;

    default:
      dataId = null;
      break;
  }

  return dataId && `${node.type}-${dataId}`;
}

export function getContentIdPairsFromPathId(pathedId, key) {
  const pairs =
    Object.entries(destructId(pathedId)).find(([prop]) => prop !== 'value') || [];
  if (!key || pairs[0] === key) {
    return pairs;
  }
  return [];
}

export function getContentIdFromProperty(ni, property) {
  return ni?.node?.[property && property.slice(0, -2)]?.[property];
}

export function comparePathedIds(leftId, rightId) {
  const [leftKey, leftValue] = getContentIdPairsFromPathId(leftId);
  const [rightKey, rightValue] = getContentIdPairsFromPathId(rightId);
  return leftKey === rightKey && leftValue === rightValue;
}
