import { walkTree } from '../../../components/Tree/helpers';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { CATALOG_STATUS_ACTIVE } from '../../../utils/statuses/catalogStatus';
import {
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../../../utils/types/catalogTypes';
import { selectPanes } from '../pane/data';
import { selectTreeDataByCatalogId } from '../tree';
import { createReferenceEqualSelector } from '../utils';

export const selectCatalogIdForPredictions = createReferenceEqualSelector(
  selectPanes,
  (panes) => {
    const { catalogId, showsUncatagorized, showsProducts } = panes.reduce(
      (result, content) => {
        if (
          content.catalog.catalogType === CATALOG_TYPE_MASTER &&
          content.catalog.catalogStatus === CATALOG_STATUS_ACTIVE
        ) {
          Object.assign(result, {
            showsProducts: true,
            catalogId: content.catalog.catalogId,
          });
        }
        if (content.catalog.catalogType === CATALOG_TYPE_UNCATEGORIZED) {
          Object.assign(result, { showsUncatagorized: true });
        }
        return result;
      },
      {},
    );
    if (showsProducts && showsUncatagorized) {
      return catalogId;
    }
    return null;
  },
);

export const selectTreeDataForPredictions = createReferenceEqualSelector(
  (state) => state,
  selectCatalogIdForPredictions,
  (state, catalogId) => {
    if (!catalogId) {
      return ReadOnlyArray.Empty;
    }
    return selectTreeDataByCatalogId(state, catalogId);
  },
);

export const selectCategoriesForPredictions = createReferenceEqualSelector(
  selectTreeDataForPredictions,
  (treeData) => {
    const categories = new Map();
    walkTree({
      treeData,
      callback: (ni) => {
        const { category } = ni.node;
        if (category?.categoryId && category.childCategoryCount === 0) {
          categories.set(category.externalCategoryId, category);
        }
      },
      ignoreCollapsed: false,
    });
    return categories;
  },
);
