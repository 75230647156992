import { getGenericValidationMessage } from './text';

export function validateGlobal(catalog) {
  const { activationDate } = catalog;
  const validationErrors = {
    activationDate: !Date.parse(activationDate)
      ? 'Please enter a valid date.'
      : undefined,
  };
  return validationErrors;
}

export function validateMarket(catalog) {
  const { catalogIdentifierTranslations = {} } = catalog;
  const validationErrors = {
    catalogIdentifierTranslations: Object.entries(
      catalogIdentifierTranslations,
    ).reduce(
      (validation, [languageCode, catalogIdentifier]) =>
        Object.assign(validation, {
          [languageCode]:
            getGenericValidationMessage(catalogIdentifier) ||
            (validateName(catalogIdentifier)
              ? 'Translation may not be empty'
              : undefined),
        }),
      {},
    ),
  };
  return validationErrors;
}

function validateName(name) {
  return !name || !name.trim();
}
