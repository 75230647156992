import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createComparer } from '../../../utils/object';
import { getItems } from '../../actions/api/items';
import { setMarket } from '../../actions/intl';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';
import { ItemDTO } from '../../mappers/dtoMappers';

const itemEntityAdapter = createEntityAdapter<ItemDTO, string>({
  selectId: (item) => item.itemId,
  sortComparer: createComparer('itemNameOfficial'),
});

const itemSlice = createSlice({
  name: 'item',
  initialState: itemEntityAdapter.getInitialState(),
  extraReducers: (builder) =>
    mergeStateReducerBuilder('state.item', builder)
      .addCase(setMarket, () => itemEntityAdapter.getInitialState())
      .addCase(getItems.fulfilled, (state, action) => {
        const {
          items,
          meta: { totalNumberOfElements },
        } = action.payload;
        if (typeof totalNumberOfElements === 'number') {
          itemEntityAdapter.setMany(state, items);
        }
      }),
  reducers: {},
});

export default itemSlice.reducer;

export const { selectAll: selectItems, selectById: selectItem } =
  itemEntityAdapter.getSelectors(
    (state: { item: EntityState<ItemDTO, string> }) => state.item,
  );
