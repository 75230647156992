import Title from '../Title';
import { NodeBase } from '../../../../entities/Node';

const Group = function Group({ node }: { node: NodeBase }) {
  const { title, group } = node;
  const count =
    typeof group?.childItemCount !== 'undefined' ? ` (${group.childItemCount})` : '';
  return <Title title={`${title}${count}`} />;
};

export default Group;
