import { createUncategorizedNodes } from '../../../utils/item/createUncategorizedNodes';
import { selectTreeState } from '../../selectors/tree/data';
import { getUncategorizedItems } from '../api/items';
import { updateData } from '../tree';
import { CATALOG_TYPE_MASTER } from '../../../utils/types/catalogTypes';
import { PANE_SOURCETREE, PANE_TARGETTREE } from '../../constants/panes';
import { CATALOG_STATUS_ACTIVE } from '../../../utils/statuses/catalogStatus';

export const fetchUncategorizedItems =
  (tree, catalogId) => async (dispatch, getState) => {
    const { items } = await dispatch(getUncategorizedItems()).unwrap();
    const state = getState();
    const expandedNodes = selectTreeState(state, catalogId);
    const panes = state.pane;

    const showPredictions =
      (panes[PANE_SOURCETREE].catalogType === CATALOG_TYPE_MASTER &&
        panes[PANE_SOURCETREE].catalogStatus === CATALOG_STATUS_ACTIVE) ||
      (panes[PANE_TARGETTREE].catalogType === CATALOG_TYPE_MASTER &&
        panes[PANE_TARGETTREE].catalogStatus === CATALOG_STATUS_ACTIVE);

    const uncategorizedNodes = createUncategorizedNodes({
      catalogId,
      items,
      showPredictions,
      expandedIds: expandedNodes,
    });
    return dispatch(
      updateData(tree, uncategorizedNodes, {
        meta: {
          totalNumberOfElements: items.length,
        },
      }),
    );
  };
