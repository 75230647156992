import * as PANES from '../../store/constants/panes';
import { toRegExpSafeString } from '../string';

const DUMMY = 'DUMMY';
const panesRegexpSafe = [...Object.values(PANES), DUMMY]
  .map((p) => toRegExpSafeString(p))
  .join('|');
const catalogIdRegexp = RegExp(`^(${panesRegexpSafe})-`);

export function makeTreeId(pane, catalogId) {
  return `${pane || DUMMY}-${catalogId}`;
}

export function getCatalogId(treeId) {
  return treeId && treeId.replace(catalogIdRegexp, '');
}
