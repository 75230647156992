import { pasteCopy } from '../../../../store/actions/nodeMenu/pasteCopy';
import { pasteMove } from '../../../../store/actions/nodeMenu/pasteMove';
import { isPastable } from '../../../../utils/command';

export function getCatalogActions(
  { canPaste },
  { directive, dispatch, isCopy, targetNodeItem, sourceNodeItems, tree },
) {
  if (
    canPaste({ nextParent: targetNodeItem.node, node: sourceNodeItems?.[0] }) &&
    isPastable(directive)
  ) {
    return {
      pasteAction: isCopy
        ? () => dispatch(pasteCopy(tree, targetNodeItem))
        : () => dispatch(pasteMove(tree, targetNodeItem)),
    };
  }

  return {};
}
