import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { addDays } from 'date-fns/addDays';
import {
  INTERVALS,
  SORT_ORDER,
} from '../../../actions/reports/notvalidforstrucutreitems/constants';
import { createReferenceEqualSelector } from '../../../selectors/utils';
import { getDatesFromInterval } from './helpers';
import type { RootState } from '../../../index';
import {
  NvfsItem,
  NvfsPaging,
} from '../../../actions/reports/notvalidforstrucutreitems/fetchItems';

const notValidForStructureItemsEntityAdapter = createEntityAdapter<NvfsItem, string>(
  {
    selectId: (item) => item.itemNo,
  },
);

export type NotValidForStructureState = {
  loading: string;
  paging: null | {
    totalElements?: number;
    number?: number;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
  };
  sortOn: string;
  sortOrder: string;
  filter: string;
  fromDate: string;
  toDate: string;
  interval: number | null;
};

const notValidForStructureState: NotValidForStructureState = {
  loading: 'idle',
  paging: null,
  sortOn: 'UPDATED_DATE',
  sortOrder: SORT_ORDER.DESC,
  filter: '',
  fromDate: '',
  toDate: '',
  interval: null,
};

const notValidForStrucureItemsSlice = createSlice({
  name: 'reports/notvalidforstructureitems',
  initialState: notValidForStructureItemsEntityAdapter.getInitialState({
    ...notValidForStructureState,
  }),
  reducers: {
    setDataFilter(state, action) {
      state.filter = action.payload;
    },
    setDataInterval(state, action) {
      const { interval = INTERVALS.CUSTOM, fromDate, toDate } = action.payload;
      const [from, to] = getDatesFromInterval(interval, fromDate, toDate);
      state.fromDate = from;
      state.toDate = to;
      state.interval = interval;
    },
    setDataSorting(state, action: { payload: string }) {
      if (state.sortOn === action.payload) {
        state.sortOrder =
          Object.values(SORT_ORDER).find((o) => o !== state.sortOrder) || '';
      } else {
        state.sortOn = action.payload;
        state.sortOrder = SORT_ORDER.DESC;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase('reports/notvalidforstructureitems/fetchItems/pending', (state) => {
        state.loading = 'loading';
      })
      .addCase(
        'reports/notvalidforstructureitems/fetchItems/fulfilled',
        (state, action) => {
          const {
            payload: { data, paging },
          } = action as unknown as {
            payload: {
              data: NvfsItem[];
              paging: NvfsPaging;
            };
          };
          if (paging?.first) {
            notValidForStructureItemsEntityAdapter.setAll(state, data);
          } else {
            notValidForStructureItemsEntityAdapter.setMany(state, data);
          }
          state.loading = 'idle';
          state.paging = paging;
        },
      )
      .addCase('reports/notvalidforstructureitems/fetchItems/rejected', (state) => {
        state.loading = 'idle';
      }),
});

export const { setDataFilter, setDataInterval, setDataSorting } =
  notValidForStrucureItemsSlice.actions;
export default notValidForStrucureItemsSlice.reducer;

export const {
  selectAll: selectReportsNotValidForStructureItems,
  selectTotal: selectDataPagingItemCount,
} = notValidForStructureItemsEntityAdapter.getSelectors(
  (state: RootState) => state.notvalidforstructureitems,
);

function createNvfsiSelector<T>(
  selector: (state: RootState['notvalidforstructureitems']) => T,
) {
  return createReferenceEqualSelector(
    (state: RootState) => state.notvalidforstructureitems,
    selector,
  );
}

export const selectDataFilter = createNvfsiSelector<string>((state) => state.filter);

export const selectDataPagingTotalCount = createNvfsiSelector<number>(
  (state) => state.paging?.totalElements || 0,
);
export const selectDataPagingPageNo = createNvfsiSelector(
  (state) => state.paging?.number || 0,
);
export const selectDataSortOn = createNvfsiSelector<string>((state) => state.sortOn);
export const selectDataSortOrder = createNvfsiSelector<string>(
  (state) => state.sortOrder,
);
export const selectDataIntervalSpan = createNvfsiSelector<number | null>((state) => {
  const { interval } = state;
  return interval !== undefined ? interval : INTERVALS.LAST_DAY;
});
export const selectDataIntervalFromDate = createNvfsiSelector<string>((state) => {
  const { fromDate } = state;
  return fromDate !== undefined ? fromDate : addDays(new Date(), -1).toISOString();
});

export const selectDataIntervalToDate = createNvfsiSelector<string>((state) => {
  const { toDate } = state;
  return toDate !== undefined ? toDate : new Date().toISOString();
});

export const selectDataReadMoreBtnDisabled = createNvfsiSelector<boolean>(
  (state) =>
    state.loading === 'loading' || !!state.paging?.empty || !!state.paging?.last,
);
