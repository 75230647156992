import React, { useCallback, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { getCyclicIndex } from '../../utils/array';
import styles from './Dropdown.module.scss';
import SSRIcon from '@ingka/ssr-icon';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';

type DropdownProps = {
  id: string;
  value: string;
  label: string;
  onChange: (catalogId: string) => void;
  ItemRenderer: React.ElementType;
  itemList: { value: string; text: string }[];
  className: string;
};

const Dropdown = ({
  id,
  value,
  onChange,
  ItemRenderer,
  itemList,
  label,
  className,
}: DropdownProps) => {
  const Option = ItemRenderer;
  const elementRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectedIndex = useMemo(
    () => itemList.findIndex((item) => item.value === value),
    [itemList, value],
  );
  const [highlightIndex, setHighlightIndex] = useState(selectedIndex);

  const highlightOption = useCallback(
    (newHighlightIndex: number) => {
      if (highlightIndex === newHighlightIndex) {
        return;
      }
      setHighlightIndex(newHighlightIndex);
      const option = elementRef?.current?.querySelector(
        `[data-index="${newHighlightIndex}"]`,
      ) as HTMLLIElement;
      if (option) {
        option.focus();
      }
    },
    [highlightIndex],
  );

  const toggleOptionsList = useCallback(
    (value?: boolean) => {
      const newVal = typeof value === 'boolean' ? value : !isOpen;
      if (newVal === isOpen) {
        return;
      }
      setIsOpen(newVal);

      const option =
        newVal &&
        (elementRef?.current?.querySelector(
          `[data-index="${highlightIndex}"]`,
        ) as HTMLLIElement);
      if (option) {
        option.focus();
      }
    },
    [isOpen, setIsOpen, highlightIndex],
  );

  const updateValue = useCallback(
    (index: number) => {
      const indexToSelect = getCyclicIndex(itemList, index);
      if (indexToSelect === selectedIndex) {
        toggleOptionsList(false);
        return;
      }

      highlightOption(indexToSelect);
      toggleOptionsList(false);
      const { value } = itemList[indexToSelect];
      onChange(value);
    },
    [highlightOption, itemList, onChange, selectedIndex, toggleOptionsList],
  );

  const handleOptionKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        updateValue(highlightIndex);
      }
    },
    [highlightIndex, updateValue],
  );

  const resetComponent = useCallback(() => {
    toggleOptionsList(false);
    highlightOption(selectedIndex);
  }, [selectedIndex, toggleOptionsList, highlightOption]);

  const handleSelectKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        const index = (highlightIndex + 1) % itemList.length;
        if (!isOpen) {
          toggleOptionsList(true);
        }
        highlightOption(index);
      } else if (e.key === 'ArrowUp') {
        const i = highlightIndex - 1;
        const index = i < 0 ? itemList.length - 1 : i;
        if (!isOpen) {
          toggleOptionsList(true);
        }
        highlightOption(index);
      } else if (e.key === 'Enter') {
        updateValue(highlightIndex);
      } else if (e.key === 'Escape') {
        resetComponent();
      } else {
        return true;
      }
      return false;
    },
    [
      highlightIndex,
      itemList.length,
      isOpen,
      highlightOption,
      toggleOptionsList,
      updateValue,
      resetComponent,
    ],
  );

  const onChangeFallbackSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = itemList.findIndex((i) => i.value === e.target.value);
    updateValue(index);
  };

  const onBlur = () => {
    setTimeout(() => {
      const hasFocus = elementRef.current?.querySelector(':focus');
      if (!hasFocus) {
        resetComponent();
      }
    }, 200);
  };

  return (
    <div className={cx('select', className)}>
      <form className={cx('select__wrapper', styles.dropdownWrapper)}>
        <select
          className={styles.backingComponent}
          onChange={onChangeFallbackSelect}
          tabIndex={-1}
          title={label}
          value={itemList[selectedIndex]?.value}
        >
          {itemList.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
        <button
          id={`${id}-button`}
          aria-label={label}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          ref={elementRef}
          onBlur={onBlur}
          className={styles.select}
          onClick={(e) => {
            // focus fix for safari
            e.currentTarget.focus();
            toggleOptionsList();
          }}
          onKeyDown={handleSelectKeyDown}
          type="button"
        >
          <div className={styles.value}>
            <SSRIcon paths={chevronDown} className={styles.chevron} />
            {!!itemList[highlightIndex] && <Option {...itemList[highlightIndex]} />}
          </div>
          <div
            role="presentation"
            id="dropdown"
            className={cx(styles.optionsPanel, { [styles.hidden]: !isOpen })}
          >
            <ul className={styles.options} role="listbox">
              {itemList.map((item, i) => (
                <li
                  aria-label={item.text}
                  id={`item-${i}`}
                  aria-selected={i === highlightIndex}
                  className={cx(styles.option, {
                    [styles.highlight]: i === highlightIndex,
                  })}
                  data-index={i}
                  key={item.value}
                  onClick={() => updateValue(i)}
                  onFocus={() => highlightOption(i)}
                  onKeyDown={handleOptionKeyDown}
                  onMouseOver={() => highlightOption(i)}
                  role="option"
                  tabIndex={-1}
                >
                  <Option {...item} />
                </li>
              ))}
            </ul>
          </div>
        </button>
      </form>
    </div>
  );
};

export { Dropdown };
