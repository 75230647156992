import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { marketComparer } from '../../../utils/market';
import { getAllMarkets } from '../../actions/api/market';
import type { RootState } from '../../index';
import { Market } from '../../../entities/Market';

const marketEntityAdapter = createEntityAdapter({
  selectId: (market: Market) => market.retailUnit,
  sortComparer: marketComparer,
});

const marketSlice = createSlice({
  name: 'market',
  initialState: marketEntityAdapter.getInitialState(),
  extraReducers: (builder) =>
    builder.addCase(getAllMarkets.fulfilled, (state, action) => {
      marketEntityAdapter.setAll(state, action.payload);
    }),
  reducers: {},
});

export default marketSlice.reducer;

export const { selectAll: selectAvailableMarkets } =
  marketEntityAdapter.getSelectors((state: RootState) => state.market);
