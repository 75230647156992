import * as CATALOG_TYPES from '../types/catalogTypes';
import {
  CATALOG_TYPE_AUTOMATED,
  CATALOG_TYPE_COLLECTION,
  CATALOG_TYPE_MARKET,
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_SERIES,
  CATALOG_TYPE_THEMES,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../types/catalogTypes';

const sortOrder = Object.values(CATALOG_TYPES).reduce(
  (ordered, type) => (ordered.includes(type) ? ordered : [...ordered, type]),
  [
    CATALOG_TYPE_UNCATEGORIZED,
    CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
    CATALOG_TYPE_MASTER,
    CATALOG_TYPE_MARKET,
    CATALOG_TYPE_COLLECTION,
    CATALOG_TYPE_SERIES,
    CATALOG_TYPE_THEMES,
    CATALOG_TYPE_AUTOMATED,
  ],
);

export function comparer(a, b) {
  const aValue = sortOrder.indexOf(a.catalogType);
  const bValue = sortOrder.indexOf(b.catalogType);
  return aValue - bValue;
}
