import React, { useMemo } from 'react';

export interface TreeContextType {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const defaultValue = {
  loading: false,
  setLoading: () => {},
} as TreeContextType;

export const TreeContext = React.createContext<TreeContextType>(defaultValue);
TreeContext.displayName = 'TreeContext';

export function TreeContextProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const value = useMemo(
    () => ({ loading, setLoading }),
    [loading, setLoading],
  ) as TreeContextType;

  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>;
}
