import React from 'react';
import visibilityHide from '@ingka/ssr-icon/paths/visibility-hide';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import calendar from '@ingka/ssr-icon/paths/calendar';
import Badge from './Badge';
import styles from './StatusBadges.module.scss';

export const New = function New() {
  return (
    <Badge compact={false} className={styles.publish} label="New" tooltip="New" />
  );
};

export const NotAvailable = function NotAvailable({
  compact,
}: {
  compact: boolean;
}) {
  return (
    <Badge
      className={styles.publish}
      compact={compact}
      iconPath={incorrect}
      label="Not available"
      tooltip="Not available for markets"
    />
  );
};

export const Hide = function Hide({ compact }: { compact: boolean }) {
  return (
    <Badge
      className={styles.publish}
      compact={compact}
      iconPath={visibilityHide}
      label="Hide"
      tooltip="Hidden from navigation & internal search"
    />
  );
};

export const UnpublishedCount = function UnpublishedCount({
  compact,
  count,
}: {
  compact: boolean;
  count: number;
}) {
  return (
    <Badge
      className={styles.publishInverted}
      compact={compact}
      label={`${count} publishing`}
      labelCompact={count.toString()}
      tooltip="Unpublished"
    />
  );
};

export const FuturePublishDate = function FuturePublishDate({
  compact,
}: {
  compact: boolean;
}) {
  return (
    <Badge
      className={styles.publish}
      compact={compact}
      iconPath={calendar}
      label="Future publish date"
      tooltip="Future publish date"
    />
  );
};
