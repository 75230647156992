import { openModal } from '../../../../store/actions/modal';
import { openDialogRemoveItems } from '../../../../store/actions/modal/removeItems';
import { nodeMenuCopy } from '../../../../store/actions/nodeMenu/copy';
import { nodeMenuCut } from '../../../../store/actions/nodeMenu/cut';
import { markForCategorization } from '../../../../store/actions/nodeMenu/markForCategorization';

export function getItemActions(treeConfig, { dispatch, nodeItem, tree }) {
  const nodeActions = {};

  if (nodeItem.node.isInSuggestion) {
    nodeActions.openAlterSuggestionsDialog = () =>
      dispatch(openModal('AlterSuggestionsDialog', { tree, nodeItem }));
  } else if (nodeItem.node.isUncategorized) {
    const excludedGroups = ['date-group_no-product-name', 'date-group_no-date'];
    if (!excludedGroups.includes(nodeItem.node.parentId)) {
      nodeActions.nodeMenuCut = () =>
        dispatch(markForCategorization(tree, nodeItem));
    }
  }

  if (
    !nodeItem.node.isUncategorized &&
    treeConfig.allowItemOperations &&
    !nodeItem.node.isChildItem
  ) {
    nodeActions.nodeMenuCopy = () => dispatch(nodeMenuCopy(tree, nodeItem));
    nodeActions.nodeMenuCut = () => dispatch(nodeMenuCut(tree, nodeItem));
    nodeActions.openDialogRemoveItem = () =>
      dispatch(openDialogRemoveItems(tree, nodeItem));
  }

  return nodeActions;
}
