import { getNodeKey } from '../../../components/Tree/helpers';
import { makeTreeData } from '../../../components/Tree/helpers/makeTreeData';
import { MISSING_ITEMS_CATALOG } from '../../../utils/catalog/missingitems';
import { toItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import { walk } from '../../../utils/tree/tree-data-utils';
import { getMissingItems } from '../api/items';
import { updateData } from '../tree';

export const fetchMissingItems = (tree) => async (dispatch) => {
  const { items, categories, languageCode, topCategories } =
    await dispatch(getMissingItems()).unwrap();
  const itemsObj = items.reduce(
    (obj, item) => Object.assign(obj, { [`${item.itemNo}`]: item }),
    {},
  );
  const { catalogId } = MISSING_ITEMS_CATALOG;
  const treeData = makeTreeData(
    {
      ...MISSING_ITEMS_CATALOG,
      globalCatalogIdentifier: 'Missing from LIVE catalogue',
      topCategories,
      isMissing: true,
    },
    categories,
    languageCode,
    [],
    itemsObj,
  );
  const unmappedItems = getUnmappedItems(treeData, items, catalogId);
  treeData.push(...unmappedItems);
  return dispatch(
    updateData(tree, treeData, { meta: { totalNumberOfElements: items.length } }),
  );
};

function getUnmappedItems(treeData, items, catalogId) {
  const mappedItems = new Set([]);
  const callback = function name(nodeItem) {
    if (nodeItem.node.itemNo) {
      mappedItems.add(nodeItem.node.itemNo);
    }
  };
  walk({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed: false,
  });
  const mappedItemNbrs = Array.from(mappedItems.keys());
  return toItemNodes({
    catalogId,
    items: items.filter((item) => !mappedItemNbrs.includes(item.itemNo)),
    level: 0,
  });
}
