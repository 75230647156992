import { toDateIsoString } from '../../date';
import { isDateTimeString } from '../../string';
import { NODE_TYPE_DATE_GROUP } from '../../types/nodeTypes';
import { createNewNode } from '../node';

export function toDateGroupNode({ catalogId, childItemCount, date, expanded }) {
  let title;

  if (date === 'no-date') {
    title = 'Sale start date undefined';
  } else if (date === 'no-product-name') {
    title = 'Product name missing';
  } else {
    title = toDateIsoString(date);
  }

  return createNewNode(
    catalogId,
    `${isDateTimeString(date) ? Date.parse(date) : date}`,
    NODE_TYPE_DATE_GROUP,
    {
      expanded,
      group: { childItemCount },
      title,
    },
  );
}
