import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { externalLinkClicked } from '../../store/actions/analytics/externalLinkClicked';
import linkOut from '@ingka/ssr-icon/paths/link-out';
import Link from '../Link';
import styles from './ExternalLink.module.scss';
import { AppThunkDispatch } from '../../store';
import SSRIcon from '@ingka/ssr-icon';

type ExternalLinkProps = {
  asButton: boolean;
  asIcon: boolean;
  children: React.ReactNode;
  href: string;
  label: string;
  text: string;
};

const ExternalLink = ({
  asButton,
  asIcon,
  children,
  href,
  label,
  text,
}: ExternalLinkProps) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(externalLinkClicked(e.target));
    },
    [dispatch],
  );

  return (
    <Link
      className={asButton ? undefined : styles.link}
      asIcon={asIcon}
      asButton={asButton}
      href={href}
      label={label}
      newWindow
      onClick={onClick}
    >
      {text || children}
      {!asButton && <SSRIcon paths={linkOut} className={styles.icon} />}
    </Link>
  );
};

export default ExternalLink;
