class PermissionDeniedError extends Error {
  constructor() {
    super(
      'Sadly you do not have the correct user premissions to perform this action.',
    );

    this.name = 'PermissionDeniedError';

    Object.setPrototypeOf(this, PermissionDeniedError.prototype);
  }
}

export default PermissionDeniedError;
