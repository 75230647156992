import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { marketComparer } from '../../../utils/market';
import { GET } from '.';
import { MarketsSchema } from '../../../schemas/markets';

export const getAllMarkets = createAsyncThunk(
  'market/getAll',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const result = await dispatch(
      GET({
        path: '/rrm-rc/markets',
        label: 'Fetch all markets',
      }),
    );
    const markets = Array.isArray(result)
      ? result.sort(marketComparer)
      : ReadOnlyArray.Empty;

    const parsed = MarketsSchema.parse(markets);

    return parsed;
  },
);
