import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../index';

const rolesSlice = createSlice({
  name: 'roles',
  initialState: [] as string[],
  reducers: {
    setRoles: (_, action: PayloadAction<string[]>) => action.payload,
  },
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice.reducer;

export const selectRoles = (state: RootState) => state.roles;
