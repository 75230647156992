import {
  Key,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import RCTree from 'rc-tree/es';
import { conductExpandParent } from 'rc-tree/es/util';
import { convertDataToEntities } from 'rc-tree/es/utils/treeUtil';
import { shallowEqual } from 'react-redux';
import { EventDataNode } from 'rc-tree/es/interface';
import { TreeContextProvider } from '../../../contexts/TreeContext';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import NodeIcon from '../../Icons/NodeIcon';
import { getUnfoldedNodeIds } from '../helpers/folding';
import NodeContent from '../NodeContent';
import styles from './CommonTree.module.scss';
import { NodeBase } from '../../../entities/Node';
import { OnSelectEvent, OnExpandType } from '../treeTypes';
import SSRIcon from '@ingka/ssr-icon';

interface CommonTreeProps {
  className?: string;
  indentTree: boolean;
  autoExpandParent: boolean;
  onActiveChange?: (key: Key) => void;
  onDoubleClick?: (e: unknown, node: { type: string }) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onSelect?: (selectedKeys: React.Key[], info: OnSelectEvent) => void;
  onExpand: OnExpandType;
  scrollToKey: string | number | null;
  selectedKeys: string[];
  treeNodes: NodeBase[];
}

const CommonTree = ({
  className,
  indentTree,
  onSelect,
  onExpand,
  scrollToKey,
  selectedKeys = [],
  treeNodes,
  ...props
}: CommonTreeProps) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const ref = useRef<RCTree<NodeBase> | null>(null);

  const onExpandNode = useCallback(
    (
      _: unknown,
      treeNode: {
        node: EventDataNode<NodeBase>;
        expanded: boolean;
        nativeEvent: MouseEvent;
      },
    ) => {
      onExpand(treeNode);
    },
    [onExpand],
  );

  const expandedNodes = useMemo(() => {
    if (!scrollToKey) {
      return expanded;
    }
    const { keyEntities } = convertDataToEntities(treeNodes);
    const expandKeys = [
      ...new Set([
        ...expanded,
        ...conductExpandParent([scrollToKey], keyEntities).filter(
          (key) => key !== scrollToKey,
        ),
      ]),
    ];
    return expandKeys;
  }, [expanded, scrollToKey, treeNodes]);

  useEffect(() => {
    const results = getUnfoldedNodeIds(treeNodes);
    if (!shallowEqual(expanded, results)) {
      setExpanded(results);
    }
  }, [expanded, treeNodes]);

  useEffect(() => {
    if (scrollToKey && ref.current) {
      ref.current.scrollTo({
        align: 'top',
        key: scrollToKey,
      });
    }
  }, [ref, scrollToKey]);

  return (
    <TreeContextProvider>
      <RCTree
        ref={ref}
        focusable
        className={cx({ [styles.indent]: indentTree }, className)}
        itemHeight={32}
        multiple={!!onSelect}
        onSelect={onSelect}
        selectable={!!onSelect}
        selectedKeys={selectedKeys}
        treeData={treeNodes}
        {...props}
        draggable={false}
        expandedKeys={expandedNodes}
        icon={NodeIcon}
        onDragStart={undefined}
        onDrop={undefined}
        onExpand={onExpandNode}
        showIcon
        switcherIcon={<SSRIcon paths={chevronRight} />}
        titleRender={NodeContent}
      />
      <span className="select__border" />
    </TreeContextProvider>
  );
};

export default CommonTree;
