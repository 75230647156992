import { parseMarketCodeFromPathname } from '../market';
import { getParamsObj } from './search';
import { createUrl } from './utils';

export function parse(urlToParse) {
  const url = createUrl(urlToParse);
  const { pathname } = url;
  const {
    catalogues: catalogs = [],
    selectedNode: selectedNodeId,
    ...searchParams
  } = getParamsObj(url);
  const { retailUnit, languageCode } = parseMarketCodeFromPathname(pathname);
  const sourceTree = catalogs[0];
  const targetTree = catalogs[1];
  const sourceTreeState = getTreeState(searchParams, sourceTree);
  const targetTreeState = getTreeState(searchParams, targetTree);

  return {
    ...searchParams,
    languageCode,
    retailUnit,
    selectedNodeId,
    sourceTree,
    sourceTreeState,
    targetTree,
    targetTreeState,
  };
}

function getTreeState(searchParams, catalogId) {
  return searchParams[`exp_${catalogId}`] || undefined;
}
