import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  findFirstNode,
  findNodes,
  getNodeInTree,
  updateNodeInTree,
  withSearchResults,
} from '../../../components/Tree/helpers';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { UNCATEGORIZED_CATALOG } from '../../../utils/catalog/uncategorized';
import { extend } from '../../../utils/object';
import { ReadOnlyObject } from '../../../utils/object/ReadOnlyObject';
import { getCatalogId } from '../../../utils/tree/ids';
import {
  customSearchMethod,
  getFirstXSearchResults,
  SEARCH_BY,
  toSearchQuery,
} from '../../../utils/tree/search';
import { getPathById } from '../../../utils/tree/treeNode';
import { removeCatalogs } from '../../actions/catalog';
import { fetchCatalogRemovalInfo } from '../../actions/catalog/fetchCatalogRemovalInfo';
import { createTreesFromPreviewResult } from '../../actions/importitems/createTrees';
import { setMarket } from '../../actions/intl';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';
import {
  createReferenceEqualSelector,
  getCatalogIdFromTreeId,
  getIdFromArgument,
} from '../../selectors/utils';
import { categorize, copyNode, cutNode } from './actions';
import { clearSearch } from './helpers';
import { highlightSuggestedCategoriesReducer } from './reducers/highlightSuggestedCategories';
import type { RootState } from '../..';
import { NodeBase, NodeBaseItem } from '../../../entities/Node';
import { Item } from '../../../entities/Item';

export type TreeCatalogs = {
  isFetching: boolean;
  treeData: NodeBase[];
  searchCondition: {
    by: string;
    condition: string;
  } | null;
  searchQuery: {
    updated?: number;
    catalogIds?: string[];
    by: string;
    condition: string;
    regexp: string;
    structureReasonText?: string | null;
    item?: Item;
  } | null;
  searchPanel: boolean;
  searchMatches: NodeBaseItem[];
  searchSuggestions: { id: string; title: string; type: string; data: unknown }[];
  highlightFocusId: string;
  highlightIndex: string;
  searchFocusIndex: number | null;
  searchFocusId: string | null;
  meta?: {
    totalNumberOfElements: number;
  };
  removalInfo?: {
    categoryId: number;
    externalCategoryId: string;
    categoryName: string;
  }[];
};

type TreeState = {
  catalogs: { [key: string]: TreeCatalogs | Record<string, never> };
  action:
    | {
        directive: string;
        tree: string;
        nodeItems: NodeBaseItem[];
      }
    | Record<string, never>;
  selectedNodeItem: { [key: string]: NodeBaseItem } | Record<string, never>;

  selectedNodeItems: {
    [key: string]: NodeBaseItem[] | Record<string, never>;
  };
  originalTree: NodeBase[];
  sortedCatalogs:
    | {
        [key: string]: {
          [key: string]: {
            [key: string]: {
              positionBeforeChange: number;
            };
          };
        };
      }
    | Record<string, never>;
};

const initialState: TreeState = {
  catalogs: {},
  action: {},
  selectedNodeItem: {},
  selectedNodeItems: {},
  sortedCatalogs: {},
  originalTree: [],
};

const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    resetSelectedNode(state) {
      state.selectedNodeItem = {};
      state.selectedNodeItems = {};
      state.action = {};
      return state;
    },
    expandNode: {
      reducer(state, action) {
        const {
          meta: { tree },
          payload: { treeData },
        } = action;
        const catalogId = getCatalogId(tree);
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], { treeData });
      },
      prepare(tree: NodeBase, treeData: NodeBaseItem, node, expanded) {
        return {
          meta: { tree },
          error: null,
          payload: {
            treeData,
            treeNode: {
              expanded,
              node,
            },
          },
        };
      },
    },
    updateCategoryImage(state, action) {
      const {
        catalogId,
        url,
        nodeId,
        comment = '',
        isGlobal = false,
      } = action.payload;
      const tree = state.catalogs[catalogId];
      const path = getPathById(nodeId) as string;
      const res = getNodeInTree({ treeData: tree.treeData, path }) as NodeBaseItem;

      if (!res?.node?.category) return;

      const updatedCategory = {
        image: url,
        imageOverride: !isGlobal,
        imageComment: isGlobal ? '' : comment,
      };

      res.node.category = {
        ...res.node.category,
        ...updatedCategory,
      };

      const newTree = updateNodeInTree({
        node: res.node,
        path,
        treeData: tree.treeData,
      }) as NodeBase[];

      state.catalogs[catalogId].treeData = newTree;

      state.selectedNodeItem[catalogId].node.category = {
        ...state.selectedNodeItem[catalogId].node.category,
        ...updatedCategory,
      };
    },
    updateProductCategoryImage(state, action) {
      const {
        catalogId,
        nodeId,
        isGlobal = false,
        itemNo,
        productType,
        productName,
        image,
      } = action.payload;
      const tree = state.catalogs[catalogId];
      const path = getPathById(nodeId) as string;
      const res = getNodeInTree({ treeData: tree.treeData, path }) as NodeBaseItem;

      if (!res?.node?.category) return;

      const updatedCategory = {
        productImage: {
          image,
          itemNo,
          productType,
          productName,
        },
        productImageOverride: !isGlobal,
      };

      res.node.category = {
        ...res.node.category,
        ...updatedCategory,
      };

      const newTree = updateNodeInTree({
        node: res.node,
        path,
        treeData: tree.treeData,
      }) as NodeBase[];

      state.catalogs[catalogId].treeData = newTree;

      state.selectedNodeItem[catalogId].node.category = {
        ...state.selectedNodeItem[catalogId].node.category,
        ...updatedCategory,
      };
    },

    updateData: {
      prepare(
        tree: string,
        treeData: NodeBase[],
        { selectNodeAtPath, ...extra } = {},
      ) {
        return {
          meta: {
            tree,
            selectNodeAtPath,
          },
          error: null,
          payload: {
            ...extra,
            treeData,
          },
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload,
        } = action;
        const catalogId = getCatalogId(tree);
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], payload);
      },
    },
    updateSelectedCategoryNode(
      state,
      action: PayloadAction<{
        property: string;
        value: unknown;
        treeId: string;
        isDirty: boolean;
      }>,
    ) {
      const { property, value, treeId, isDirty } = action.payload;
      const catalogId = getCatalogId(treeId);

      const newCategory = state.selectedNodeItem[catalogId].node as {
        category: { [key: string]: unknown };
        isDirty: boolean;
        isNew: boolean;
      };

      newCategory.category[property] = value;
      newCategory.isDirty = isDirty;
      newCategory.isNew = !newCategory.category.categoryId;
    },
    updateSelectedItemNode(
      state,
      action: PayloadAction<{
        catalogId: string;
        mainParentDTOs: {
          parentCategoryId: string | null;
          parentCategoryName: string | null;
          parentExternalCategoryId: string | null;
          mainParent: boolean;
        }[];
      }>,
    ) {
      const mainParentDTOs = action.payload.mainParentDTOs;
      const catalogId = action.payload.catalogId;
      state.selectedNodeItem[catalogId].node.item.mainParentDTOs = mainParentDTOs;
    },
    selectNodeIsDirty(state, action) {
      const { tree, isDirty } = action.payload;
      const catalogId = getCatalogId(tree);
      if (state?.selectedNodeItem && state?.selectedNodeItem[catalogId]) {
        state.selectedNodeItem[catalogId].node.isDirty = isDirty;
      }
    },
    highlightSuggestedCategories: {
      prepare(tree: string, highlight: boolean, highlightItem?: string) {
        return { meta: { tree }, payload: { highlight, highlightItem } };
      },
      reducer: highlightSuggestedCategoriesReducer,
    },
    resetTreeSorting(state, action) {
      const { catalogId } = action.payload;

      state.catalogs[catalogId].treeData = state.originalTree;
      state.originalTree = [];
      state.sortedCatalogs = {};
    },
    resetSortOrder(state) {
      state.sortedCatalogs = {};
    },
    resetDirective(state) {
      state.action = {};
    },
    saveSortOrder(state, action) {
      const { catalogId, parentId, categoryId, currentTree, newTree } =
        action.payload;

      const currentCatalog = state.sortedCatalogs[catalogId] || {};
      const currentParent = (currentCatalog && currentCatalog[parentId]) || {};
      const currentCategory = (currentParent && currentParent[categoryId]) || null;

      // set original tree on first move
      if (!Object.keys(state.originalTree).length) {
        state.originalTree = currentTree;
      }

      const originalParent =
        findFirstNode({
          treeData: state.originalTree,
          searchMethod: (n: NodeBaseItem) => n.node.id === parentId,
          ignoreCollapsed: false,
        }) || ({} as NodeBaseItem | Record<string, never>);

      // check each category in parent if its the same as original pos and delete
      if (Object.keys(currentParent).length) {
        const newParent =
          findFirstNode({
            treeData: newTree,
            searchMethod: (n: NodeBaseItem) => n.node.id === parentId,
            ignoreCollapsed: false,
          }) || ({} as NodeBaseItem | Record<string, never>);

        const categories = Object.keys(currentParent);

        originalParent?.node?.children.forEach((originalChild, index) => {
          if (
            originalChild.categoryId === newParent.node.children[index].categoryId &&
            categories.includes(originalChild.categoryId)
          ) {
            delete state.sortedCatalogs[catalogId][parentId][
              originalChild.categoryId
            ];
          }
        });
      }

      if (currentCategory == null) {
        const positionBeforeChange = originalParent.node.children.findIndex(
          (f: NodeBase) => f.categoryId === categoryId,
        );

        state.sortedCatalogs = {
          ...state.sortedCatalogs,
          [catalogId]: {
            ...state.sortedCatalogs[catalogId],
            [parentId]: {
              ...(state.sortedCatalogs[catalogId] &&
                state.sortedCatalogs[catalogId][parentId]),
              [categoryId]: {
                positionBeforeChange,
              },
            },
          },
        };
      }

      // clear parentNode if empty
      if (
        currentCategory &&
        Object.keys(state.sortedCatalogs[catalogId][parentId]).length === 0
      ) {
        delete state.sortedCatalogs[catalogId][parentId];
      }
      if (
        currentCategory &&
        Object.keys(state.sortedCatalogs[catalogId]).length === 0
      ) {
        delete state.sortedCatalogs[catalogId];
        state.originalTree = [];
      }
    },
    search: {
      prepare(tree: string) {
        return { meta: { tree }, payload: {}, error: null };
      },
      reducer(state, action) {
        const {
          meta: { tree },
        } = action;
        const catalogId = getCatalogId(tree);
        const { searchCondition = {}, treeData = [] } =
          state.catalogs[catalogId] || {};
        const searchQuery = toSearchQuery(searchCondition);

        // findNodes and find function needs to be properly typed to avoid casting this as unknown
        const foundNode = findNodes({
          treeData,
          searchMethod: (ni: NodeBaseItem) =>
            customSearchMethod({ node: ni.node, searchQuery }),
          searchFocusOffset: null,
          searchQuery: null,
        }) as unknown;

        const { matches } = foundNode as {
          matches: NodeBaseItem[];
        };

        const searchFocusIndex = matches.length ? 0 : null;
        const searchFocusId = matches[0]?.node.id;
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          searchFocusId,
          searchFocusIndex,
          searchMatches: matches,
          searchQuery,
          treeData: withSearchResults({
            treeData,
            searchFocusId,
            searchMatches: matches,
            expandMatches: true,
          }),
        });
        if (matches.length) {
          state.selectedNodeItem[catalogId] = matches[0];
        }
      },
    },
    setIsFetching: {
      prepare(tree: string, isFetching) {
        return { meta: { tree, isFetching }, payload: null, error: null };
      },
      reducer(state, action) {
        const {
          meta: { tree, isFetching },
        } = action;
        const catalogId = getCatalogId(tree);
        state.catalogs[catalogId].isFetching = isFetching;
      },
    },
    searchUpdate: {
      prepare(
        tree: string,
        catalogIdsList: string[],
        item: Item,
        structureReasonText: string,
      ) {
        return {
          meta: { tree },
          error: null,
          payload: { catalogIdsList, item, structureReasonText },
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload,
        } = action;
        const catalogId = getCatalogId(tree);
        const { catalogIdsList, item, structureReasonText } = payload || {};
        const { searchQuery, treeData = [] } = state.catalogs[catalogId] || {};

        const foundNodes = findNodes({
          treeData,
          searchMethod: (ni: NodeBaseItem) =>
            customSearchMethod({ node: ni.node, searchQuery }),
          searchFocusOffset: null,
          searchQuery: null,
        }) as unknown;

        const { matches: searchMatches } = foundNodes as {
          matches: NodeBaseItem[];
        };

        const searchFocusIndex = searchMatches.length ? 0 : null;
        const searchFocusId = searchMatches[0]?.node.id;
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          searchFocusId,
          searchFocusIndex,
          searchMatches,
          searchQuery: {
            updated: Date.now(),
            catalogIds: catalogIdsList,
            item,
            structureReasonText,
          },
          treeData: withSearchResults({
            treeData,
            searchFocusId,
            searchMatches,
            expandMatches: true,
          }),
        });
      },
    },
    searchClear: {
      prepare(tree: string) {
        return { meta: { tree }, payload: {}, error: null };
      },
      reducer(state, action) {
        const {
          meta: { tree },
        } = action;
        if (tree) {
          const catalogId = getCatalogId(tree);
          Object.assign(
            state.catalogs[catalogId],
            clearSearch(state.catalogs[catalogId]?.treeData),
          );
        } else {
          const catalogIds = Object.keys(state).filter(
            (key) =>
              key === UNCATEGORIZED_CATALOG.catalogId ||
              !Number.isNaN(Number.parseInt(key, 10)),
          );
          catalogIds.forEach(
            (s, id) =>
              Object.assign(s[id], clearSearch(state.catalogs[id]?.treeData)),
            state,
          );
        }
      },
    },
    searchSetCondition: {
      prepare(tree: string, by: string, condition?: string) {
        return {
          meta: { tree },
          error: null,
          payload: {
            by,
            condition: condition?.trim(),
          },
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload,
        } = action;
        const catalogId = getCatalogId(tree);
        const { by, condition } = payload;
        const searchCondition = condition ? { by, condition } : null;
        const treeData = state.catalogs[catalogId]?.treeData || [];
        const searchSuggestions = condition
          ? getFirstXSearchResults(treeData, toSearchQuery(searchCondition), 5)
          : [];
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          searchCondition,
          searchQuery: null,
          searchSuggestions,
        });
      },
    },
    searchMoveNext: {
      prepare(tree: string) {
        return { meta: { tree }, payload: null, error: null };
      },
      reducer(state, action) {
        const {
          meta: { tree },
        } = action;
        const catalogId = getCatalogId(tree);
        const catalog = state.catalogs[catalogId];
        const currentIndex = catalog?.searchFocusIndex || 0;
        if (Number.isNaN(currentIndex)) {
          return;
        }
        const { searchMatches } = catalog;
        const searchMatchCount = searchMatches?.length || 0;
        const searchFocusIndex =
          currentIndex >= searchMatchCount - 1 ? 0 : currentIndex + 1;
        const searchFocusId = searchMatches?.[searchFocusIndex]?.node.id;
        const treeData = withSearchResults({
          treeData: catalog.treeData,
          searchMatches,
          searchFocusId,
          expandMatches: false,
        });
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          searchFocusId,
          searchFocusIndex,
          treeData,
        });
        if (searchMatches.length) {
          state.selectedNodeItem[catalogId] = searchMatches[searchFocusIndex];
        }
      },
    },
    searchMovePrevious: {
      prepare(tree: string) {
        return { meta: { tree }, payload: {}, error: null };
      },
      reducer(state, action) {
        const {
          meta: { tree },
        } = action;
        const catalogId = getCatalogId(tree);
        const catalog = state.catalogs[catalogId];
        const currentIndex = catalog?.searchFocusIndex || 0;
        if (Number.isNaN(currentIndex)) {
          return;
        }
        const { searchMatches } = catalog;
        const searchMatchCount = searchMatches?.length || 0;
        const searchFocusIndex =
          currentIndex <= 0 ? searchMatchCount - 1 : currentIndex - 1;
        const searchFocusId = searchMatches?.[searchFocusIndex]?.node.id;
        const treeData = withSearchResults({
          treeData: catalog.treeData,
          searchMatches,
          searchFocusId,
          expandMatches: false,
        });
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          searchFocusId,
          searchFocusIndex,
          treeData,
        });
        if (searchMatches.length) {
          state.selectedNodeItem[catalogId] = searchMatches[searchFocusIndex];
        }
      },
    },
    searchToggle: {
      prepare(tree: string, open?: boolean) {
        return {
          meta: { tree },
          payload: { open },
          error: null,
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload,
        } = action;
        const catalogId = getCatalogId(tree);
        const { open = !state.catalogs[catalogId]?.searchPanel } = payload;
        if (!open && state.catalogs[catalogId].searchSuggestions) {
          const newState = {
            ...clearSearch(state.catalogs[catalogId]?.treeData),
          };
          // @ts-expect-error clearSearch not typed
          state.catalogs[catalogId] = newState;
        }
        state.catalogs[catalogId].searchPanel = open;
      },
    },
    selectNodes: {
      prepare(
        tree: string,
        selectedNodeItem: TreeState['selectedNodeItem'],
        selectedNodeItems: TreeState['selectedNodeItems'],
      ) {
        return {
          meta: { tree },
          error: null,
          payload: {
            selectedNodeItem,
            ...(selectedNodeItems?.length ? { selectedNodeItems } : {}),
          },
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload: { selectedNodeItem, selectedNodeItems },
        } = action;
        const catalogId = getCatalogId(tree);

        state.selectedNodeItem = {
          [catalogId]: selectedNodeItem,
        };

        state.selectedNodeItems = {
          [catalogId]: selectedNodeItems || [],
        };
      },
    },
    pasteNode: {
      prepare(tree: string, treeData: NodeBase) {
        return {
          meta: { tree },
          payload: {
            treeData,
          },
          error: null,
        };
      },
      reducer(state, action) {
        const {
          meta: { tree },
          payload: { treeData },
        } = action;
        const catalogId = getCatalogId(tree);
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], { treeData });
      },
    },
  },
  extraReducers: (builder) =>
    mergeStateReducerBuilder('state.tree', builder)
      .addCase(setMarket, () => ({ ...initialState }))
      .addCase(fetchCatalogRemovalInfo.fulfilled, (state, action) => {
        const catalogId = action?.payload?.catalogId;
        const removalInfo = action?.payload?.categories;
        state.catalogs[catalogId] = extend(state.catalogs[catalogId], {
          removalInfo,
        });
      })
      .addCase(removeCatalogs, (state, action) => {
        const catalogIds = action.payload;
        catalogIds.forEach((catalogId) => {
          delete state.catalogs[catalogId];
          delete state.selectedNodeItem[catalogId];
          delete state.selectedNodeItems[catalogId];
        });
      })
      .addCase(createTreesFromPreviewResult, (state, action) => {
        const { payload } = action;
        const data = Object.entries(payload.trees).reduce((t, [treeId, treeObj]) => {
          const catalogId = getCatalogId(treeId);
          if (!catalogId) {
            return t;
          }
          return Object.assign(t, { [catalogId]: treeObj });
        }, {});

        Object.assign(state.catalogs, data);
      })
      .addMatcher(isAnyOf(categorize, copyNode, cutNode), (state, action) => {
        const {
          meta: { treeId },
          payload: { directive, nodeItems },
        } = action;

        state.action = {
          directive,
          nodeItems,
          tree: treeId,
        };
      }),
});

export const {
  expandNode,
  highlightSuggestedCategories,
  pasteNode,
  search,
  searchClear,
  searchMoveNext,
  searchMovePrevious,
  searchToggle,
  setIsFetching,
  searchUpdate,
  updateCategoryImage,
  updateProductCategoryImage,
  selectNodes,
  selectNodeIsDirty,
  updateData,
  updateSelectedCategoryNode,
  updateSelectedItemNode,
  resetSelectedNode,
  saveSortOrder,
  resetDirective,
  resetSortOrder,
  resetTreeSorting,
} = treeSlice.actions;

export const searchSetConditionByFlag = (tree: string, flag: string) =>
  treeSlice.actions.searchSetCondition(tree, SEARCH_BY.FLAG, flag);

export const searchSetConditionByText = (tree: string, text: string) =>
  treeSlice.actions.searchSetCondition(tree, SEARCH_BY.TEXT, text);

export { categorize, copyNode, cutNode };

export default treeSlice.reducer;

const treeSelector = (state: RootState) => state.tree;

export const selectCurrentCommand = createReferenceEqualSelector(
  treeSelector,
  (state) => state.action,
);

export const selectSortedCatalogs = createReferenceEqualSelector(
  treeSelector,
  (state) => state.sortedCatalogs,
);

export const selectSelectedNodeItem = createReferenceEqualSelector(
  treeSelector,
  (state) => state.selectedNodeItem,
);
export const selectSelectedNodeItems = createReferenceEqualSelector(
  treeSelector,
  (state) => state.selectedNodeItems,
);
export const selectTreeDataByCatalogId = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state?.catalogs[catalogId]?.treeData || ReadOnlyArray.Empty,
);
export const selectTreeDataByTreeId = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.treeData || ReadOnlyArray.Empty,
);
export const selectTreeCategoryRemovalInfo = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state.catalogs[catalogId]?.removalInfo || [],
);

export const selectIsFetchingTree = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state.catalogs[catalogId]?.isFetching,
);

export const selectTreeObject = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId] || ReadOnlyObject.Empty,
);
export const selectTreeTotalNumberOfElements = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state.catalogs[catalogId]?.meta?.totalNumberOfElements || 0,
);
export const selectSearchCatalogHits = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) =>
    state.catalogs[catalogId]?.searchQuery?.catalogIds || ReadOnlyArray.Empty,
);
export const selectSearchConditionForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchCondition || null,
);
export const selectSearchFocusIdForCatalog = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state.catalogs[catalogId]?.searchFocusId,
);
export const selectSearchFocusIdForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchFocusId,
);

export const selectSearchFocusIndexForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => {
    const searchFocusIndex = state.catalogs[catalogId]?.searchFocusIndex;
    return Number.isNaN(searchFocusIndex) ? null : searchFocusIndex;
  },
);

export const selectSearchItemHit = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchQuery?.item || null,
);

export const selectSearchMatchesForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) =>
    state.catalogs[catalogId]?.searchMatches || ReadOnlyArray.Empty,
);

export const selectSearchMatchesCountForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchMatches?.length || 0,
);

export const selectSearchPanelOpenForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchPanel || false,
);

export const selectSearchQueryForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) => state.catalogs[catalogId]?.searchQuery || null,
);

export const selectSearchSuggestionsForTree = createReferenceEqualSelector(
  treeSelector,
  getCatalogIdFromTreeId,
  (state, catalogId) =>
    state.catalogs[catalogId]?.searchSuggestions || ReadOnlyArray.Empty,
);

export const selectSuggestionFocusIdForCatalog = createReferenceEqualSelector(
  treeSelector,
  getIdFromArgument,
  (state, catalogId) => state.catalogs[catalogId]?.highlightFocusId,
);
