import { withMapArgs } from '../withMapArgs';
import { clearData, readData, storeData } from './operations';

export default function create(storageType, namespace) {
  const clear = clearData.bind(null, storageType);
  const read = readData.bind(null, storageType);
  const store = storeData.bind(null, storageType);

  return {
    clearStorage: () =>
      Object.keys(storageType)
        .filter((key) => key.startsWith(`${namespace}.`))
        .forEach(clear),
    clearData: withMapArgs((key) => [`${namespace}.${key}`])(clear),
    readData: withMapArgs((key) => [`${namespace}.${key}`])(read),
    storeData: withMapArgs((key, data) => [`${namespace}.${key}`, data])(store),
  };
}
