import Button from '@ingka/button';
import styles from './UpdateApplication.module.scss';
import IkeaLogo from '../IkeaLogo/IkeaLogo';

export const UpdateApplication = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <IkeaLogo />
          <h4 className={styles.text}>
            An update is available, please reload the application
          </h4>
          <Button
            size="small"
            className={styles.button}
            type="primary"
            text="Reload"
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
    </>
  );
};
