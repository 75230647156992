import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCommand } from '../../../store/selectors/tree';
import {
  treeConfigSelector,
  treeIdByCatalogIdSelector,
} from '../../../store/selectors/tree/data';
import { NODE_COPY } from '../../../utils/command';
import { getCatalogId } from '../../../utils/tree/ids';
import { getPathById, hasPasteOption } from '../../../utils/tree/treeNode';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NODE_TYPE_MULTI_SELECT,
} from '../../../utils/types/nodeTypes';
import { getCatalogActions } from './helpers/getCatalogActions';
import { getCategoryActions } from './helpers/getCategoryActions';
import { getItemActions } from './helpers/getItemActions';
import { getMultiselectActions } from './helpers/getMultiselectActions';
import { selectCatalog } from '../../../store/slices/catalog/index';

export const useHoverActionsNodeConfig = (node) => {
  const dispatch = useDispatch();
  const nodeItem = useMemo(
    () => ({
      node,
      path: getPathById(node.id),
    }),
    [node],
  );
  const targetTree = useSelector((state) =>
    treeIdByCatalogIdSelector(state, node.catalogId),
  );
  const {
    directive,
    nodeItems,
    tree: sourceTree,
  } = useSelector(selectCurrentCommand);
  const treeConfig = useSelector((state) =>
    treeConfigSelector(state, node.catalogId),
  );
  const sourceCatalogId = getCatalogId(sourceTree);
  const isCopy = useMemo(
    () => directive === NODE_COPY || node.catalogId !== sourceCatalogId,
    [directive, node.catalogId, sourceCatalogId],
  );
  const catalog = useSelector((state) => selectCatalog(state, node.catalogId));
  const catalogType = catalog?.catalogType;
  const actions = useMemo(() => {
    if (!treeConfig.allowHoverActions) {
      return {};
    }
    switch (node.type) {
      case NODE_TYPE_MULTI_SELECT:
        return getMultiselectActions(treeConfig, {
          dispatch,
          nodeItem,
          tree: targetTree,
        });
      case NODE_TYPE_CATALOG:
        return getCatalogActions(treeConfig, {
          directive,
          dispatch,
          isCopy,
          sourceNodeItems: nodeItems,
          targetNodeItem: nodeItem,
          tree: targetTree,
        });
      case NODE_TYPE_CATEGORY:
        return getCategoryActions(treeConfig, {
          directive,
          dispatch,
          isCopy,
          nodeItem,
          nodeItems,
          sourceTree,
          tree: targetTree,
          catalogType,
        });
      case NODE_TYPE_ITEM:
        return getItemActions(treeConfig, {
          dispatch,
          nodeItem,
          tree: targetTree,
        });

      default:
        return {};
    }
  }, [
    directive,
    dispatch,
    isCopy,
    node.type,
    nodeItem,
    nodeItems,
    sourceTree,
    targetTree,
    treeConfig,
    catalogType,
  ]);

  const nodeConfig = useMemo(
    () => ({
      actions,
      onHoverOnly: node.type !== NODE_TYPE_MULTI_SELECT,
      showPaste: !!nodeItems?.length && hasPasteOption(node),
    }),
    [actions, node, nodeItems?.length],
  );

  return nodeConfig;
};
