import { memo } from 'react';

import { NODE_TYPE_CATEGORY, NODE_TYPE_ITEM } from '../../../utils/types/nodeTypes';
import { TabTypes, PropertyTab } from './PropertyTab';
import { NodeBase } from '../../../entities/Node';
import { useSelector } from 'react-redux';
import { selectIsGlobalMarket } from '../../../store/slices/intl';
import {
  masterCatalogIdSelector,
  masterDraftCatalogIdSelector,
} from '../../../store/selectors/catalog/ids';

const TabOptionsGlobal: { [key: string]: TabTypes[] } = {
  [NODE_TYPE_CATEGORY]: ['Details', 'Images', 'Relations', 'History'],
  [NODE_TYPE_ITEM]: ['Details', 'ItemStartEnd', 'Relations', 'History'],
};

const TabOptionsMarket: { [key: string]: TabTypes[] } = {
  [NODE_TYPE_CATEGORY]: ['Details', 'Images', 'Relations', 'History'],
  [NODE_TYPE_ITEM]: ['Details', 'Relations'],
};

const PropertiesTabs = ({
  type,
  node,
  activeTab,
  setActiveTab,
}: {
  type: string;
  node: NodeBase;
  activeTab: TabTypes;
  setActiveTab: (type: TabTypes) => void;
}) => {
  const isGlobalMarket = useSelector(selectIsGlobalMarket);
  const masterCatalogId = useSelector(masterCatalogIdSelector);
  const draftCatalogId = useSelector(masterDraftCatalogIdSelector);
  const masterAndDraftId = [masterCatalogId, draftCatalogId];

  switch (type) {
    case NODE_TYPE_CATEGORY: {
      const showRelations = !!node.category?.mainParentCategoryId;
      const tabs = isGlobalMarket ? TabOptionsGlobal : TabOptionsMarket;

      return (
        <>
          {tabs[type]
            .filter((f) => showRelations || f !== 'Relations')
            .map((m) => (
              <PropertyTab
                activeType={activeTab}
                setActiveTab={setActiveTab}
                key={m}
                type={m}
              />
            ))}
        </>
      );
    }
    case NODE_TYPE_ITEM: {
      const tabs = isGlobalMarket ? TabOptionsGlobal : TabOptionsMarket;
      const showRelations =
        masterAndDraftId.includes(node.catalogId) &&
        node?.item?.mainParentDTOs?.length > 1;

      return (
        <>
          {tabs[type]
            .filter((f) => f !== 'Relations' || showRelations)
            .map((m) => (
              <PropertyTab
                activeType={activeTab}
                setActiveTab={setActiveTab}
                key={m}
                type={m}
              />
            ))}
        </>
      );
    }
    default:
      return (
        <PropertyTab
          activeType={activeTab}
          setActiveTab={setActiveTab}
          type="Details"
        />
      );
  }
};

export default memo(PropertiesTabs);
