import React, { PropsWithChildren } from 'react';
import styles from './Spinner.module.scss';

interface Props {
  height?: number;
  width?: number;
  children?: React.ReactNode;
}

export const Spinner = ({
  children,
  width = 30,
  height = 30,
}: PropsWithChildren<Props>) => (
  <>
    <span style={{ height, width }} className={styles.loader} />
    {children}
  </>
);
