import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import Header from './components/Header';
import CatalogManager from './components/CatalogManager';
import {
  countryPrefix,
  ROUTE_AUTOCATEGORIZED,
  ROUTE_IMAGE_OVERRIDES,
  ROUTE_ITEM_GROPUPING,
  ROUTE_NVFS,
  ROUTE_UNHIDDEN,
} from './routes';
const Toaster = lazy(() => import('./components/Toaster'));
const ModalManager = lazy(() => import('./components/ModalManager'));
import { selectAppIsLoading } from './store/slices/app';

const Root = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Suspense>
        <ModalManager />
        <Toaster />
      </Suspense>
    </>
  );
};

const BubbleError = () => {
  /* rethrow to react error boundary */
  const error = useRouteError();
  throw error;
};

export const Router = () => {
  const auth0 = useSelector((state: RootState) => state.auth0Client);
  const appLoading = useSelector(selectAppIsLoading);
  const marketCode = useSelector((state: RootState) => state.intl.marketCode);

  if (!auth0.client || appLoading) return null;

  const routes = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <BubbleError />,
      children: [
        {
          index: true,
          element: <CatalogManager />,
          loader: async () => {
            if (marketCode) {
              return redirect(`/${marketCode}?`);
            }
            return true;
          },
        },
        {
          path: `/${countryPrefix}?`,
          element: <CatalogManager />,
        },
        {
          path: ROUTE_AUTOCATEGORIZED,
          lazy: async () => {
            const { AutoCategorized } = await import(
              './components/Reports/AutoCategorized/AutoCategorized'
            );
            return { Component: AutoCategorized };
          },
        },
        {
          path: ROUTE_UNHIDDEN,
          lazy: async () => {
            const { UnHidden } = await import(
              './components/Reports/UnHidden/UnHidden'
            );
            return { Component: UnHidden };
          },
        },
        {
          path: ROUTE_ITEM_GROPUPING,
          lazy: async () => {
            const { ItemGrouping } = await import(
              './components/Reports/ItemGrouping/ItemGrouping'
            );
            return { Component: ItemGrouping };
          },
        },
        {
          path: ROUTE_IMAGE_OVERRIDES,
          lazy: async () => {
            const { ImageOverrides } = await import(
              './components/Reports/ImageOverrides/ImageOverrides'
            );

            return { Component: ImageOverrides };
          },
        },
        {
          path: ROUTE_NVFS,
          lazy: async () => {
            const { NotValidForStructureItemReports } = await import(
              './components/Reports/NotValidForStructureItemReports/NotValidForStructureItemReports'
            );
            return { Component: NotValidForStructureItemReports };
          },
        },
        {
          path: '*',
          loader: async () => {
            if (marketCode) {
              return redirect(`/${marketCode}?`);
            }
            return true;
          },
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
};
