import { returnArg } from '../function';

export function extend(...args) {
  return Object.assign({}, ...args);
}

function getPathParts(path) {
  return path.reduce(
    (_parts, part) => [
      ..._parts,
      ...(typeof part === 'string' ? part.split(/\.+/) : [part]),
    ],
    [],
  );
}

function getPropertyOrValue(obj, value, ...path) {
  if (typeof obj === 'undefined' || obj === null) {
    return value;
  }
  const parts = getPathParts(path);
  const propVal = parts.reduce(
    (part, property) =>
      typeof part?.[property] !== 'undefined' ? part?.[property] : undefined,
    obj,
  );

  return typeof propVal !== 'undefined' && propVal !== null ? propVal : value;
}

export function createComparer(...path) {
  return (a, b) => {
    const aValue = getPropertyOrValue(a, undefined, ...path);
    const bValue = getPropertyOrValue(b, undefined, ...path);
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };
}

export function extendDeep(obj, value, ...path) {
  if (!path.length) {
    return extend(
      obj,
      Object.entries(value).reduce(
        (accumulator, [key, val]) =>
          Object.assign(accumulator, {
            [key]:
              val !== null &&
              typeof val === 'object' &&
              !Array.isArray(val) &&
              !(val instanceof RegExp) &&
              typeof obj[key] === 'object'
                ? extendDeep(obj[key], val)
                : val,
          }),
        {},
      ),
    );
  }
  const currentPaths = getPathParts(path);
  const property = currentPaths.shift();
  return {
    ...obj,
    [property]: extendDeep(
      getPropertyOrValue(obj, {}, property),
      value,
      ...currentPaths,
    ),
  };
}

export function extendFromEntries(obj, [key, value]) {
  return Object.assign(obj, { [key]: value });
}

export function objectFromArray(array, keyFunc) {
  return array.reduce(
    (obj, item) => Object.assign(obj, { [keyFunc(item)]: item }),
    {},
  );
}

export function within(obj, ...props) {
  return props.reduce(
    (result, key) =>
      Object.assign(result, obj[key] !== undefined ? { [key]: obj[key] } : {}),
    {},
  );
}

export function without(obj, ...props) {
  return Object.keys(obj).reduce(
    (result, key) =>
      props.indexOf(key) < 0 ? Object.assign(result, { [key]: obj[key] }) : result,
    {},
  );
}

export function mapObject(obj, funcOrOpts) {
  if (typeof funcOrOpts === 'function') {
    return mapObject(obj, { valueMapper: funcOrOpts });
  }
  if (typeof obj !== 'object' || typeof funcOrOpts !== 'object') {
    return obj;
  }
  const { keyMapper = returnArg, valueMapper = returnArg } = funcOrOpts;
  return Object.entries(obj).reduce(
    (mapped, [key, value]) =>
      Object.assign(mapped, { [keyMapper(key)]: valueMapper(value) }),
    {},
  );
}
