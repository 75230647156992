import styles from './IkeaLogo.module.scss';
import svgLogo from './logo.svg';

const IkeaLogo = () => (
  <img
    alt="IKEA Logotype"
    className={styles.logo}
    height="40"
    src={svgLogo}
    width="100"
  />
);

export default IkeaLogo;
