import { useListenerMiddleware } from '../../hooks/useListenerMiddleware';
import { requestExcelPendingListenerOptions as requestExcelPendingListener } from '../../store/middlewares/export';
import {
  activateDraftCatalogListenerOptions as activateDraftCatalogListener,
  catalogDeletedListenerOptions as catalogDeletedListener,
} from '../../store/slices/catalog/listeners';
import { categoryReceivedListenerOptions as categoryReceivedListener } from '../../store/slices/category/listeners';
import { draftStatusReceivedListenerOptions as draftStatusReceivedListener } from '../../store/slices/draftstatus/listeners';
import { itemsReceivedListenerOptions as itemsReceivedListener } from '../../store/slices/item/listeners';
import {
  paneSetSourceListenerOptions as paneSetSourceListener,
  toggleActivePaneListenerOptions as toggleActivePaneListener,
} from '../../store/slices/pane/listeners';
import {
  treeActionsListenerOptions as treeActionsListener,
  treeExpandNodeListenerOptions as treeExpandNodeListener,
  treeSearchListenerOptions as treeSearchListener,
  treeUpdateDataListenerOptions as treeUpdateDataListener,
} from '../../store/slices/tree/listeners';

const MiddlewareWrapper = function MiddlewareWrapper({ children }) {
  useListenerMiddleware(activateDraftCatalogListener);
  useListenerMiddleware(catalogDeletedListener);
  useListenerMiddleware(categoryReceivedListener);
  useListenerMiddleware(draftStatusReceivedListener);
  useListenerMiddleware(itemsReceivedListener);
  useListenerMiddleware(paneSetSourceListener);
  useListenerMiddleware(requestExcelPendingListener);
  useListenerMiddleware(toggleActivePaneListener);
  useListenerMiddleware(treeActionsListener);
  useListenerMiddleware(treeExpandNodeListener);
  useListenerMiddleware(treeSearchListener);
  useListenerMiddleware(treeUpdateDataListener);
  return children;
};

export default MiddlewareWrapper;
