import { createAsyncThunk } from '@reduxjs/toolkit';
import { findFirstNode } from '../../../components/Tree/helpers';
import { resetSortOrder } from '../../slices/tree';
import { sortMarketCategory } from '../api/categories';
import { destructId } from '../../../utils/tree/treeNode';
import { RootState } from '../..';
import { NodeBaseItem } from '../../../entities/Node';

export const saveCategorySorting = createAsyncThunk(
  'tree/saveCategorySorting',
  async (catalogId: string, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const { tree } = getState() as RootState;
    const { treeData } = tree.catalogs[catalogId];

    const payload = Object.keys(tree.sortedCatalogs[catalogId]).reduce<
      {
        categoryId: string;
        rank: number;
        parentCategoryId: number;
      }[]
    >((acc, cur) => {
      const parent = findFirstNode({
        treeData,
        searchMethod: (n: NodeBaseItem) => n.node.id === cur,
        ignoreCollapsed: false,
      }) as NodeBaseItem | undefined;

      // @ts-expect-error destructId no typed
      const parentCategoryId: number = destructId(parent?.node.id).categoryId;

      const changed =
        parent?.node.children.map((childCategory, index) => ({
          categoryId: childCategory.categoryId,
          rank: index + 1,
          parentCategoryId,
        })) || [];

      acc.push(...changed);
      return acc;
    }, []);

    if (payload.length) {
      await dispatch(
        sortMarketCategory({
          catalogId,
          payload,
        }),
      );
    }
    dispatch(resetSortOrder());
  },
);
