import {
  CATALOG_TYPE_COLLECTION,
  CATALOG_TYPE_SERIES,
} from '../../../utils/types/catalogTypes';
import { selectCatalog } from '../../selectors/catalog';
import { fetchCatalogRemovalInfo } from '../catalog/fetchCatalogRemovalInfo';
import { setSource } from '../pane';

const seriesCollections = [CATALOG_TYPE_COLLECTION, CATALOG_TYPE_SERIES];

export const selectSourceTree = (pane, catalogId) => async (dispatch, getState) => {
  const state = getState();
  const source = selectCatalog(state, catalogId);
  if (!source) {
    return undefined;
  }

  if (seriesCollections.includes(source.catalogType)) {
    dispatch(fetchCatalogRemovalInfo({ catalogId }));
  }

  return dispatch(setSource({ pane, source }));
};
