import { useEffect } from 'react';
import { initialize, pageview } from 'react-ga';
import {
  ANALYTICS_DEBUGMODE,
  ANALYTICS_ENABLED,
  ANALYTICS_TESTMODE,
  ANALYTICS_TRACKINGID,
} from '../utils/analytics';

const options = {
  debug: ANALYTICS_DEBUGMODE,
  testMode: ANALYTICS_TESTMODE,
  titleCase: true,
};

export const useAnalyticsInitialize = () => {
  useEffect(() => {
    if (!ANALYTICS_ENABLED) {
      return;
    }

    initialize(ANALYTICS_TRACKINGID, options);
    pageview(window.location.pathname + window.location.search);
  }, []);
};
