import {
  addNodesInTree,
  getNodeInTree,
  updateMatchingNodesInTree,
} from '../../../components/Tree/helpers';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { formatItemNo } from '../../../utils/data/utils';
import { getItemId } from '../../../utils/item';
import { getCatalogId } from '../../../utils/tree/ids';
import { createUpdatedNode } from '../../../utils/tree/node';
import { getNodeType } from '../../../utils/tree/nodeItem';
import { destructId } from '../../../utils/tree/treeNode';
import { NODE_TYPE_CATEGORY, NODE_TYPE_ITEM } from '../../../utils/types/nodeTypes';
import {
  selectCurrentCommand,
  selectTreeDataByCatalogId,
} from '../../selectors/tree';
import { treeConfigSelector } from '../../selectors/tree/data';
import { addItemsToCategory } from '../api/items';
import { copyCategoryNode } from '../category/copyCategory';
import { newCopyPasteError } from '../error/newCopyPasteError';
import { toastMessage } from '../toaster';
import { pasteNode } from '../tree';

export const pasteCopy =
  (targetTree, targetNodeItem) => async (dispatch, getState) => {
    const state = getState();
    const { nodeItems, tree: sourceTree } = selectCurrentCommand(state);
    const nodeItem = nodeItems[0];
    const nodeType = getNodeType(nodeItem);
    const sourceCatalogId = getCatalogId(sourceTree);
    const targetCatalogId = getCatalogId(targetTree);
    const betweenCatalogs = targetCatalogId !== sourceCatalogId;
    const targetTreeConfig = treeConfigSelector(state, targetCatalogId);
    if (
      betweenCatalogs &&
      (nodeType !== NODE_TYPE_ITEM ||
        !targetTreeConfig.canPaste({
          node: nodeItem.node,
          nextParent: targetNodeItem.node,
        }))
    ) {
      return dispatch(
        newCopyPasteError(
          'Copy / paste is not allowed between theese two catalogues.',
        ),
      );
    }
    const sourceTreeData = selectTreeDataByCatalogId(state, sourceCatalogId);
    const sourceNodeItems = nodeItems.reduce((items, ni) => {
      const sourceItem = getNodeInTree({ treeData: sourceTreeData, path: ni.path });
      if (sourceItem) {
        items.push(sourceItem);
      }
      return items;
    }, []);
    if (!sourceNodeItems.length) {
      return dispatch(newCopyPasteError('Source item(s) can not be found'));
    }

    const destinationName = targetNodeItem?.node?.title || '';
    const newNodes = sourceNodeItems.map((sni) =>
      createUpdatedNode({
        node: sni.node,
        nextParentNode: targetNodeItem.node,
      }),
    );
    let nodesText =
      nodeItem.node.type === NODE_TYPE_CATEGORY ? 'Categories' : 'Items';
    if (nodeItem.node.type === NODE_TYPE_CATEGORY) {
      const newNode = newNodes[0];
      await dispatch(copyCategoryNode(newNode));
      nodesText = newNode.category.globalCategoryName;
    } else if (nodeItem.node.type === NODE_TYPE_ITEM) {
      const response = await dispatch(
        addItemsToCategory({
          catalogId: targetCatalogId,
          items: newNodes,
          categoryId: destructId(targetNodeItem.node.id).categoryId,
        }),
      ).unwrap();
      const {
        addedItems = ReadOnlyArray.Empty,
        existingItems = ReadOnlyArray.Empty,
        notValidForStructureItems = ReadOnlyArray.Empty,
      } = response || {};
      notValidForStructureItems.forEach((nvfsi) => {
        const itemId = getItemId(nvfsi);
        const index = newNodes.findIndex((node) => node?.item?.itemId === itemId);
        if (index < 0) {
          return;
        }
        newNodes.splice(index, 1);
      });
      let text = '';
      if (existingItems.length === 1) {
        text = `${formatItemNo(
          existingItems[0]?.itemNo || '',
        )}</b> allready exists in <b>${destinationName}\n`;
      } else if (existingItems.length) {
        text = `${existingItems.length} items</b> allready exists in <b>${destinationName}\n`;
      }
      if (notValidForStructureItems.length === 1) {
        text += `${formatItemNo(
          notValidForStructureItems[0]?.itemNo || '',
        )}</b> are not valid for structure\n<b>`;
      } else if (notValidForStructureItems.length) {
        text += `${notValidForStructureItems.length} items</b> are not valid for structure\n<b>`;
      }
      if (!addedItems.length) {
        text += 'no items';
      } else if (addedItems.length === 1) {
        text += formatItemNo(addedItems[0]?.itemNo || '');
      } else {
        text += `${addedItems.length} items`;
      }
      if (
        !existingItems.length &&
        !addedItems.length &&
        !notValidForStructureItems.length
      ) {
        text = 'no items';
      }
      nodesText = text;
    }

    const result = addNodesInTree(
      newNodes,
      betweenCatalogs
        ? selectTreeDataByCatalogId(getState(), targetCatalogId)
        : sourceTreeData,
      {
        expandParent: true,
        addAsFirstChild: true,
      },
    );

    dispatch(
      toastMessage(`<b>${nodesText}</b> copied to <b>${destinationName}</b>`),
    );

    if (nodeItem.node.type === NODE_TYPE_CATEGORY) {
      const selectedNodeParentId = destructId(nodeItem.node.parentId).value;
      const selectNodeMainParentCategoryId =
        nodeItem.node.category.mainParentCategoryId;

      // when copying a category, we need to update its relations so that if there is no
      // existing mainParentCategoryId, we set the selected nodes parent as mainParentCategoryId
      result.treeData = updateMatchingNodesInTree(
        (ni) => ni.node?.category?.categoryId === nodeItem.node.categoryId,
        result.treeData,
        {
          category: {
            mainParentCategoryId:
              selectNodeMainParentCategoryId ?? selectedNodeParentId,
          },
        },
      );
    }

    return dispatch(pasteNode(targetTree, result.treeData));
  };
