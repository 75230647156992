import SkapaLoading, { LoadingBall } from '@ingka/loading';
import cx from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({ horizontal, text }: { horizontal?: boolean; text?: string }) => {
  return (
    <SkapaLoading
      className={cx({
        [styles.horizontal]: horizontal,
        [styles.noText]: !text,
      })}
      text={text}
    >
      <LoadingBall />
    </SkapaLoading>
  );
};

export default Loading;
