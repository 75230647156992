import { toggleExpandedPropOnNode } from '../../../components/Tree/helpers';
import { selectTreeDataByTreeId } from '../../slices/tree';
import { expandNode } from '.';
import { toastMessage } from '../../slices/toaster';

export const expandTreeNode = (tree, treeNode) => async (dispatch, getState) => {
  const { expanded, node } = treeNode || {};
  if (node.category !== undefined) {
    const { childCategoryCount, childItemCount } = node.category;
    if (!childCategoryCount && !childItemCount) {
      const message = `<b>Empty category</b>`;
      dispatch(toastMessage(message));
      return false;
    }
  }
  const treeData = toggleExpandedPropOnNode({
    treeData: selectTreeDataByTreeId(getState(), tree),
    node,
  });

  return dispatch(expandNode(tree, treeData, node, expanded));
};
