import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './TreeSelector.module.scss';
import TreeSelectorNode from './TreeSelectorNode/TreeSelectorNode';
import { selectCatalogs } from '../../../store/slices/catalog';
import { selectTreeTotalNumberOfElements } from '../../../store/slices/tree';
import { useShallowEqualSelector } from '../../../hooks/useEqualitySelector';
import {
  selectUnpublishedCategories,
  selectUntranslatedCategories,
} from '../../../store/slices/indicator';
import type { RootState } from '../../../store';
import CatalogStatus from '../../CatalogStatus';
import { SummaryCount } from '../../StatusBadges/SummaryBadges';
import { Catalog } from '../../../entities/Catalog';
import { Dropdown } from '../../Dropdown/Dropdown';

const TreeSelector = function TreeSelector({
  onChange,
  pane,
  sourceId,
}: {
  pane: string;
  id: string;
  onChange: (catalogId: string) => void;
  sourceId: string;
}) {
  const unpublishedCategories = useShallowEqualSelector(selectUnpublishedCategories);
  const untranslatedCategories = useShallowEqualSelector(
    selectUntranslatedCategories,
  );

  const totalNumberOfElements = useShallowEqualSelector((state: RootState) =>
    selectTreeTotalNumberOfElements(state, sourceId),
  );

  const catalogs = useSelector(selectCatalogs);

  const sources = useMemo(
    () =>
      catalogs.map((c) => {
        const total =
          totalNumberOfElements &&
          c.catalogId === sourceId &&
          `${totalNumberOfElements} items`;
        const unpublishedCount = unpublishedCategories.filter(
          (catalog: Catalog) => catalog.catalogId === c.catalogId,
        ).length;
        const untranslatedCount = untranslatedCategories.filter(
          (catalog: Catalog) => catalog.catalogId === c.catalogId,
        ).length;
        const summaryCount = untranslatedCount + unpublishedCount;

        return {
          catalog: c,
          value: c.catalogId,
          text: c.catalogName,
          meta: {
            status: !!c.catalogStatus && <CatalogStatus catalog={c} />,
            total,
            indicators: summaryCount ? (
              <SummaryCount count={summaryCount} compact />
            ) : null,
          },
        };
      }),
    [
      catalogs,
      sourceId,
      totalNumberOfElements,
      unpublishedCategories,
      untranslatedCategories,
    ],
  );

  if (!sources.length) return null;

  return (
    <Dropdown
      id={pane}
      className={styles.dropdown}
      label="Catalog selection list"
      onChange={onChange}
      value={sourceId}
      itemList={sources}
      ItemRenderer={TreeSelectorNode}
    />
  );
};

export default TreeSelector;
