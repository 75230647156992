import { createSlice } from '@reduxjs/toolkit';
import { ReadOnlyObject } from '../../../utils/object/ReadOnlyObject';
import type { RootState } from '../..';

interface ModalState {
  modalType: string;
  modalProps: Record<string, 'unknown'>;
}
const initialState: ModalState = {
  modalProps: {},
  modalType: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    close() {
      return initialState;
    },
    open(state, action) {
      if (state.modalType === 'ErrorMessage') {
        return state;
      }
      const { modalType, modalProps } = action.payload;
      return { modalType, modalProps };
    },
  },
});

export const { close, open } = modalSlice.actions;
export default modalSlice.reducer;

export const selectCurrentModal = (state: RootState) =>
  state.modal || ReadOnlyObject.Empty;
