import React from 'react';
import cx from 'classnames';
import styles from '../components/Icons/Icons.module.scss';
import FolderFilledIcon from './raw/filledFolder.svg?react';
import { IconProps } from './types';

const FolderFilled = ({
  className,
  iconClass = true,
  label,
  style,
  viewBox,
}: IconProps) => (
  <FolderFilledIcon
    title={label}
    aria-labelledby={label}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    viewBox={viewBox || '0 0 24 24'}
    className={cx({
      [styles.svgIcon]: iconClass,
      ...(className
        ? {
            [styles[className]]: className && styles[className],
            [className]: className && !styles[className],
          }
        : {}),
    })}
    style={style}
  />
);

export default FolderFilled;
