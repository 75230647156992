import folder from '@ingka/ssr-icon/paths/folder';
import Badge from './Badge';
import styles from './StatusBadges.module.scss';

export const CategoryTypeBadge = ({
  compact = false,
  label = '',
  className = '',
}: {
  compact?: boolean;
  label?: string;
  className?: string;
}) => {
  return (
    <Badge iconPath={folder} label={label} compact={compact} className={className} />
  );
};

export const CategoryAccessoryBadge = () => (
  <CategoryTypeBadge label="Accessories" className={styles.categorytype} />
);

export const CategorySystemBadge = () => (
  <CategoryTypeBadge label="System" className={styles.categorytype} />
);

export const CategoryFurnitureBadge = () => (
  <CategoryTypeBadge label="Furniture" className={styles.categorytype} />
);

export const CategoryUndefined = () => (
  <CategoryTypeBadge
    label="Missing category type"
    className={styles.categorytypemissing}
  />
);
