import { catalogFromDto } from './dtoMappers';
import { CatalogCategories } from '../../schemas/catalogs';
import { STATE_UNPUBLISHED, STATE_VERIFIED } from '../../utils/category/states';

type CatalogCategory = CatalogCategories['categories'][number];

const catalogCategoryFromDto = (category: CatalogCategory) => {
  const categoryNameTranslationApproved = category.translateState === STATE_VERIFIED;

  const published = category.publishState !== STATE_UNPUBLISHED;

  return { ...category, categoryNameTranslationApproved, published };
};

const dtoToCategory = (category: CatalogCategory, languageCode: string) => {
  const newCategory = catalogCategoryFromDto(category);

  const categoryNameTranslationsVerified = {
    [languageCode]: category.categoryNameTranslation,
  };

  const childCategoryCount = category.childCategories.length;

  return {
    ...newCategory,
    categoryNameTranslationsVerified,
    childCategoryCount,
    childItems: [],
  };
};

export const dataFromDto = (treeDto: CatalogCategories, languageCode: string) => {
  const catalog = catalogFromDto(treeDto.catalogue);

  const categories = treeDto.categories.reduce<{
    [key: string]: CatalogCategory;
  }>(
    (cats, cat) =>
      Object.assign(cats, {
        [`${cat.categoryId}`]: dtoToCategory(cat, languageCode),
      }),
    {},
  );

  return { catalog, categories };
};

export type CatalogCategoriesFromDTO = ReturnType<typeof dataFromDto>;
