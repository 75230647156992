import cx from 'classnames';
import styles from '../components/Icons/Icons.module.scss';
import TranslationIcon from './raw/translation.svg?react';
import { IconProps } from './types';

const Translation = ({
  className,
  iconClass = true,
  label,
  style,
  viewBox,
}: IconProps) => (
  <TranslationIcon
    title={label}
    aria-labelledby={label}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    viewBox={viewBox || '-3 -3 20 20'}
    style={style}
    className={cx({
      [styles.svgIcon]: iconClass,
      ...(className
        ? {
            [styles[className]]: className && styles[className],
            [className]: className && !styles[className],
          }
        : {}),
    })}
  />
);

export default Translation;
