import { createAction } from '@reduxjs/toolkit';
import { addNodesInTree } from '../../../components/Tree/helpers';
import { extend } from '../../../utils/object';
import { makeTreeId } from '../../../utils/tree/ids';
import { toItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import {
  LIST_EXISTS,
  LIST_IMPORT,
  LIST_NOT_VALID_FOR_STRUCTURE,
} from '../../constants/importitems';
import {
  PANE_EXISTSTREE,
  PANE_IMPORTTREE,
  PANE_INVALIDTREE,
} from '../../constants/panes';

export const createTreesFromPreviewResult = createAction(
  'importitems/createTreesFromPreviewResult',
  (previewResult) => {
    const {
      notValidForStructureItems,
      relationAlreadyExistsItems,
      toBeImportedItems,
    } = previewResult;
    const trees = extend(
      makeItemsListTree(
        PANE_INVALIDTREE,
        LIST_NOT_VALID_FOR_STRUCTURE,
        notValidForStructureItems,
      ),
      makeItemsListTree(PANE_EXISTSTREE, LIST_EXISTS, relationAlreadyExistsItems),
      makeItemsListTree(PANE_IMPORTTREE, LIST_IMPORT, toBeImportedItems),
    );
    return { payload: { trees } };
  },
);

function makeItemsListTree(pane, listId, items) {
  const treeId = makeTreeId(pane, listId);
  const { treeData } = addNodesInTree(
    toItemNodes({
      catalogId: listId,
      items,
      level: 0,
    }),
    [],
  );
  return { [treeId]: { treeData } };
}
