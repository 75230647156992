import { walkTree } from '../../components/Tree/helpers';
import {
  CATEGORY_TYPE_ACCESSORIES,
  CATEGORY_TYPE_FURNITURE,
  CATEGORY_TYPE_SYSTEM,
} from '../category/categoryTypes';
import { destructId, hasUnpublished, hasUntranslated } from './treeNode';

export const getCategoryStatus = (treeData) => {
  const publish = {};
  const translate = {};
  const autoCategorisable = {};
  const categoryTypes = {
    [CATEGORY_TYPE_FURNITURE]: {},
    [CATEGORY_TYPE_SYSTEM]: {},
    [CATEGORY_TYPE_ACCESSORIES]: {},
    NOT_SET: {},
  };

  const invalidCategoryImage = {};
  const callback = ({ node, path }) => {
    if (!node?.category) {
      return;
    }
    if (hasUnpublished(node)) {
      addPaths(publish, node.category.categoryId, path);
    }
    if (hasUntranslated(node)) {
      addPaths(translate, node.category.categoryId, path);
    }
    if (node.category.autoCategorisable) {
      addPaths(autoCategorisable, node.category.categoryId, path);
    }
    if (node.invalidCategoryImage) {
      addPaths(invalidCategoryImage, node.category.categoryId, path);
    }
    if (Object.keys(categoryTypes).includes(node.category.categoryType)) {
      addPaths(
        categoryTypes[node.category.categoryType],
        node.category.categoryId,
        path,
      );
    }
    if (node.category.categoryType === null) {
      addPaths(categoryTypes.NOT_SET, node.category.categoryId, path);
    }
  };

  walkTree({
    treeData,
    callback,
    ignoreCollapsed: false,
  });

  return {
    unpublishedCategories: mapToDto(publish),
    untranslatedCategories: mapToDto(translate),
    autoCategorisable: mapToDto(autoCategorisable),
    categoryTypeAccessories: mapToDto(categoryTypes.ACCESSORIES),
    categoryTypeSystem: mapToDto(categoryTypes.SYSTEM),
    categoryTypeFurniture: mapToDto(categoryTypes.FURNITURE),
    categoryTypeUndefined: mapToDto(categoryTypes.NOT_SET),
    invalidCategoryImages: mapToDto(invalidCategoryImage),
  };
};

function addPaths(source, categoryId, path) {
  const paths = source[categoryId] || [];
  paths.push(path.map((p) => destructId(p).value));

  source[categoryId] = paths;
}

function mapToDto(source) {
  return Object.entries(source).map(([categoryId, paths]) => ({
    catalogId: paths[0][0],
    categoryId,
    paths: paths.map((p) => p.slice(1)),
  }));
}
