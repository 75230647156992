import {
  addNodesInTree,
  removeNodesFromTree,
  updateNodesInTree,
} from '../../../components/Tree/helpers';
import { getMultiselectPaths } from '../../../components/Tree/helpers/getMultiselectPaths';
import { CATEGORIZATION } from '../../../utils/command';
import { extendDeep } from '../../../utils/object';
import { updateDateGroups } from '../../../utils/tree/dateGroupNodes';
import { getCatalogId } from '../../../utils/tree/ids';
import { createUpdatedNode } from '../../../utils/tree/node';
import { selectIsCatalogDraft } from '../../selectors/catalog/draft';
import {
  selectCurrentCommand,
  selectTreeDataByTreeId,
  selectTreeObject,
} from '../../selectors/tree';
import { toastMessage } from '../toaster';
import { updateData } from '../tree';
import { addItemNodes } from './addItems';

export const categorizeItems =
  (targetTree, ...targetNodeItems) =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      directive,
      nodeItems: sourceNodeItems,
      tree,
    } = selectCurrentCommand(state);
    if (directive !== CATEGORIZATION) {
      return undefined;
    }
    const isDraftCatalog = selectIsCatalogDraft(state, getCatalogId(targetTree));
    const { treeData, meta } = selectTreeObject(state, tree);
    const sourceTreeData = isDraftCatalog
      ? updateNodesInTree({
          nodeItems: sourceNodeItems.map((ni) =>
            extendDeep(ni, { isInDraft: true }, 'node'),
          ),
          treeData,
        })
      : removeNodesFromTree({
          paths: sourceNodeItems.reduce(
            (paths, ni) => [...paths, ni.path],
            getMultiselectPaths(treeData),
          ),
          treeData,
        }).treeData;
    const updatedSourceTreeData = updateDateGroups(sourceTreeData);

    const toBeCategorized = targetNodeItems.reduce(
      (prev, curr) => {
        prev.categories.push(curr.node.categoryId);
        const itemsToAdd = sourceNodeItems.map((sni) =>
          createUpdatedNode({
            node: sni.node,
            nextParentNode: curr.node,
            props: {
              isInDraft: false,
              isUncategorized: false,
              isInSuggestion: false,
            },
          }),
        );
        prev.items.push(...itemsToAdd);
        return prev;
      },
      {
        categories: [],
        items: [],
      },
    );

    await dispatch(
      updateData(tree, updatedSourceTreeData, {
        meta: {
          ...meta,
          totalNumberOfElements:
            meta.totalNumberOfElements -
            (isDraftCatalog ? 0 : sourceNodeItems.length),
        },
      }),
    );

    const uniqueItemNumbers = [
      ...new Set(toBeCategorized.items.map((m) => m.itemNo)),
    ];

    await dispatch(
      addItemNodes({
        catalogId: getCatalogId(targetTree),
        categories: toBeCategorized.categories.map(Number),
        itemNos: uniqueItemNumbers,
      }),
    );

    const currentTreeData = selectTreeDataByTreeId(getState(), targetTree);
    const result = addNodesInTree(toBeCategorized.items, currentTreeData, {
      expandParent: true,
      addAsFirstChild: true,
    });

    dispatch(updateData(targetTree, result.treeData));

    const nodesText =
      toBeCategorized.items.length === 1
        ? toBeCategorized.items[0]?.itemNoFormated || ''
        : 'Items';
    const destinationName = targetNodeItems
      .map((targetNodeItem) => targetNodeItem?.node?.title || '')
      .filter((i) => !!i)
      .join(', ');
    return dispatch(
      toastMessage(`<b>${nodesText}</b> moved to <b>${destinationName}</b>`),
    );
  };
