import { hasStringValue } from '../string';
import { Market } from '../../entities/Market';

export const GLOBAL = 'ZZ';

export function isGlobalMarket(retailUnit: Market['retailUnit']) {
  return retailUnit === GLOBAL;
}

export function marketComparer(a: Market, b: Market) {
  const first = a.countryName || a.retailUnit;
  const second = b.countryName || b.retailUnit;

  if (a.retailUnit === GLOBAL) {
    return -1;
  }

  if (b.retailUnit === GLOBAL) {
    return 1;
  }

  if (first < second) {
    return -1;
  }

  if (first > second) {
    return 1;
  }

  return 0;
}

export function makeMarketCode(
  retailUnit: Market['retailUnit'],
  languageCode: Market['languageCodes'][0],
) {
  const ru = hasStringValue(retailUnit) ? retailUnit.toUpperCase() : '';
  const lang = hasStringValue(languageCode) ? languageCode.toLowerCase() : '';
  return `${ru}/${lang}`;
}

export function parseMarketCodeFromPathname(pathname: string) {
  const [, retailUnit, languageCode] = pathname.split('/');
  return {
    retailUnit: retailUnit && retailUnit.toUpperCase(),
    languageCode: languageCode && languageCode.toLowerCase(),
    marketCode: makeMarketCode(retailUnit, languageCode),
  };
}
