import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET, POST } from '.';

export const getUser = createAsyncThunk('user/get', (_, thunkAPI) => {
  const { dispatch } = thunkAPI;
  return dispatch(
    GET({
      ignoreError: true,
      path: `/rrm-rc/user`,
      label: `get user`,
    }),
  );
});

export const logoutUser = createAsyncThunk('user/logout', (_, thunkAPI) => {
  const { dispatch } = thunkAPI;
  return dispatch(
    POST({
      path: `/rrm-rc/logout`,
      label: `get user`,
    }),
  );
});
