import React from 'react';
import Tooltip from '@ingka/tooltip';
import ExternalLink from '../../ExternalLink/ExternalLink';
import questionMark from '@ingka/ssr-icon/paths/question-mark';
import SSRIcon from '@ingka/ssr-icon';

const KunskapLink = function KunskapLink() {
  return (
    <Tooltip interactiveCustomTrigger tooltipText="Help" position="bottom">
      <ExternalLink
        asButton
        asIcon
        text=""
        href="https://confluence.build.ingka.ikea.com/x/PCisCg"
        label="Go to Kunskap page"
      >
        <SSRIcon paths={questionMark} />
      </ExternalLink>
    </Tooltip>
  );
};

export default KunskapLink;
