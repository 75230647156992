export const shortcutKeyBindings = {
  nodeMenuCopy: {
    ctrlKey: true,
    key: 'c',
  },
  nodeMenuCut: {
    ctrlKey: true,
    key: 'x',
  },
  openDialogRemoveItem: {
    key: 'Delete',
  },
  pasteAction: {
    ctrlKey: true,
    key: 'v',
  },
  nodeMenuMoveDown: {
    shiftKey: true,
    key: 'ArrowDown',
  },
  nodeMenuMoveUp: {
    shiftKey: true,
    key: 'ArrowUp',
  },
};
