import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class ThemesTreeConfig extends DefaultTreeConfig {
  constructor(props: TreeConfigProps) {
    super(props);

    this.categoryNameEditable = this.editable;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowItemOperations =
      props.isGlobalMarket && props.isEditorForCurrentMarket;
    // RC-3183
    this.allowRanking = props.isGlobalMarket || props.isEditorForCurrentMarket;
    this.moveDisabled = false;
    this.newCategory = this.editable;
  }
}
