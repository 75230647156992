import { camelCase, escapeRegExp, snakeCase, trimEnd, upperFirst } from 'lodash-es';

const leadingNumberRegExp = /(^\d)/;
const nonWordOrWhitespace = /\W|\s/g;
const isoDateTimeStrRegExp =
  /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:(?:\.\d{3,6})?Z|[+-][01]\d:[0-5]\d)$/;

export function capitalizeFirstLetter(str) {
  return upperFirst(str);
}

export function removeTrailingSlashes(str) {
  return trimEnd(str, '/');
}

export function toIdString(str) {
  if (typeof str === 'undefined' || str === '') {
    return `id-${Math.random().toString().substring(2)}`;
  }

  return str.replace(leadingNumberRegExp, 'id-$1').replace(nonWordOrWhitespace, '-');
}

export function toCamelCase(str) {
  return camelCase(str);
}

export function toSnakeCase(str) {
  return snakeCase(str);
}

export function toSpaceCase(str) {
  return toSnakeCase(str).replace('_', ' ');
}

export function randomString(length) {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  const randomValues = Array.from(
    window.crypto.getRandomValues(new Uint8Array(length)),
  );
  return randomValues.map((value) => charset[value % charset.length]).join('');
}

export function toRegExpSafeString(str) {
  return escapeRegExp(str);
}

export function isDateTimeString(str) {
  return typeof str === 'string' && isoDateTimeStrRegExp.test(str);
}

export function isNullOrEmpty(str) {
  return str === null || str === '';
}

export function hasStringValue(str) {
  return typeof str === 'string' && !!str.length;
}
