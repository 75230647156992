import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { togglePane } from '../../store/actions/pane';
import { selectedNodeItemSelector } from '../../store/selectors/tree/node';
import Loading from '../Loading';
import Pane from '../Pane';
import styles from './PropertiesPane.module.scss';
import PropertiesTabs from './PropertiesTabs';
import { TabTypes } from './PropertiesTabs/PropertyTab';
import { TabNodes } from './TabNodes';
import { NodeBaseItem } from '../../entities/Node';
import { analyticsEvent } from '../../store/actions/analytics/analytics';
import type { AppThunkDispatch } from '../../store';
import { ANALYTICS_PROPERTIES_PANE } from '../../store/actions/analytics/analyticsCategories';
import { Cross } from '../Icons/paths';
import Header from '../Pane/Header';
import { PANE_PROPERTIES } from '../../store/constants/panes';

export const PropertiesPane = ({ className }: { className: string }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [activeTab, setActiveTab] = useState<TabTypes>('Details');
  const nodeItem = useSelector(selectedNodeItemSelector) as NodeBaseItem;
  const activeNodeId = useRef<string | null>(null);
  const closePane = useCallback(() => {
    dispatch(togglePane(false));
    dispatch(
      analyticsEvent({
        category: ANALYTICS_PROPERTIES_PANE,
        action: 'Propeties pane closed',
        label: 'Properties pane closed',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    // go back to details pane when changing selected node
    // to avoid having eg parents tab open in a category with out that functionality
    if (activeNodeId.current !== nodeItem?.node.id) {
      activeNodeId.current = nodeItem?.node.id;
      setActiveTab('Details');
    }
    if (!activeNodeId.current) activeNodeId.current = nodeItem?.node.id;
  }, [nodeItem]);

  return (
    <Pane id={PANE_PROPERTIES} className={className}>
      <Header
        className=""
        buttons={[
          <Tooltip
            interactiveCustomTrigger
            tooltipText="Close details"
            key="close-details"
            position="bottom"
          >
            <Button
              key="close-details"
              size="small"
              iconOnly
              onClick={closePane}
              type="tertiary"
              ssrIcon={Cross}
              aria-label="Hide details pain"
            />
            ,
          </Tooltip>,
        ]}
      >
        <div className={cx(styles.headerContent)}>
          <div role="tablist" className="tabs__list">
            <PropertiesTabs
              node={nodeItem?.node}
              type={nodeItem?.node?.type}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </Header>
      <Suspense fallback={<Loading text="Component is loading, please wait..." />}>
        <TabNodes
          activeTab={activeTab}
          key={nodeItem?.node.id ?? 'na'}
          node={nodeItem?.node}
          nodeItem={nodeItem}
          catalogId={nodeItem?.node.catalogId}
        />
      </Suspense>
    </Pane>
  );
};
