import { isNodeExpandable } from '../../../utils/tree/treeNode';
import { NODE_TYPE_CATEGORY, NODE_TYPE_ITEM } from '../../../utils/types/nodeTypes';
import { fetchItem } from '../item/fetchItem';
import { fetchItems } from '../item/fetchItems';

export const fetchChildNodes = (tree, nodeItem) => async (dispatch) => {
  const { node } = nodeItem;
  const hasChildren = !!node?.children?.length;
  if (!node || !isNodeExpandable(node) || hasChildren) {
    return undefined;
  }

  const data = node[node.type];
  if (node.type === NODE_TYPE_CATEGORY && data.childItemCount) {
    return dispatch(fetchItems({ tree, node: nodeItem.node }));
  }
  if (node.type === NODE_TYPE_ITEM) {
    return dispatch(fetchItem({ tree, node: nodeItem.node }));
  }
  return undefined;
};
