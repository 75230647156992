import { ListenerEffectAPI } from '@reduxjs/toolkit';
import { openModal } from '../../actions/modal';
import type { AppDispatch, RootState } from '../../index';
import { matcher } from './helpers';

export const errorListenerOptions = {
  matcher,
  effect: (
    action: { error: string },
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    listenerApi.dispatch(openModal('ErrorMessage', { error: action.error }));
  },
};
