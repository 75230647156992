import { analyticsEvent } from './analytics';

export const externalLinkClicked = (element) => {
  function findLink(el) {
    if (!el) {
      return undefined;
    }
    if (el.tagName === 'A') {
      return el;
    }
    return findLink(el.parentElement);
  }

  const { href, ariaLabel } = findLink(element) || {};

  return analyticsEvent({
    category: 'External Link',
    action: `Clicked on ${ariaLabel}`,
    label: href,
  });
};
