import { createSlice } from '@reduxjs/toolkit';
import { extend, without } from '../../../utils/object';
import {
  createReferenceEqualSelector,
  getIdFromArgument,
} from '../../selectors/utils';
import type { RootState } from '../..';

interface PondState {
  [key: string]: boolean;
}

const pondSlice = createSlice({
  name: 'pond',
  initialState: {} as PondState,
  reducers: {
    removeData(state, action) {
      return without(state, action.payload.key);
    },
    storeData(state, action) {
      return extend(state, { [action.payload.key]: action.payload.data });
    },
  },
});

export const { removeData, storeData } = pondSlice.actions;
export default pondSlice.reducer;

const createPondSelector = (state: RootState) => state.pond;

export const selectPondDataByKey = createReferenceEqualSelector(
  createPondSelector,
  getIdFromArgument,
  (state, key) => state[key],
);

export function isLoadingSelector(state: RootState, key: string) {
  return !!selectPondDataByKey(state, key);
}
