import { arrayify } from '../array';
import { without } from '../object';
import { hasStringValue } from '../string';
import { createUrl, setPraramsInUrl } from './utils';

export function mapURLSearchParamsToObject(urlSearchParams) {
  return Array.from(urlSearchParams.entries()).reduce(addParamKeyValueToObj, {});
}

export function getParamsObj(url) {
  const { searchParams = new URLSearchParams() } = createUrl(url);
  return mapURLSearchParamsToObject(searchParams);
}

export function addParam(url, key, value) {
  const params = addParamKeyValueToObj(getParamsObj(url), [key, value]);
  return setPraramsInUrl(url, params);
}

export function updateParam(url, key, value) {
  const params = replaceParamKeyValueInObj(getParamsObj(url), [key, value]);
  return setPraramsInUrl(url, params);
}

export function deleteParam(url, key) {
  const params = getParamsObj(url);
  return setPraramsInUrl(url, without(params, getPropertyName(key)));
}

export function deleteParamValue(url, key, value) {
  const params = getParamsObj(url);
  const propertyName = getPropertyName(key);
  if (Array.isArray(params[propertyName])) {
    params[propertyName] = params[propertyName].filter((v) => v !== value);
  } else if (
    Object.prototype.hasOwnProperty.call(params, propertyName) &&
    params[propertyName] === value
  ) {
    delete params[propertyName];
  }
  return setPraramsInUrl(url, params);
}

function getPropertyName(key) {
  return key.replace(/\[\]$/, '');
}

function getUnifiedValue(value) {
  return value.indexOf(',') >= 0 ? value.split(',') : value;
}

function addParamKeyValueToObj(obj, [key, value]) {
  if (!hasStringValue(key)) {
    return obj;
  }
  const propertyName = getPropertyName(key);
  const unifiedValue = getUnifiedValue(value);
  return Object.assign(obj, {
    [propertyName]:
      propertyName !== key || Object.prototype.hasOwnProperty.call(obj, propertyName)
        ? [...arrayify(obj[propertyName]), ...arrayify(unifiedValue)]
        : unifiedValue,
  });
}

function replaceParamKeyValueInObj(obj, [key, value]) {
  if (!hasStringValue(key)) {
    return obj;
  }
  const propertyName = getPropertyName(key);
  const unifiedValue = getUnifiedValue(value);
  return Object.assign(obj, { [propertyName]: unifiedValue });
}
