import { HEADER_CONTENT_DISPOSITION, HEADER_CONTENT_TYPE } from './headers';
import {
  MIME_TYPE_JSON,
  MIME_TYPE_STREAM,
  MIME_TYPE_TEXT,
  MIME_TYPE_XLS,
  MIME_TYPE_XLSX,
} from './mime-types';

export function parseContentDispositionHeader(headers) {
  const filenameRegexp = /^filename="(.*)"$/i;
  const dispositionRegexp = /^inline|attachment$/i;
  const contentDisposition = headers.get(HEADER_CONTENT_DISPOSITION) || '';
  return contentDisposition.split(';').reduce(
    (result, part) => {
      const s = part.trim();
      const f = s.replace(filenameRegexp, '$1');
      const d = dispositionRegexp.exec(s);
      if (f) {
        return Object.assign(result, { filename: f });
      }
      if (d) {
        return Object.assign(result, { disposition: d.toLowerCase() });
      }
      return result;
    },
    { filename: '', disposition: '' },
  );
}

export function parseContentTypeHeader(headers) {
  const contentType = headers.get(HEADER_CONTENT_TYPE) || '';
  return {
    contentType,
    isJson: !!contentType && contentType.includes(MIME_TYPE_JSON),
    isBlob:
      !!contentType &&
      (contentType.includes(MIME_TYPE_XLSX) ||
        contentType.includes(MIME_TYPE_XLS) ||
        contentType.includes(MIME_TYPE_STREAM)),
    isText: !!contentType && contentType.includes(MIME_TYPE_TEXT),
  };
}
