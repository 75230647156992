import { lazy } from 'react';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NODE_TYPE_SUGGESTION,
} from '../../utils/types/nodeTypes';
import { TabTypes } from './PropertiesTabs/PropertyTab';
import {
  NodeBase,
  NodeBaseItem,
  NodeCatalog,
  NodeItemSuggestion,
} from '../../entities/Node';

const CategoryNodes = lazy(() => import('./CategoryNodes'));
const ItemNodes = lazy(() => import('./ItemNodes'));
const CatalogProperties = lazy(
  () => import('../CatalogProperties/CatalogProperties'),
);
const MissingCatalog = lazy(
  () => import('../CatalogProperties/MissingItemsCatalog'),
);
const MissingCategory = lazy(
  () => import('../CategoryProperties/MissingItemsCategory'),
);
const NoNodeSelected = lazy(() => import('./NoNodeSelected'));
const Suggestion = lazy(
  () => import('../SuggestionProperties/SuggestionProperties'),
);

export const TabNodes = ({
  node,
  nodeItem,
  activeTab,
  catalogId,
}: {
  node: NodeBase;
  nodeItem: NodeBaseItem;
  activeTab: TabTypes;
  catalogId: string;
}) => {
  if (node?.catalog?.isMissing) {
    return <MissingCatalog />;
  }

  if (node?.category?.isMissing) {
    return <MissingCategory node={node} />;
  }

  switch (node?.type) {
    case NODE_TYPE_CATEGORY:
      return (
        <CategoryNodes
          catalogId={catalogId}
          nodeItem={nodeItem}
          activeTab={activeTab}
          node={node as NodeBase}
        />
      );
    case NODE_TYPE_CATALOG:
      return (
        <CatalogProperties
          catalogId={catalogId}
          nodeItem={nodeItem}
          node={node as NodeCatalog}
        />
      );
    case NODE_TYPE_ITEM:
      return (
        <ItemNodes
          catalogId={catalogId}
          nodeItem={nodeItem}
          activeTab={activeTab}
          node={node as NodeBase}
        />
      );
    case NODE_TYPE_SUGGESTION:
      return <Suggestion node={node as NodeItemSuggestion} nodeItem={nodeItem} />;
    default:
      return <NoNodeSelected />;
  }
};
