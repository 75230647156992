import { isAnyOf, ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit';
import {
  getCatalog,
  getAll,
  activateDraft,
  deleteCatalog,
} from '../../actions/api/catalogs';
import { fetchCatalogs } from '../../actions/catalog/fetchCatalogs';
import { catalogReceived, catalogsReceived, removeCatalogs } from '.';
import type { AppDispatch, RootState } from '../../index';
import { masterCatalogIdSelector } from '../../selectors/catalog/ids';
import { Catalog } from '../../../entities/Catalog';

export const activateDraftCatalogListenerOptions = {
  actionCreator: activateDraft.fulfilled,
  effect: async (
    action: { meta: { arg: string } },
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    const { dispatch, getState } = listenerApi;
    const {
      meta: { arg: catalogId },
    } = action;
    const prevLiveCatalogId = masterCatalogIdSelector(getState());
    dispatch(removeCatalogs(prevLiveCatalogId || '', catalogId));
    return dispatch(fetchCatalogs());
  },
};

export const catalogDeletedListenerOptions = {
  actionCreator: deleteCatalog.fulfilled,
  effect: async (
    action: { meta: { arg: string } },
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    const { dispatch } = listenerApi;
    const {
      meta: { arg: catalogId },
    } = action;
    dispatch(removeCatalogs(catalogId));
    return dispatch(fetchCatalogs());
  },
};

export const catalogReceivedListenerOptions = {
  matcher: isAnyOf(getCatalog.fulfilled, getAll.fulfilled),
  effect: (
    action: unknown,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    // fix for type inference error in startAppListening
    const tempAction = action as PayloadAction<{ catalogs: Catalog[] }>;

    const { dispatch } = listenerApi;
    if (tempAction.payload.catalogs) {
      dispatch(catalogsReceived(tempAction.payload.catalogs));
    } else {
      dispatch(catalogReceived(tempAction.payload));
    }
  },
};
