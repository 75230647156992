import { createAsyncThunk } from '@reduxjs/toolkit';
import { parse } from '../../utils/url/parse';
import { PANE_SOURCETREE, PANE_TARGETTREE } from '../constants/panes';
import { selectHasErrors } from '../slices/error';
import { selectAvailableMarkets } from '../slices/market';
import { selectActivePane } from '../slices/pane';
import { appStart, appStartFailed, appStartSuccess } from './app';
import { fetchCatalogs } from './catalog/fetchCatalogs';
import { selectSourceTree } from './data/selectSourceTree';
import { setMarket } from './intl';
import { openMarketSelector } from './modal/openMarketSelector';
import { prePopulateTreeState } from './nodes/prePopulateTreeState';
import { toggleActivePane, togglePane } from './pane';
import type { AppThunkDispatch, RootState } from '..';
import { selectHasRetailUnitRole } from '../selectors/roles';
import { getAllMarkets } from './api/market';

export const bootstrapApplication = createAsyncThunk<
  unknown,
  string,
  { dispatch: AppThunkDispatch; state: RootState }
>('bootstrap', async (redirectUrl, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;

  let markets: {
    retailUnit: string;
    countryName: string;
    languageCodes: string[];
  }[] = [];

  try {
    markets = await dispatch(getAllMarkets()).unwrap();
  } catch (error) {
    return dispatch(appStartFailed());
  }

  const {
    activepane,
    details,
    languageCode,
    retailUnit,
    selectedNodeId,
    sourceTree,
    sourceTreeState,
    targetTree,
    targetTreeState,
  } = parse(redirectUrl);
  const state = getState();

  dispatch(appStart());

  if (!validateMarket(markets, retailUnit, languageCode)) {
    const market = getMarketFromUserRoles(state);
    if (!market) {
      await dispatch(openMarketSelector());
    } else {
      await dispatch(
        setMarket({
          retailUnit: market.retailUnit,
          languageCode: market.languageCode,
        }),
      );
      await dispatch(fetchCatalogs());
    }
  } else {
    await dispatch(
      prePopulateTreeState({ selectedNodeId, sourceTreeState, targetTreeState }),
    );

    await dispatch(setMarket({ retailUnit, languageCode }));
    await dispatch(fetchCatalogs());

    if (activepane && selectActivePane(getState()) !== activepane) {
      dispatch(toggleActivePane(activepane));
    }
    if (details) {
      dispatch(togglePane(details === 'true'));
    }
    if (sourceTree) {
      dispatch(selectSourceTree(PANE_SOURCETREE, sourceTree));
    }
    if (targetTree) {
      dispatch(selectSourceTree(PANE_TARGETTREE, targetTree));
    }
  }

  if (selectHasErrors(getState())) {
    return dispatch(appStartFailed());
  }

  return dispatch(appStartSuccess());
});

function validateMarket(
  markets: {
    retailUnit: string;
    countryName: string;
    languageCodes: string[];
  }[],
  retailUnit?: string,
  languageCode?: string,
) {
  return !!(
    languageCode &&
    retailUnit &&
    markets.find(
      (m) => m.retailUnit === retailUnit && m.languageCodes.includes(languageCode),
    )
  );
}

function getMarketFromUserRoles(state: RootState) {
  const market = selectAvailableMarkets(state).find((m) =>
    selectHasRetailUnitRole(state, m.retailUnit),
  );
  if (market?.languageCodes?.length && market?.retailUnit) {
    return {
      languageCode: market.languageCodes?.[0],
      retailUnit: market.retailUnit,
    };
  }
  return null;
}
