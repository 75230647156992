import React, { useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { openExport } from '../../../store/actions/modal/openExport';
import { ArrowDownToBase } from '../../Icons/paths';

import styles from './ExportButton.module.scss';
import { selectHasOngoingExports } from '../../../store/slices/ongoingrequests';
import { AppThunkDispatch } from '../../../store';

const ExportButton = function ExportButton() {
  const dispatch = useDispatch<AppThunkDispatch>();
  const isExporting = useSelector(selectHasOngoingExports);
  const openExportDialog = useCallback(() => dispatch(openExport()), [dispatch]);

  return (
    <Tooltip interactiveCustomTrigger tooltipText="Export" position="bottom">
      <Button
        className={cx({ [styles.exporting]: isExporting })}
        disabled={isExporting}
        iconOnly
        size="small"
        aria-label="Open export dialog"
        onClick={openExportDialog}
        ssrIcon={ArrowDownToBase}
        type="tertiary"
      />
    </Tooltip>
  );
};

export default ExportButton;
