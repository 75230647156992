import { destructId } from '../../../utils/tree/treeNode';
import { moveCategory } from '../api/categories';

const findPlaceAfterId = (index, nodes) => nodes?.[index - 1]?.categoryId;

export const moveCategoryNode =
  ({ node, nextChildIndex, nextParentNode, prevParentId }) =>
  async (dispatch) => {
    const payload = [
      {
        categoryId: node.categoryId,
        oldParentCategoryId: Number(destructId(prevParentId).categoryId),
        newParentCategoryId: Number(destructId(nextParentNode.id).categoryId),
        placeAfterCategoryId: Number(
          findPlaceAfterId(nextChildIndex, nextParentNode.children),
        ),
      },
    ];
    await dispatch(
      moveCategory({
        catalogId: node.catalogId,
        payload,
      }),
    );
  };
