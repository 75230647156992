import { shallowEqual, useSelector } from 'react-redux';
import { deepEqual } from '../utils/validation/equality';

export function useDeepEqualSelector(selector) {
  return useSelector(selector, deepEqual);
}

export function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}
