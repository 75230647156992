export function createUrl(url, base = window.location.origin) {
  try {
    return new URL(url, base !== null ? base : undefined);
  } catch {
    return {};
  }
}

export function setPraramsInUrl(url, paramsObj) {
  const { origin, pathname, hash } = createUrl(url);
  const params = new URLSearchParams(paramsObj).toString();
  return hrefFromUrl({
    origin,
    pathname,
    search: params,
    hash,
  });
}

export function hrefFromUrl({ origin, pathname, search = '', hash = '' }) {
  return `${origin}${pathname}${search.replace(/^(\??)(.+)$/, '?$2')}${hash.replace(
    /^(#?)(.+)/,
    '#$2',
  )}`;
}

export function objToQueryString(params) {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}
