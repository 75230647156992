import { arrayify, orderBy } from '../array';
import { isDateTimeString, toIdString } from '../string';
import { toSuggestedCategoryNode } from '../tree/nodeMappers/toCategoryNodes';
import { toDateGroupNode } from '../tree/nodeMappers/toDateGroupNode';
import { toUncategorizedItemNodes } from '../tree/nodeMappers/toItemNodes';
import { getTreeFromFlatData } from '../tree/tree-data-utils';
import { createId } from '../tree/treeNode';
import { NODE_TYPE_DATE_GROUP } from '../types/nodeTypes';

export function createUncategorizedNodes({
  catalogId,
  items,
  showPredictions,
  expandedIds,
}) {
  const dateGroups = createDateGroups(items);
  const suggestedCategories = items
    .flatMap((m) => m.suggestedCategories)
    .filter((f) => !!f);

  const categories = new Map(
    suggestedCategories.map((m) => [m.externalCategoryId, m]),
  );
  const groups = Object.entries(dateGroups)
    .sort()
    .reduce((mapped, [date, uncategorizedItems]) => {
      const predictions = getPredictions(showPredictions, uncategorizedItems);
      const aiGroups = createAiGroups(predictions, categories);
      const ungrouped = getUngroupedItems(predictions);

      mapped[date] = mapped[date] ? mapped[date] : [];

      const mappedGroups = aiGroups.map((g) => ({
        categories: g.categories,
        childItemCount: g.aiGroup.length,
        childItems: g.aiGroup,
        suggestionId: toIdString(g.key),
      }));
      mapped[date] = mapped[date].concat(mappedGroups);
      mapped[date] = mapped[date].concat(ungrouped);

      return mapped;
    }, {});

  const keepExpandedOpen = Array.isArray(expandedIds) && !!expandedIds.length;
  const flatData = Object.entries(groups).reduce(
    (data, [date, uncategorizedItems], index) => {
      const aiGroups = showPredictions
        ? uncategorizedItems.filter((i) => i.suggestionId)
        : [];

      const notGrouped = showPredictions
        ? uncategorizedItems.filter((i) => !i.suggestionId)
        : uncategorizedItems;

      const nodeId = createId({
        type: NODE_TYPE_DATE_GROUP,
        contentId: `${isDateTimeString(date) ? Date.parse(date) : date}`,
      });

      const dateGroup = toDateGroupNode({
        catalogId,
        childItemCount:
          notGrouped.length +
          aiGroups.reduce((sum, suggestion) => sum + suggestion.childItemCount, 0),
        date,
        expanded: keepExpandedOpen ? expandedIds.includes(nodeId) : index <= 1,
      });

      data.push(
        ...aiGroups.reduce((nodes, suggestion) => {
          const node = toSuggestedCategoryNode(dateGroup.id, suggestion);
          return [
            ...nodes,
            node,
            ...toUncategorizedItemNodes(node.id, 2, suggestion.childItems),
          ];
        }, []),
        dateGroup,
        ...toUncategorizedItemNodes(dateGroup.id, 1, notGrouped),
      );
      return data;
    },
    [],
  );

  const uncategorizedNodes = getTreeFromFlatData({ flatData });

  return uncategorizedNodes;
}

function createAiGroups(predictions, categories) {
  return orderBy(
    Array.from(predictions.entries())
      .filter(([key]) => key !== '')
      .map(([key, aiGroup]) => ({
        key,
        aiGroup,
        categories: orderBy(
          key.split(',').map((id) => {
            const cat = categories.get(id);
            if (!cat) {
              return {
                externalCategoryId: id,
                globalCategoryName: '--- Invalid prediction ---',
              };
            }
            return cat;
          }),
          ['globalCategoryName', 'externalCategoryId'],
        ),
      })),
    [(i) => i.categories[0].globalCategoryName],
    ['asc'],
  );
}

const getGroupName = (item) => {
  const { salesStartDate, productName } = item;

  if (!salesStartDate) return 'no-date';
  if (!productName) return 'no-product-name';

  return salesStartDate;
};

function createDateGroups(items) {
  return arrayify(items).reduce((mapped, item) => {
    const group = getGroupName(item);
    const subItems = mapped[group] || [];
    subItems.push(item);
    return Object.assign(mapped, { [group]: subItems });
  }, {});
}

function getPredictions(showPredictions, items) {
  return items.reduce((groups, item) => {
    const key =
      showPredictions && item?.suggestedCategories?.length > 0
        ? [...arrayify(item?.suggestedCategories?.map((m) => m.externalCategoryId))]
            .sort()
            .join(',')
        : '';
    const content = groups.get(key) || [];
    groups.set(key, [...content, item]);
    return groups;
  }, new Map());
}

function getUngroupedItems(predictions) {
  return Array.from(predictions.entries())
    .filter(([key]) => key === '')
    .map(([, value]) => value)
    .flat();
}
