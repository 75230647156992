import { PANE_SOURCETREE, PANE_TARGETTREE } from '../../constants/panes';
import { selectSourcePane, selectTargetPane } from '../../slices/pane';
import {
  createShallowEqualResultSelector,
  createStructuredSelector,
} from '../utils';

export const selectPanesCatalog = createStructuredSelector({
  [PANE_SOURCETREE]: selectSourcePane,
  [PANE_TARGETTREE]: selectTargetPane,
});

export const selectPanes = createShallowEqualResultSelector(
  selectPanesCatalog,
  (panesCatalog) =>
    [
      { catalog: panesCatalog[PANE_SOURCETREE], pane: PANE_SOURCETREE },
      { catalog: panesCatalog[PANE_TARGETTREE], pane: PANE_TARGETTREE },
    ].filter((p) => !!p.catalog),
);
