import React from 'react';
import PropTypes from 'prop-types';
import { CATALOG_STATUS_ACTIVE } from '../../utils/statuses/catalogStatus';
import DataPlate from '../DataPlate/DataPlate';
import styles from './CatalogStatus.module.scss';

function CatalogStatus({ catalog }) {
  return (
    !!catalog?.catalogStatus &&
    catalog.catalogStatus !== CATALOG_STATUS_ACTIVE && (
      <DataPlate className={styles.status}>{catalog.catalogStatus}</DataPlate>
    )
  );
}

CatalogStatus.propTypes = {
  catalog: PropTypes.shape({
    catalogStatus: PropTypes.string,
  }),
};

export default CatalogStatus;
