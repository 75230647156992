export const FILTERS = {
  NOT_VALID: 'NOT_VALID',
  VALID: 'VALID',
};

export const INTERVALS = {
  CUSTOM: 0,
  LAST_DAY: 1,
  LAST_WEEK: 14,
  LAST_MONTH: 30,
};

export const SORT_ORDER = {
  DESC: 'DESC',
  ASC: 'ASC',
};
