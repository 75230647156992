import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_DATE_GROUP,
  NODE_TYPE_ITEM,
  NODE_TYPE_MULTI_SELECT,
  NODE_TYPE_REASON_GROUP,
  NODE_TYPE_SMALL_BANNER_NODE,
  NODE_TYPE_SUGGESTION,
} from '../../../utils/types/nodeTypes';
import Catalog from './Catalog';
import Category from './Category';
import Group from './Group';
import Item from './Item';
import MultiSelect from './MultiSelect';
import SmallBanner from './SmallBanner';
import Title from './Title';
import { NodeBase } from '../../../entities/Node';

const DefaultRenderer = ({ node: { title } }: { node: NodeBase }) => (
  <Title title={title} />
);

const NodeContent = (node: NodeBase) => {
  const { type } = node;
  switch (type) {
    case NODE_TYPE_CATEGORY:
      return <Category node={node} />;
    case NODE_TYPE_CATALOG:
      return <Catalog node={node} />;
    case NODE_TYPE_ITEM:
      return <Item node={node} />;
    case NODE_TYPE_MULTI_SELECT:
      return <MultiSelect node={node} />;
    case NODE_TYPE_SMALL_BANNER_NODE:
      return <SmallBanner node={node as unknown as { bannerId: string }} />;
    case NODE_TYPE_DATE_GROUP:
    case NODE_TYPE_REASON_GROUP:
    case NODE_TYPE_SUGGESTION:
      return <Group node={node} />;
    default:
      return <DefaultRenderer node={node} />;
  }
};

export default NodeContent;
