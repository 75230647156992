import { isDateTimeString, isNullOrEmpty } from '../string';
import { isEqualWith } from '../validation/equality';
import { within } from '.';

function customComparer(first, second) {
  if (isDateTimeString(first) && isDateTimeString(second)) {
    return Date.parse(first) === Date.parse(second);
  }
  if (isNullOrEmpty(first) && isNullOrEmpty(second)) {
    return true;
  }
  return undefined;
}

export function isEqualByProps(first, second, ...props) {
  if (!first || !second) {
    return false;
  }
  if (props.length) {
    const f = within(first, ...props);
    const s = within(second, ...props);
    return isEqualWith(f, s, customComparer);
  }
  return isEqualWith(first, second, customComparer);
}
