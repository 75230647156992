import PropTypes from 'prop-types';
import { SHAPE_TOOLTIP } from './shapes/tooltip';
import * as modalTypes from './types/modalTypes';

export const PROPTYPE_CHILDREN_TYPE = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);
export const PROPTYPE_MODAL_TYPE = PropTypes.oneOf(Object.values(modalTypes));
export const PROPTYPE_REF = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.any,
  }),
]);

export const PROPTYPE_TOOLTIP = PropTypes.oneOfType([
  PropTypes.string,
  SHAPE_TOOLTIP,
]);
