import { pushHistoryFromState, replaceHistoryFromState } from '../../utils/history';
import { selectAppIsStarted } from '../slices/app';

export const navigation = (store) => (next) => async (action) => {
  // DO NOT ACT
  if (!selectAppIsStarted(store.getState())) {
    return next(action);
  }

  // RUN ACTION
  const result = await next(action);

  // ACT AFTER ACTION
  switch (action.type) {
    case 'catalog/catalogsReceived':
    case 'history/replaceState':
    case 'pane/togglePane':
    case 'pane/toggleActivePane':
    case 'tree/expandNode':
    case 'tree/selectNodes': {
      const reason = action.payload?.reason;
      if (window.location.pathname.includes('reports')) {
        break;
      }
      replaceHistoryFromState(store.getState(), reason ?? 'set market state');
      break;
    }
    case 'pane/setSource': {
      pushHistoryFromState(store.getState(), 'market changed');
      break;
    }
    default:
      break;
  }

  return result;
};
