import {
  findFirstNode,
  findNodes,
  insertNodeAtChildIndex,
  removeNodeFromTree,
} from '../../../components/Tree/helpers';
import { getCyclicIndex } from '../../../utils/array';
import { destructId, getPathById } from '../../../utils/tree/treeNode';

export function moveNodeInTreeData({ direction, node, treeData: initialTreeData }) {
  // Find the parent category
  const parent = findFirstNode({
    treeData: initialTreeData,
    searchMethod: (n) => n.node.id === node.parentId,
    ignoreCollapsed: false,
  });
  // Find all the sibling parents in the tree
  const allParents = findNodes({
    treeData: initialTreeData,
    searchMethod: (ni) => ni.node?.categoryId === parent.node.category?.categoryId,
    ignoreCollapsed: false,
  });
  const nodes = [];
  // Get all the nodes for the categories that should be moved within those parents
  if (allParents.matches?.length > 1) {
    allParents.matches.forEach(({ node: no }) => {
      const match = no.children.find(
        (n) => n.category?.categoryId === node.category?.categoryId,
      );
      nodes.push({ node: match, path: getPathById(match.id), parent: no });
    });
  } else {
    nodes.push({ node, path: getPathById(node.id), parent: parent.node });
  }

  let treeData;
  let currentIndex;
  nodes.forEach(({ node: n, path: nodePath, parent: currentParentNode }, i) => {
    currentIndex = currentParentNode.children.findIndex((no) => no.id === n.id);

    const moveToIndex = direction === '+' ? currentIndex + 1 : currentIndex - 1;
    const childIndex = getCyclicIndex(currentParentNode.children, moveToIndex);

    treeData = removeNodeFromTree({
      path: nodePath,
      treeData: i === 0 ? initialTreeData : treeData,
    }).treeData;
    treeData = insertNodeAtChildIndex({
      childIndex,
      newNode: n,
      treeData,
    }).treeData;
  });
  const newParentNode = findFirstNode({
    treeData,
    searchMethod: (ni) => ni.node.id === node.parentId,
    ignoreCollapsed: true,
  }).node;

  const oldParentId = destructId(node.parentId).categoryId;
  const newParentId = destructId(newParentNode.id).categoryId;
  const newIndex = newParentNode.children.findIndex((c) => c.id === node.id);
  const placedAfterId =
    newIndex < 1 ? undefined : newParentNode.children[newIndex - 1].categoryId;

  return {
    newParentId,
    oldParentId,
    treeData,
    placedAfterId,
    oldPosition: currentIndex,
    newPosition: newIndex,
  };
}
