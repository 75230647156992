/* eslint-disable no-console */
import { ENV_PROD, LOG_LEVEL } from './env';
import { noOperation } from './function';

const LOG_LEVEL_MODEST = 'MODEST';
const LOG_LEVEL_VERBOSE = 'VERBOSE';

const output = {
  LEVEL: LOG_LEVEL,
  clear: ENV_PROD ? console.clear : noOperation,
  error: ENV_PROD ? console.error : noOperation,
  greet: ENV_PROD ? console.log : noOperation,
  handles: (func) => func !== noOperation,
  log: noOperation,
  warn: noOperation,
};

if (output.LEVEL === LOG_LEVEL_VERBOSE) {
  output.clear = console.clear;
  output.error = console.error;
  output.greet = console.log;
  output.log = console.log;
  output.warn = console.warn;
} else if (output.LEVEL === LOG_LEVEL_MODEST) {
  output.clear = console.clear;
  output.error = console.error;
  output.greet = console.log;
  output.warn = console.warn;
}

const { clear, error, greet, handles, log, warn } = output;

export { clear, error, greet, handles, log, warn };
