import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../..';

const invalidCategoryImagesSlice = createSlice({
  name: 'invalidCategoryImages',
  initialState: { categoryIds: [], fetching: false } as {
    categoryIds: string[];
    fetching: boolean;
  },
  reducers: {
    setFetchingInvalidCategoryImages(
      state,
      action: PayloadAction<{ fetching: boolean }>,
    ) {
      const { fetching } = action.payload;
      return {
        ...state,
        fetching,
      };
    },
    removeCategoryFromInvalid(state, action: PayloadAction<string>) {
      const categoryId = action.payload;
      const categoryIds = state.categoryIds.filter((f) => f !== categoryId);
      return {
        ...state,
        categoryIds,
      };
    },
    setInvalidCategoryImages(
      _,
      action: PayloadAction<{ categoryIds: string[]; retailUnit: string }>,
    ) {
      const { categoryIds } = action.payload;
      return {
        categoryIds,
        fetching: false,
      };
    },
  },
});

export const {
  setInvalidCategoryImages,
  setFetchingInvalidCategoryImages,
  removeCategoryFromInvalid,
} = invalidCategoryImagesSlice.actions;

export const selectInvalidImages = (state: RootState) => state.invalidCategoryImages;

export default invalidCategoryImagesSlice.reducer;
