import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { toItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import { getItems } from '../api/items';
import { addNodes } from '../tree/addNodes';
import { NodeBase } from '../../../entities/Node';

export const fetchItems = createAsyncThunk(
  'tree/fetchItems',
  async (
    { tree, node, pageNo = 0 }: { tree: string; node: NodeBase; pageNo: number },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;
    const itemsPaginated = await dispatch(getItems({ ...node, pageNo })).unwrap();

    const items = Array.isArray(itemsPaginated.items)
      ? [...itemsPaginated.items]
      : ReadOnlyArray.Empty;

    const nodesToAdd = toItemNodes({
      catalogId: node.catalogId,
      items,
      level: node.level + 1,
      parentId: node.id,
    });
    dispatch(addNodes(tree, nodesToAdd, { addAsFirstChild: true }));

    return itemsPaginated.paging;
  },
);
