class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized or token has expired, please reload the page');

    this.name = 'UnauthorizedError';

    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export default UnauthorizedError;
