import { CatalogCategoriesFromDTO } from '../../../store/mappers/treeDto';
import { NODE_TYPE_CATALOG } from '../../types/nodeTypes';
import { createId, getCatalogTitle } from '../treeNode';
import { CategoryNode } from './toCategoryNodes';

type ToCatalogNodeInput = {
  catalog: CatalogCategoriesFromDTO['catalog'];
  expanded: boolean;
  languageCode: string;
};

export function toCatalogNode({
  catalog,
  expanded,
  languageCode,
}: ToCatalogNodeInput) {
  const { catalogId, catalogType } = catalog;
  const id = createId({
    parentId: undefined,
    type: NODE_TYPE_CATALOG,
    contentId: catalogId,
  });

  return {
    id,
    key: id,
    parentId: 0,
    title: getCatalogTitle(catalog, languageCode),
    type: NODE_TYPE_CATALOG,
    catalogId,
    catalog,
    catalogType,
    expanded,
    level: 0,
    role: 'treeitem',
    allowChildItems: true,
    children: [] as CategoryNode[],
  };
}
