import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeTreeData } from '../../../components/Tree/helpers/makeTreeData';
import { MISSING_ITEMS_CATALOG } from '../../../utils/catalog/missingitems';
import { updateData } from '.';
import { NOT_VALID_FOR_STRUCURE_CATALOG } from '../../../utils/catalog/notvalidforstructure';
import { UNCATEGORIZED_CATALOG } from '../../../utils/catalog/uncategorized';
import { LIST_EXISTS, LIST_IMPORT } from '../../constants/importitems';
import { selectTreeDataByCatalogId } from '../../selectors/tree';
import { getTree } from '../api/catalogs';
import { replaceHistoryState } from '../history';
import { updateCategoryStatusFromTreeData } from '../indicator';
import { fetchMissingItems } from '../item/fetchMissingItems';
import { fetchNotValidForStructureItems } from '../item/fetchNotValidForStructureItems';
import { fetchUncategorizedItems } from '../item/fetchUncategorizedItems';
import type { AppDispatch, RootState } from '../..';
import { Catalog } from '../../../entities/Catalog';
import { selectIsFetchingTree, setIsFetching } from '../../slices/tree';
import { fetchInvalidCategoryImages } from './fetchInvalidCategoryImages';
import { updateMatchingNodesInTree } from '../../../components/Tree/helpers';
import { NodeBaseItem } from '../../../entities/Node';

export const fetchTree = createAsyncThunk<
  [],
  { tree: string; catalog: Catalog; isGlobalMarket: boolean },
  { dispatch: AppDispatch; state: RootState }
>('tree/fetchTree', async ({ tree, catalog, isGlobalMarket }, thunkAPI) => {
  const { catalogId, catalogType, catalogName } = catalog;
  if (
    !catalog.catalogId ||
    catalog.catalogId === LIST_IMPORT ||
    catalogId === LIST_EXISTS
  ) {
    return Promise.resolve();
  }
  const { dispatch, getState } = thunkAPI;

  const isFetchingTree = selectIsFetchingTree(getState(), catalog.catalogId);
  if (isFetchingTree === undefined) {
    // catalog not created yet, create empty shell
    // @ts-expect-error updateDate not typed correctly
    dispatch(updateData(tree, {}));
  }

  if (isFetchingTree) return Promise.resolve();

  try {
    dispatch(setIsFetching(tree, true));

    if (catalogId === MISSING_ITEMS_CATALOG.catalogId) {
      return await dispatch(fetchMissingItems(tree));
    }
    if (catalogId === NOT_VALID_FOR_STRUCURE_CATALOG.catalogId) {
      return await dispatch(fetchNotValidForStructureItems(tree, catalogId));
    }
    if (catalogId === UNCATEGORIZED_CATALOG.catalogId) {
      return await dispatch(fetchUncategorizedItems(tree, catalogId));
    }
    const currentTreeData = selectTreeDataByCatalogId(getState(), catalogId);
    if (currentTreeData && currentTreeData.length) {
      return await Promise.resolve();
    }

    const {
      catalog: treeCatalog,
      categories,
      languageCode,
    } = await dispatch(getTree(catalogId)).unwrap();

    const treeData = makeTreeData(treeCatalog, categories, languageCode, []);

    // @ts-expect-error reducer not correctly typed
    await dispatch(updateData(tree, treeData));

    dispatch(replaceHistoryState('Catalogue loaded'));

    const invalidCategoryImages = await dispatch(
      fetchInvalidCategoryImages({
        catalogType,
        catalogId,
        catalogName,
        isGlobalMarket,
      }),
    ).unwrap();

    const withInvalidImages = updateMatchingNodesInTree(
      (ni: NodeBaseItem) => invalidCategoryImages.includes(ni.node.categoryId),
      selectTreeDataByCatalogId(getState(), catalogId),
      { invalidCategoryImage: true },
    );
    dispatch(updateCategoryStatusFromTreeData(withInvalidImages, catalogId));
    await dispatch(updateData(tree, withInvalidImages));
  } finally {
    dispatch(setIsFetching(tree, false));
  }

  return Promise.resolve();
});
