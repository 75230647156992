import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { indicatorsSelector } from '../store/selectors/category/ui';
import { arrayify } from '../utils/array';
import { STATE_NEW, STATE_UNPUBLISHED } from '../utils/category/states';

export function usePublishIndicators(node) {
  const publishState = node?.category?.publishState;
  const hideEndDate = node?.category?.hideEndDate;
  const hideStartDate = node?.category?.hideStartDate;
  const props = useMemo(() => {
    const isNew = node?.category?.newCategory;
    const state = isNew && publishState === STATE_UNPUBLISHED ? null : publishState;
    const indicators = [...arrayify(state)];
    if (isNew) {
      indicators.unshift(STATE_NEW);
    }

    return {
      badges: indicators,
      hideEndDate,
      hideStartDate,
    };
  }, [hideEndDate, hideStartDate, node, publishState]);

  return props;
}

export function useTranslateIndicators(node) {
  const props = useMemo(() => {
    const indicators = arrayify(node?.category?.translateState);
    return {
      badges: indicators,
    };
  }, [node]);

  return props;
}

export function useAllIndicators(catalogId, content) {
  return useSelector((state) => indicatorsSelector(state, catalogId, content));
}
