import cx from 'classnames';
import PropTypes from 'prop-types';
import { PROPTYPE_CHILDREN_TYPE } from '../../utils/proptypes';
import styles from './Pane.module.scss';

const Pane = function Pane({ children, className, collapsed, id }) {
  return (
    <div
      id={id}
      className={cx(
        styles.pane,
        {
          [styles.collapsed]: collapsed,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
Pane.propTypes = {
  id: PropTypes.string,
  children: PROPTYPE_CHILDREN_TYPE,
  className: PropTypes.string,
  collapsed: PropTypes.bool,
};

export default Pane;
