import { findNodes } from '../../../components/Tree/helpers';
import { getCatalogId } from '../../../utils/tree/ids';
import { toItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { searchSetConditionByText, searchUpdate } from '.';
import { getCategoryRelationsItem } from '../api/items';
import { createLoadingActionsFromKey } from '../category/loading';
import { addNodes } from './addNodes';

export const searchItemAndAddToCatalog =
  (tree, itemNo) => async (dispatch, getState) => {
    const catalogId = getCatalogId(tree);
    const { startLoading, stopLoading } = createLoadingActionsFromKey(itemNo);
    dispatch(startLoading());
    const result = await dispatch(
      getCategoryRelationsItem({ catalogId, itemNo }),
    ).unwrap();
    const {
      catalogIdsList,
      structureReasonText,
      categoryWithCountrySpecificItemList,
      item,
    } = result;

    const itemMappedToCategory =
      categoryWithCountrySpecificItemList?.reduce((acc, curr) => {
        acc[curr.categoryId] = {
          ...item,
          itemCountrySpecificList: curr.itemCountrySpecificList,
        };
        return acc;
      }, {}) || {};

    const currentTreeData = selectTreeDataByTreeId(getState(), tree);
    const itemNodesToAdd = findNodes({
      treeData: currentTreeData,
      searchMethod: (ni) =>
        Object.keys(itemMappedToCategory).includes(ni?.node?.category?.categoryId),
    }).matches.reduce(
      (nodes, parentNodeItem) => [
        ...nodes,
        ...toItemNodes({
          catalogId,
          isLeaf:
            itemMappedToCategory[parentNodeItem.node.categoryId]
              .itemCountrySpecificList.length === 0,
          items: [itemMappedToCategory[parentNodeItem.node.categoryId]],
          level: parentNodeItem.node.level + 1,
          parentId: parentNodeItem.node.id,
        }),
      ],
      [],
    );
    dispatch(stopLoading());
    if (!itemNodesToAdd.length) {
      return dispatch(searchUpdate(tree, catalogIdsList, item, structureReasonText));
    }
    await dispatch(
      addNodes(tree, itemNodesToAdd, { addAsFirstChild: true, expandParent: true }),
    );
    return dispatch(searchUpdate(tree));
  };

export const searchNonSearchableCatalogs =
  (tree, itemNo, type) => async (dispatch) => {
    const { startLoading, stopLoading } = createLoadingActionsFromKey(itemNo);
    dispatch(startLoading());

    const result = await dispatch(getCategoryRelationsItem({ itemNo })).unwrap();

    if (result.item && result[type]) {
      // found country specific item
      dispatch(searchSetConditionByText(tree, result.item.itemNo));
    } else if (result.item && !result[type]) {
      // found item in another catalog
      dispatch(
        searchUpdate(
          tree,
          result.catalogIdsList,
          result.item,
          result.structureReasonText,
        ),
      );
    }

    dispatch(stopLoading());
  };
