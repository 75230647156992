import type { RootState } from '../../store';
import { log } from '../log';
import { makeUrlFromState } from '../url/fromState';

export function historyPayloadSelector(state: RootState) {
  const { catalog, category, indicator, intl, item, pane, tree } = state;

  return JSON.parse(
    JSON.stringify({
      catalog,
      category,
      intl,
      indicator,
      item,
      pane,
      tree,
    }),
  );
}

export function pushReportsUrl({ marketCode }: { marketCode: string }) {
  const reportPage = window.location.pathname.split('/').slice(-1);
  const href = `/${marketCode}/reports/${reportPage}${window.location.search}`;
  window.history.pushState('', '', href);
}

export function replaceHistory(state: RootState, reason: string) {
  const payload = historyPayloadSelector(state);
  const { href } = window.location;
  // @ts-expect-error log not typed
  log(`replaceHistory ${reason}`, payload, href);
  window.history.replaceState(payload, reason, href);
}

export function pushHistoryFromState(state: RootState, reason: string) {
  const payload = historyPayloadSelector(state);
  const href = makeUrlFromState(state);
  // @ts-expect-error log not typed
  log(`pushFromState ${reason}`, payload, href);
  window.history.pushState(payload, reason, href);
}

export function replaceHistoryFromState(state: RootState, reason: string) {
  const payload = historyPayloadSelector(state);
  const href = makeUrlFromState(state);
  // @ts-expect-error log not typed
  log(`replaceFromState ${reason}`, payload, href);
  window.history.replaceState(payload, reason, href);
}
