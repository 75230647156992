import { CatalogCategoriesFromDTO } from '../../../store/mappers/treeDto';
import { CATALOG_TYPE_UNCATEGORIZED } from '../../types/catalogTypes';
import { NODE_TYPE_CATEGORY, NODE_TYPE_SUGGESTION } from '../../types/nodeTypes';
import { createId, getCategoryTitle } from '../treeNode';
import type { ItemNode } from './toItemNodes';

export type CategoryNode = {
  id: string;
  key: string;
  title: string;
  catalogId: string;
  categoryId: string;
  category: CatalogCategoriesFromDTO['categories'][string];
  children: Array<CategoryNode | ItemNode>;
  isLeaf: boolean;
  expanded: boolean;
  type: string;
  level: number;
  invalidCategoryImage: boolean;
  allowChildItems: boolean;
};

export function toCategoryNodes({
  catalogId,
  categories,
  level,
  languageCode,
  parentId,
  invalidCategoryImages,
}: {
  catalogId: string;
  categories?: CatalogCategoriesFromDTO['categories'][string][];
  level: number;
  languageCode: string;
  parentId: string;
  invalidCategoryImages: string[];
}) {
  if (!categories) return [];

  return categories.map((category) => {
    const id = createId({
      parentId,
      type: NODE_TYPE_CATEGORY,
      contentId: category.categoryId,
    });

    const invalidCategoryImage =
      invalidCategoryImages && invalidCategoryImages.includes(category.categoryId);

    return {
      id,
      key: id,
      parentId,
      title: getCategoryTitle(category, languageCode),
      catalogId,
      categoryId: category.categoryId,
      category,
      children: [],
      isLeaf: false,
      expanded: false,
      type: NODE_TYPE_CATEGORY,
      level,
      role: 'treeitem',
      invalidCategoryImage,
      allowChildItems: true,
    };
  });
}

export function toSuggestedCategoryNode(
  parentId: string,
  suggestion: {
    suggestionId: string;
    childItems: string[];
    categories: CatalogCategoriesFromDTO['categories'][string][];
  },
) {
  const { suggestionId, categories, childItems } = suggestion;

  const id = createId({
    parentId,
    type: NODE_TYPE_SUGGESTION,
    contentId: suggestionId,
  });

  return {
    catalogId: CATALOG_TYPE_UNCATEGORIZED,
    id,
    key: id,
    title: categories.map((c) => c.globalCategoryName).join(', '),
    level: 1,
    parentId,
    suggestion,
    suggestionId,
    expanded: false,
    isLeaf: false,
    allowChildItems: true,
    role: 'treeitem',
    type: NODE_TYPE_SUGGESTION,
    group: { childItemCount: childItems.length },
  };
}
