import { ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit';
import { getItems } from '../../actions/api/items';
import { updatePropsInMatchingNodes } from '../../actions/nodes/updatePropsInMatchingNodes';
import { treeIdByCatalogIdSelector } from '../../selectors/tree/data';
import type { AppDispatch, RootState } from '../..';
import { NodeBase } from '../../../entities/Node';

export const itemsReceivedListenerOptions = {
  actionCreator: getItems.fulfilled,
  effect: (
    action: PayloadAction<{
      categoryId: string;
      catalogId: string;
      meta: { totalNumberOfElements: number };
    }>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    const { dispatch, getState } = listenerApi;
    const { catalogId, categoryId, meta } = action.payload;
    const treeId = treeIdByCatalogIdSelector(getState(), catalogId);
    dispatch(
      updatePropsInMatchingNodes(
        treeId,
        {
          category: {
            childItemCount: meta.totalNumberOfElements,
          },
        },
        (ni: { node: NodeBase }) => ni?.node?.category?.categoryId === categoryId,
      ),
    );
  },
};
