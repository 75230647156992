import { STATE_UNTRANSLATED } from '../category/states';
import { getGenericValidationMessage } from './text';

const validateCategoryType = (isMaster, categoryType) => {
  if (!isMaster) return undefined;

  return categoryType ? undefined : 'Must select category type';
};

export function validateGlobal(category, isNew, isMaster) {
  const {
    externalCategoryId,
    globalCategoryComment,
    globalCategoryName,
    globalPublishDate,
    categoryType,
  } = category;
  return {
    categoryType: validateCategoryType(isMaster, categoryType),
    externalCategoryId:
      getGenericValidationMessage(externalCategoryId) ||
      (!isNew && validateName(externalCategoryId)
        ? 'Category ID must not be empty'
        : undefined),
    globalCategoryComment: getGenericValidationMessage(globalCategoryComment),
    globalCategoryName:
      getGenericValidationMessage(globalCategoryName) ||
      (validateName(globalCategoryName) ? 'Name must not be empty' : undefined),
    globalPublishDate: !Date.parse(globalPublishDate)
      ? 'Please enter a valid date.'
      : undefined,
  };
}

export function validateMarket(category) {
  const {
    categoryComment,
    categoryNameTranslations = { null: null },
    translateState = STATE_UNTRANSLATED,
  } = category;
  return {
    categoryComment: getGenericValidationMessage(categoryComment),
    categoryNameTranslations:
      translateState !== STATE_UNTRANSLATED
        ? Object.entries(categoryNameTranslations).reduce(
            (validation, [languageCode, categoryName]) =>
              Object.assign(validation, {
                [languageCode]:
                  getGenericValidationMessage(categoryName) ||
                  (validateName(categoryName)
                    ? 'The translation shall not be empty.'
                    : undefined),
              }),
            {},
          )
        : {},
  };
}

function validateName(name) {
  return !name || !name.trim();
}
