import { createAction } from '@reduxjs/toolkit';
import { CATEGORIZATION, NODE_COPY, NODE_CUT } from '../../../utils/command';
import { NodeBaseItem } from '../../../entities/Node';

export const categorize = createAction(
  'tree/categorize',
  (treeId: string, items: NodeBaseItem[]) => ({
    meta: { treeId },
    payload: {
      directive: CATEGORIZATION,
      nodeItems: items,
    },
  }),
);

export const copyNode = createAction(
  'tree/copyNode',
  (treeId: string, items: NodeBaseItem[]) => ({
    meta: { treeId },
    payload: {
      directive: NODE_COPY,
      nodeItems: items,
    },
  }),
);

export const cutNode = createAction(
  'tree/cutNode',
  (treeId: string, items: NodeBaseItem[]) => ({
    meta: { treeId },
    payload: {
      directive: NODE_CUT,
      nodeItems: items,
    },
  }),
);
