import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectMarketProps } from '../../selectors/market';
import { FILE } from '.';
import {
  CategoryTypeOption,
  EXPORT_INCLUDE,
  ExportOptions,
} from '../../../components/ModalManager/modals/ExportDialog/exportDialogTypes';
import { toastMessage } from '../toaster';
import RequestFailedError from '../../../utils/error/RequestFailedError';
import type { RootState } from '../..';

export const requestExcel = createAsyncThunk<
  File,
  {
    catalogId: string;
    categoryId?: string;
    exportOptions: ExportOptions | null;
    categoryType: CategoryTypeOption | null;
  },
  { state: RootState }
>(
  'export/requestExcel',
  async (
    {
      catalogId,
      categoryId,
      exportOptions,
      categoryType,
    }: {
      catalogId: string;
      categoryId?: string;
      exportOptions: ExportOptions | null;
      categoryType: CategoryTypeOption | null;
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    const exportWithItems = exportOptions === EXPORT_INCLUDE;
    const blob = await dispatch(
      FILE({
        path: `/rrm-rc/${retailUnit}/${languageCode}/excel-export/catalogues/${catalogId}/categories?${
          categoryId ? `categoryId=${categoryId}&` : ''
        }exportWithItems=${exportWithItems}${categoryType ? `&categoryType=${categoryType}` : ''}`,
        label: `Excel export for catalog with id: ${catalogId}, targeting ${
          categoryId ? `categoryId: ${categoryId}` : 'full tree'
        }`,
      }),
    );
    return blob;
  },
);

export const requestDraftExcel = createAsyncThunk(
  'export/requestDraftExcel',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const blob = await dispatch(
      FILE({
        path: `/rrm-rc/ZZ/en/details-of-change-for-draft`,
        label: 'Excel export for draft excel changes',
        ignoreError: true,
        onFailure: (error: RequestFailedError) => {
          dispatch(toastMessage(error.message));
        },
      }),
    );

    return blob;
  },
);
