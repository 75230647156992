import {
  CATEGORY_TYPE_ACCESSORIES,
  CATEGORY_TYPE_FURNITURE,
  CATEGORY_TYPE_SYSTEM,
} from '../../../../utils/category/categoryTypes';

export const EXPORT_INCLUDE = 'INCLUDE' as const;
export const EXPORT_EXCLUDE = 'EXCLUDE' as const;
export const EXPORT_DRAFT_CHANGES = 'DRAFT_CHANGES' as const;

export const EXPORT_OPTIONS = [
  EXPORT_INCLUDE,
  EXPORT_EXCLUDE,
  EXPORT_DRAFT_CHANGES,
] as const;

export type ExportOptions = (typeof EXPORT_OPTIONS)[number];

export type SourceSelectorOptions = {
  catalogId?: string;
  catalogType?: string;
  categoryId?: string;
  draft?: boolean;
};

export const CATEGORY_TYPE_OPTIONS = [
  CATEGORY_TYPE_ACCESSORIES,
  CATEGORY_TYPE_FURNITURE,
  CATEGORY_TYPE_SYSTEM,
] as const;

export type CategoryTypeOption = (typeof CATEGORY_TYPE_OPTIONS)[number];
