export { default as debounce } from 'lodash-es/debounce';
export { default as defer } from 'lodash-es/defer';
export { default as throttle } from 'lodash-es/throttle';

export function compose(...functions) {
  const [first, ...restOffuncs] = functions.reverse();
  return (...args) => restOffuncs.reduce((res, fn) => fn(res), first(...args));
}

export function returnArg(arg) {
  return arg;
}

export function functionify(func) {
  if (typeof func === 'function') {
    return func;
  }

  return () => func;
}

export function noOperation() {}

export async function timer(timeout = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

export async function poll({ fn, validate, interval, maxAttempts }) {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts += 1;

    if (validate(result)) {
      resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
}
