import { useCallback } from 'react';
import Button from '@ingka/button';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { Account } from '../../Icons/paths';
import styles from './UserProfileButton.module.scss';
import { analyticsEvent } from '../../../store/actions/analytics/analytics';
import { ANALYTICS_USER_PROFILE } from '../../../store/actions/analytics/analyticsCategories';
import { AppThunkDispatch } from '../../../store';
import type { AuthUser } from '../../Auth0Wrapper/Auth0Wrapper';
import { openModal } from '../../../store/actions/modal';

const UserProfileButton = function UserProfileButton({
  className,
}: {
  className?: string;
}) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { user } = useAuth0<AuthUser>();
  const openProfile = useCallback(() => {
    dispatch(openModal('UserProfileDialog'));
    dispatch(
      analyticsEvent({
        category: ANALYTICS_USER_PROFILE,
        action: 'Click user profile open',
        label: 'User has clicked user profile icon to open modal',
      }),
    );
  }, [dispatch]);

  if (!user) return null;

  const initials = `${user?.given_name ? user?.given_name[0] : ''}${
    user?.family_name ? user?.family_name[0] : ''
  }`;

  return (
    <Tooltip
      interactiveCustomTrigger
      tooltipText="Account"
      className={className}
      position="bottom"
    >
      <Button
        className={styles.button}
        onClick={openProfile}
        aria-label="Opens user profile dialog"
        ssrIcon={Account}
        text={initials}
        size="small"
        type="tertiary"
      />
    </Tooltip>
  );
};

export default UserProfileButton;
