import styles from './Spin.module.scss';

export const Spin = ({
  children,
  spin = true,
  width = 24,
  height = 24,
}: {
  children: React.ReactNode;
  spin?: boolean;
  width?: number;
  height?: number;
}) => {
  if (!spin) return children;
  return (
    <div style={{ width, height, display: 'inline-block' }}>
      <div className={styles.spin}>{children}</div>
    </div>
  );
};
