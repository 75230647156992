import { z } from 'zod';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { NOT_VALID_FOR_STRUCURE_CATALOG } from '../../../utils/catalog/notvalidforstructure';
import { UNCATEGORIZED_CATALOG } from '../../../utils/catalog/uncategorized';
import { DELETE, GET, POST, PUT } from '.';
import { FETCH_ITEMS_PAGE_SIZE } from '../../../utils/data/constants';
import { compactItemNo } from '../../../utils/data/utils';
import { itemFromDto } from '../../mappers/dtoMappers';
import { selectMarketProps } from '../../selectors/market';
import {
  AddItemsSchema,
  AddItemsToMultipleSchema,
  AutoCategorizedItemsReport,
  ItemDetails,
  ItemHistorySchema,
  ItemImage,
  ItemRelationsSchema,
  ItemsSchema,
  MarketSalesDateSchema,
  MissingItemsSchema,
  NotValidForStructureItemsSchema,
  UncategorizedItemsSchema,
} from '../../../schemas/items';
import { Item } from '../../../entities/Item';
import type { RootState } from '../..';
import { getItemId } from '../../../utils/item';

export const getAutoCategorizedItems = createAsyncThunk(
  'item/autocategoried',
  async (
    {
      catalogId,
      from,
      to,
      source,
      pageNo,
    }: {
      catalogId: string;
      from: string;
      to: string;
      source: string;
      pageNo: string;
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;
    const pageSize = 20;
    const data = await dispatch(
      GET({
        path: `/rrm-rc/ZZ/en/${catalogId}/items/auto-categorised/${from}/${to}?pageNo=${pageNo}&source=${source}&pageSize=${pageSize}`,
        label: `Get auto categorized items`,
      }),
    );

    const parseData = AutoCategorizedItemsReport.parse(data);
    return parseData;
  },
);

export const addItemsToCategories = createAsyncThunk<
  z.infer<typeof AddItemsToMultipleSchema>,
  { catalogId: string; itemNos: string[]; categories: string[] },
  { state: RootState }
>(
  'item/addItemsToCategories',
  async ({ catalogId, itemNos, categories }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const { languageCode, retailUnit } = selectMarketProps(getState());
    const data = await dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categorise/items`,
        data: {
          itemNos,
          categories,
        },
        label: `Add items ${itemNos.map((m: string) => m)} to categories ${categories.map((m: string) => m)}`,
      }),
    );

    return AddItemsToMultipleSchema.parse(data);
  },
);

export const addItemsToCategory = createAsyncThunk<
  z.infer<typeof AddItemsSchema>,
  { catalogId: string; categoryId: string; items: { itemNo: string }[] },
  { state: RootState }
>(
  'item/addItems',
  async (
    {
      catalogId,
      categoryId,
      items,
    }: { catalogId: string; categoryId: string; items: { itemNo: string }[] },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const payload = items.map((item) => ({ itemNo: item.itemNo }));
    const { languageCode, retailUnit } = selectMarketProps(getState());
    const data = await dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/items`,
        data: payload,
        label: `Add items to Category with id: ${categoryId} in Catalogue with id: ${catalogId}`,
      }),
    );

    return AddItemsSchema.parse(data);
  },
);

export const getItems = createAsyncThunk(
  'item/get',
  async (
    {
      catalogId,
      categoryId,
      pageNo = 0,
    }: { catalogId: string; categoryId: string; pageNo: number },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);

    const data = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/items?pageNo=${pageNo}&pageSize=${FETCH_ITEMS_PAGE_SIZE}`,
        label: `Get items page number: ${pageNo} for Category with id: ${categoryId} in Catalogue with id: ${catalogId}`,
      }),
    );
    const parsedData = ItemsSchema.parse(data);
    const items = parsedData.content.map((dto) => itemFromDto(dto));

    return {
      catalogId,
      categoryId,
      items,
      paging: {
        lastPage: parsedData.last,
        pageNo: parsedData.pageable.pageNumber,
      },
      meta: {
        totalNumberOfElements: parsedData.totalElements,
      },
    };
  },
);

export const getItemImage = createAsyncThunk(
  'item/getItemImage',
  async (
    {
      catalogId,
      categoryId,
      itemNo,
      onFailure,
    }: {
      catalogId: string;
      categoryId: string;
      itemNo: string;
      onFailure: (err: { message: string }) => void;
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);

    const response = await dispatch(
      GET({
        ignoreError: true,
        onFailure,
        label: `Get Item Image information for ${itemNo}`,
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/product-image/items/${itemNo}`,
      }),
    );

    return ItemImage.parse(response);
  },
);

export const getItem = createAsyncThunk(
  'item/getItem',
  async (
    {
      itemNo,
      itemType,
      ignoreError = false,
      catalogId,
      onFailure = null,
    }: {
      itemNo: string;
      itemType: string;
      ignoreError?: boolean;
      catalogId: string;
      onFailure?: (() => void) | null;
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const itemDto = await dispatch(
      GET({
        ignoreError,
        onFailure,
        path: `/rrm-rc/${retailUnit}/${languageCode}/items/${itemNo}/${itemType}?catalogue-id=${catalogId}`,
        label: `Get item with type: ${itemType} and item number: ${itemNo}`,
      }),
    );

    const item = ItemDetails.parse(itemDto);
    item.itemCountrySpecificList = item.itemCountrySpecificList?.map((m) => ({
      ...m,
      itemName: m.itemNameOfficial,
    }));
    return {
      item: {
        ...item,
        itemId: getItemId(item),
      },
    };
  },
);

export const updateItem = createAsyncThunk(
  'item/update',
  async (
    {
      itemNo,
      catalogId,
      mainParentCategoryId,
    }: {
      itemNo: string;
      catalogId: string;
      mainParentCategoryId: string;
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);

    const data = await dispatch(
      PUT({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/items/${itemNo}/main-parent/${mainParentCategoryId}`,
      }),
    );

    return data;
  },
);

export const moveItems = createAsyncThunk(
  'item/moveItems',
  async (
    {
      catalogId,
      categoryId,
      fromCategoryId,
      items,
    }: {
      catalogId: string;
      categoryId: string;
      fromCategoryId: string;
      items: Item[];
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const itemsList = items.map((item) => ({
      itemNo: item.itemNo,
      itemType: item.itemType,
    }));
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const data = await dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/move-items`,
        data: { fromCategoryId, itemsList },
        label: `Move items from Category: ${fromCategoryId} to Category: ${categoryId} in Catalogue with id: ${catalogId}`,
      }),
    );
    const parsedData = AddItemsSchema.parse(data);
    return parsedData;
  },
);

export const removeItems = createAsyncThunk(
  'item/removeItems',
  async (
    {
      catalogId,
      categoryId,
      items,
    }: {
      catalogId: string;
      categoryId: string;
      items: Item[];
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const payload = items.map((item) => ({
      itemNo: item.itemNo,
      itemType: item.itemType,
    }));
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    return dispatch(
      DELETE({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/items`,
        data: payload,
        label: `Remove items from Category with id: ${categoryId} in Catalogue with id: ${catalogId}`,
      }),
    );
  },
);

export const getItemHistory = createAsyncThunk<
  z.infer<typeof ItemHistorySchema>,
  { itemNo: string; itemType: string; pageNo: number },
  { state: RootState }
>(
  'item/itemHistory',
  async (
    {
      itemNo,
      itemType,
      pageNo,
    }: { itemNo: string; itemType: string; pageNo: number },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    const historyEvents = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/items/${itemNo}/${itemType}/events?pageNo=${pageNo}`,
        label: `Fetch history for item: ${itemNo}`,
      }),
    );
    return ItemHistorySchema.parse(historyEvents);
  },
);

export const getMissingItems = createAsyncThunk(
  'item/getMissingItems',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const data = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/items-missing-in-draft`,
        label: 'Get missing items',
      }),
    );
    const parsedData = MissingItemsSchema.parse(data);
    const { categories = [], items = [], topCategories = [] } = parsedData || {};
    return {
      categories: categories?.reduce(
        (cats, cat) =>
          Object.assign(cats, {
            [`${cat.categoryId}`]: {
              ...cat,
              childCategoryCount: cat.childCategories?.length,
              childItemCount: cat.childItems?.length,
              isMissing: true,
            },
          }),
        {},
      ),
      items: items?.map((dto) => itemFromDto(dto, { isMissing: true })),
      languageCode,
      topCategories,
    };
  },
);

export const getNotValidForStructureItems = createAsyncThunk(
  'item/getNotValidForStructureItems',
  async ({ pageNo }: { pageNo: number }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const resultDto = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/not-valid-for-structure-items?pageNo=${
          pageNo || 0
        }&pageSize=${999999999}`,
        label: `Get page number ${pageNo} of not valid for structure items`,
      }),
    );
    const parsedData = NotValidForStructureItemsSchema.parse(resultDto);

    return parsedData;
  },
);

export const getUncategorizedItems = createAsyncThunk(
  'item/getUncategorizedItems',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const resultDto = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/uncategorised-items`,
        label: `Get list of uncategorised items`,
      }),
    );
    const parsedData = UncategorizedItemsSchema.parse(resultDto);

    const items = parsedData.map((m) => itemFromDto(m));

    return {
      items,
    };
  },
);

export const getCategoryRelationsItem = createAsyncThunk(
  'item/getCategoryRelationsItem',
  async (
    { catalogId, itemNo }: { catalogId?: string; itemNo: string },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);

    const queryParam = catalogId ? `?catalogue-id=${catalogId}` : '';
    const data = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/items/${compactItemNo(
          itemNo,
        )}${queryParam}`,
        label: `Get catalogue relations for item number ${itemNo}`,
      }),
    );

    const {
      notValidForStructure,
      uncategorised,
      categoryWithCountrySpecificItemList,
      catalogueList,
      structureReasonText,
      ...rest
    } = ItemRelationsSchema.parse(data);

    if (notValidForStructure) {
      catalogueList.push(NOT_VALID_FOR_STRUCURE_CATALOG.catalogId);
    }
    if (uncategorised) {
      catalogueList.push(UNCATEGORIZED_CATALOG.catalogId);
    }
    if (
      (!Array.isArray(categoryWithCountrySpecificItemList) ||
        !categoryWithCountrySpecificItemList.length) &&
      (!Array.isArray(catalogueList) || !catalogueList.length)
    ) {
      return {
        catalogIdsList: ReadOnlyArray.Empty,
        categoryIdsList: ReadOnlyArray.Empty,
        item: null,
      };
    }
    const item = itemFromDto(rest);
    return {
      uncategorised,
      notValidForStructure,
      catalogIdsList: catalogueList,
      categoryWithCountrySpecificItemList,
      item,
      structureReasonText,
    };
  },
);

export const getMarketSalesDates = createAsyncThunk(
  'item/marketSalesDate',
  async ({ itemNo, itemType }: { itemNo: string; itemType?: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const data = await dispatch(
      GET({
        path: `/rrm-rc/ZZ/en/item/${itemNo}/${itemType}/market-sales-dates`,
        label: `Get market sales date for  ${itemNo}-${itemType}`,
      }),
    );

    const parsedData = MarketSalesDateSchema.parse(data);
    return parsedData;
  },
);

interface PreviewItemsInput {
  catalogId: string;
  categoryId: string;
  itemNumbers: Item[];
}

export const previewItems = createAsyncThunk(
  'importitems/previewItems',
  async ({ catalogId, categoryId, itemNumbers }: PreviewItemsInput, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const result = await dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/${categoryId}/preview-import-items`,
        data: itemNumbers.map((itemNo) => ({ itemNo })),
        label: `Preview items for category ${categoryId} in catalogue ${catalogId}`,
      }),
    );
    return result;
  },
);

export const getItemCategorizedStatistics = createAsyncThunk(
  'importitems/previewItems',
  async (
    {
      catalogId,
      from,
      to,
      source,
    }: {
      catalogId: string;
      from: string;
      to: string;
      source: string | undefined;
    },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const result = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/${catalogId}/stats/item-categorised/${from}/${to}?source=${source}`,
        label: `Items categorised stats for catalogue ${catalogId} with date ${from} - ${to}`,
      }),
    );
    return result;
  },
);
