import { updateMatchingNodesInTree } from '../../../components/Tree/helpers';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { updateData } from '../tree';

export const updatePropsInMatchingNodes =
  (tree, props, predicate, selectNodeAtPath) => async (dispatch, getState) => {
    const treeData = updateMatchingNodesInTree(
      predicate,
      selectTreeDataByTreeId(getState(), tree),
      props,
    );
    return dispatch(updateData(tree, treeData, { selectNodeAtPath }));
  };
