import { ExclamationMark, Translation } from '../Icons';
import Badge from './Badge';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './StatusBadges.module.scss';

export const AutoTranslated = function AutoTranslated({
  compact,
}: {
  compact: boolean;
}) {
  return (
    <Badge
      className={styles.translation}
      compact={compact}
      label="Auto-translated"
      labelCompact="Auto"
      tooltip="Auto-translated"
    />
  );
};

export const NotVerified = function NotVerified({ compact }: { compact: boolean }) {
  return (
    <Badge
      className={styles.translation}
      compact={compact}
      CustomIcon={ExclamationMark}
      label="Not verified"
      tooltip="Not verified"
    />
  );
};

export const NotVerifiedRenamed = function NotVerifiedRenamed({
  compact,
}: {
  compact: boolean;
}) {
  return (
    <Badge
      className={styles.translation}
      compact={compact}
      iconPath={pencil}
      label="Not verified - Renamed by Global"
      labelCompact="GBL"
      tooltip="Not verified - Renamed by Global"
    />
  );
};

export const Untranslated = function Untranslated({
  compact,
  label = 'Untranslated',
}: {
  compact: boolean;
  label?: string;
}) {
  return (
    <Badge
      className={styles.translation}
      compact={compact}
      CustomIcon={Translation}
      label={label}
      tooltip="Untranslated"
    />
  );
};

export const UntranslatedCount = function UntranslatedCount({
  compact,
  count,
}: {
  compact: boolean;
  count: number;
}) {
  return (
    <Badge
      className={styles.translationInverted}
      compact={compact}
      label={`${count} translations`}
      labelCompact={count.toString()}
      tooltip="Untranslated"
    />
  );
};
