import { LegacyRef, forwardRef, useMemo } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useAllIndicators } from '../../../../hooks/useIndicators';
import { treeIdByCatalogIdSelector } from '../../../../store/selectors/tree/data';
import { destructId, hasLoadMoreItems } from '../../../../utils/tree/treeNode';
import StatusBadges from '../../../StatusBadges';
import { createContentNode } from '../createContentNode';
import LoadMoreItems from '../LoadMoreItems';
import Title from '../Title';
import { selectSortedCatalogs } from '../../../../store/slices/tree';
import { selectIsGlobalMarket } from '../../../../store/slices/intl';
import { AutoCategorisedBadge } from '../../../StatusBadges/AutoCategorisedBadge';

import styles from './Category.module.scss';
import { InvalidImageBadge } from '../../../StatusBadges/InvalidImageBadge';
import type { NodeBase } from '../../../../entities/Node';
import type { RootState } from '../../../../store';

const Category = forwardRef(function CategoryNode(
  { node }: { node: NodeBase },
  ref: LegacyRef<HTMLDivElement>,
) {
  const isGlobalMarket = useSelector(selectIsGlobalMarket);
  const { catalogId, category, title, parentId } = node;

  const tree = useSelector((state: RootState) =>
    treeIdByCatalogIdSelector(state, catalogId),
  );

  const sortedCatalogs = useSelector((state: RootState) =>
    selectSortedCatalogs(state),
  );

  const isRelatedCategory = useMemo(() => {
    const mainParentCategoryId = node.category?.mainParentCategoryId;
    return (
      (mainParentCategoryId &&
        mainParentCategoryId !== destructId(node?.parentId).value) ||
      false
    );
  }, [node]);

  const isNew = useMemo(
    () => !node.category.categoryId && !node.title,
    [node.category.categoryId, node.title],
  );
  const { childItemCount, isMissing } = category || {};
  const loadMore = useMemo(() => hasLoadMoreItems(node), [node]);
  const memoTitle = useMemo(() => {
    if (!childItemCount) {
      return title;
    }
    if (isMissing) {
      return (
        <>
          {title}
          <span style={{ color: 'rgb(var(--colour-semantic-negative))' }}>
            {` (${childItemCount})`}
          </span>
        </>
      );
    }
    return `${title} (${childItemCount})`;
  }, [childItemCount, isMissing, title]);
  const { badges, summaries } = useAllIndicators(catalogId, category);
  const sortedCatalog = sortedCatalogs[catalogId];
  const sortedParent = sortedCatalog && sortedCatalog[parentId];
  const hasChildren =
    !!node.category.childCategoryCount || !!node.category.childItemCount;
  return (
    <>
      <div
        ref={ref}
        className={cx(
          styles.titleWrapper,
          {
            [styles.relatedCategory]: isRelatedCategory,
          },
          {
            [styles.new]: isNew,
          },
          {
            [styles.isSorted]:
              sortedParent && sortedParent[node.category.categoryId],
          },
        )}
      >
        <StatusBadges className="" badges={badges} summaries={summaries} compact />
        {node.invalidCategoryImage && hasChildren && <InvalidImageBadge compact />}
        {isGlobalMarket && node?.category.autoCategorisable && (
          <AutoCategorisedBadge compact />
        )}
        <Title title={memoTitle} />
      </div>
      {loadMore && <LoadMoreItems node={node} tree={tree || ''} />}
    </>
  );
});

export default createContentNode(Category);
