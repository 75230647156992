import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { arrayify } from '../../utils/array';
import { DeletedCount } from './DeleteBadges';
import mappings from './mappings';
import { MoveCount } from './MoveBadges';
import { UnpublishedCount } from './PublishBadges';
import styles from './StatusBadges.module.scss';
import { UntranslatedCount } from './TranslationBadges';

const StatusBadges = function StatusBadges({
  badges,
  className,
  compact,
  summaries = {},
  ...rest
}) {
  const { deleted, moved, unpublished, untranslated } = summaries;
  return (
    <div className={cx(styles.badges, { [styles.compact]: compact }, className)}>
      {moved?.hasSubCategories ? <MoveCount count={moved.count} compact /> : null}
      {untranslated?.hasSubCategories ? (
        <UntranslatedCount count={untranslated.count} compact />
      ) : null}
      {unpublished?.hasSubCategories ? (
        <UnpublishedCount count={unpublished.count} compact />
      ) : null}
      {deleted?.hasSubCategories ? (
        <DeletedCount count={deleted.count} compact />
      ) : null}
      {arrayify(badges).map((badge) => {
        const Badge = mappings[badge];
        return Badge ? <Badge key={badge} compact={compact} {...rest} /> : null;
      })}
    </div>
  );
};

StatusBadges.propTypes = {
  badges: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
  summaries: PropTypes.shape({
    unpublished: PropTypes.shape({
      hasSubCategories: PropTypes.bool,
      count: PropTypes.number,
    }),
    untranslated: PropTypes.shape({
      hasSubCategories: PropTypes.bool,
      count: PropTypes.number,
    }),
  }),
};

export default StatusBadges;
