import { NODE_TYPE_REASON_GROUP } from '../../types/nodeTypes';
import { createNewNode } from '../node';

export function toReasonGroupNode({
  catalogId,
  childItemCount,
  reason,
  tooltip = 'Not valid for structure reason',
  parentId,
  ...rest
}) {
  return createNewNode(
    catalogId,
    reason,
    NODE_TYPE_REASON_GROUP,
    {
      group: { childItemCount },
      parentId,
      title: reason,
      tooltip,
      ...rest,
    },
    parentId,
  );
}
