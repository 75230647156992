export class ReadOnlyObject extends Object {
  constructor(mutable) {
    super(0);
    Object.assign(this, mutable);
    Object.freeze(this);
  }

  static get [Symbol.species]() {
    return Object;
  }

  static Empty = new ReadOnlyObject();
}
