import { useCallback, useRef } from 'react';

export const useClosestAncestor = (selector) => {
  const elementRef = useRef();
  const ancestorRef = useRef();
  const callbackRef = useCallback(
    (node) => {
      callbackRef.current = node;
      elementRef.current = node;
      ancestorRef.current = elementRef.current?.closest(selector);
    },
    [selector],
  );

  return [callbackRef, ancestorRef];
};
