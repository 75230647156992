import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createReferenceEqualSelector } from '../../selectors/utils';
import { matcher } from './helpers';
import type { RootState } from '../../index';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    errors: [],
  } as { errors: string[] },
  extraReducers: (builder) =>
    builder.addMatcher(
      matcher,
      (
        state,
        action: PayloadAction<unknown, string, unknown, { message: string }>,
      ) => {
        const { error } = action;
        state.errors.push(error.message);
      },
    ),
  reducers: {},
});

export default errorSlice.reducer;

const errorSelector = (state: RootState) => state.error;

export const selectErrors = createReferenceEqualSelector(
  errorSelector,
  (error) => error.errors,
);
export const selectHasErrors = createReferenceEqualSelector(
  selectErrors,
  (errors) => !!errors.length,
);
