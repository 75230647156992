import { z } from 'zod';

export const PaginationSchema = z.object({
  pageable: z.object({
    sort: z.object({
      sorted: z.boolean(),
      unsorted: z.boolean(),
      empty: z.boolean(),
    }),
    pageNumber: z.number(),
    pageSize: z.number(),
    offset: z.number(),
    paged: z.boolean(),
    unpaged: z.boolean(),
  }),
  last: z.boolean(),
  totalPages: z.number(),
  totalElements: z.number(),
  first: z.boolean(),
  sort: z.object({
    sorted: z.boolean(),
    unsorted: z.boolean(),
    empty: z.boolean(),
  }),
  number: z.number(),
  numberOfElements: z.number(),
  size: z.number(),
  empty: z.boolean(),
});
