import { getNodeType } from '../../../utils/tree/nodeItem';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
} from '../../../utils/types/nodeTypes';

const canPasteItem = ({ nextParent, node }) =>
  nextParent?.type === NODE_TYPE_CATEGORY &&
  nextParent?.id !== node?.parentId &&
  !nextParent?.category?.childCategoryCount;

const canPasteCategory = ({ nextParent }) => {
  const parentType = nextParent?.type;
  return (
    (parentType === NODE_TYPE_CATEGORY || parentType === NODE_TYPE_CATALOG) &&
    !nextParent?.category?.childItemCount
  );
};

export const canPasteNode = (eventArgs, moveDisabled) => {
  if (!eventArgs?.nextParent?.allowChildItems) {
    return false;
  }

  const type = getNodeType(eventArgs);
  if (type === NODE_TYPE_CATEGORY) {
    if (moveDisabled) {
      const { nextParent, node } = eventArgs;
      return nextParent?.id === node?.parentId;
    }
    return canPasteCategory(eventArgs);
  }
  if (type === NODE_TYPE_ITEM) {
    return canPasteItem(eventArgs);
  }
  return false;
};
