import { addNodesInTree } from '../../../components/Tree/helpers';
import { arrayify } from '../../../utils/array';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { updateData } from '.';

export const addNodes = (tree, nodes, options) => async (dispatch, getState) => {
  const currentTreeData = selectTreeDataByTreeId(getState(), tree);
  const nodesToAdd = arrayify(nodes);
  const result = addNodesInTree(nodesToAdd, currentTreeData, options);
  return dispatch(updateData(tree, result.treeData));
};
