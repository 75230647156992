import React from 'react';
import Title from '../Title';
import { NodeBase } from '../../../../entities/Node';
import withHoverActions from '../../HoverActions/withHoverActions';

const MultiSelect = ({ node }: { node: NodeBase }) => {
  return <Title title={node.title} />;
};

export default withHoverActions()(MultiSelect);
