import {
  getNodeItemsBetween,
  insertNodeAfter,
  isDescendant,
} from '../../../components/Tree/helpers';
import { getMultiselectPaths } from '../../../components/Tree/helpers/getMultiselectPaths';
import { getCatalogId } from '../../../utils/tree/ids';
import { getNodeType } from '../../../utils/tree/nodeItem';
import { actionsNode } from '../../../utils/tree/virtualNodes';
import { NODE_TYPE_ITEM } from '../../../utils/types/nodeTypes';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import {
  selectedTreeNodeSelector,
  selectedTreeNodesByTreeIdSelector,
} from '../../selectors/tree/node';
import { selectNodes, updateData } from '../tree';
import { removeNodes } from './removeNode';
import { selectNode } from './selectNode';

export const selectMultiple =
  (tree, nodeItem, keys) => async (dispatch, getState) => {
    const { ctrlKey, shiftKey, metaKey } = keys;
    if (!shiftKey && !ctrlKey && !metaKey) {
      return dispatch(selectNode(tree, nodeItem));
    }

    let state = getState();
    const { nodeItem: selectedNodeItem, catalogId } =
      selectedTreeNodeSelector(state);
    if (
      !selectedNodeItem ||
      getCatalogId(tree) !== catalogId ||
      getNodeType(selectedNodeItem) !== nodeItem.node.type
    ) {
      return undefined;
    }

    const multiSelectPaths = getMultiselectPaths(
      selectTreeDataByTreeId(state, tree),
    );
    if (multiSelectPaths.length) {
      await dispatch(removeNodes(tree, multiSelectPaths));
    }

    let nodeItems = [];
    let selectNodeItem = nodeItem;
    state = getState();
    const currentTreeData = selectTreeDataByTreeId(state, tree);
    if (shiftKey && nodeItem.node.id !== selectedNodeItem.node.id) {
      nodeItems = getNodeItemsBetween({
        treeData: currentTreeData,
        fromId: nodeItem.node.id,
        toId: selectedNodeItem.node.id,
      }).map((m) => ({
        node: m.node,
        path: m.path,
        treeIndex: m.treeIndex,
      }));
    } else if (ctrlKey || metaKey) {
      const selectedNodeItems = selectedTreeNodesByTreeIdSelector(
        state,
        tree,
      ).nodeItems;
      nodeItems = [
        ...(selectedNodeItems.length ? selectedNodeItems : [selectedNodeItem]),
      ];
      const index = nodeItems.findIndex((ni) => ni.node.id === nodeItem.node.id);
      if (index === -1) {
        nodeItems.push(nodeItem);
      } else {
        nodeItems.splice(index, 1);
        const [firstNode] = nodeItems;
        selectNodeItem = firstNode;
      }
    }
    const isMultiselectable = checkIfMultiselectable.bind(
      null,
      nodeItems,
      selectedNodeItem,
    );

    nodeItems = nodeItems.filter(isMultiselectable);

    if (!nodeItems.length) return;

    const result = insertNodeAfter({
      afterNodeItem: nodeItems[nodeItems.length - 1],
      newNode: actionsNode({
        title: `${nodeItems.length} items`,
        ...getNodeSpecificProps(nodeItem.node),
      }),
      treeData: selectTreeDataByTreeId(state, tree),
    });
    await dispatch(updateData(tree, result.treeData));

    return dispatch(selectNodes(tree, selectNodeItem, nodeItems));
  };

function checkIfMultiselectable(nodeItems, selectedNodeItem, ni) {
  return (
    selectedNodeItem.node.type === ni.node.type && // must be of same type
    selectedNodeItem.node.parentId === ni.node.parentId &&
    selectedNodeItem.node.isInSuggestion === ni.node.isInSuggestion &&
    !nodeItems.some((ancestor) => isDescendant(ancestor.node, ni.node)) // inheritance must not exist
  );
}

function getNodeSpecificProps(node) {
  return {
    catalogId: node.catalogId,
    level: node.level,
    parentId: node.parentId,
    ...(node.type === NODE_TYPE_ITEM
      ? {
          isInSuggestion: node.isInSuggestion,
          isItems: true,
          isUncategorized: node.isUncategorized,
          item: { suggestedCategories: node.item.suggestedCategories },
        }
      : {}),
  };
}
