import { ReadOnlyArray } from '../../array/ReadOnlyArray';
import DefaultTreeConfig from './DefaultTreeConfig';

export default class ItemsListConfig extends DefaultTreeConfig {
  allowedSources = ReadOnlyArray.Empty;

  canPaste = () => false;

  indentTree = false;
}
