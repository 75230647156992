import React, { useCallback, useMemo, useContext } from 'react';
import cx from 'classnames';
import { TreeContext } from '../../../contexts/TreeContext';
import { Spinner } from '../../Spinner';
import styles from './HoverActions.module.scss';
import SSRIcon from '@ingka/ssr-icon';

interface Props {
  className?: string;
  iconPath: (prefix?: string) => React.SVGProps<SVGElement>[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  id: string;
  label: string;
}

const ActionButton = ({ className, iconPath, label, onClick, id }: Props) => {
  const { loading } = useContext(TreeContext);
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      if (!onClick) {
        return;
      }
      onClick(e);
    },
    [onClick],
  );

  const disabled = useMemo(
    () => !!loading && !!id && (id.endsWith('up') || id.endsWith('down')),
    [id, loading],
  );

  const Component = useMemo(() => {
    if (loading) {
      return (
        <Spinner>
          <SSRIcon paths={iconPath} />
        </Spinner>
      );
    }
    return <SSRIcon paths={iconPath} />;
  }, [iconPath, loading]);

  return (
    <button
      aria-label={label}
      className={cx(styles.button, className)}
      tabIndex={-1}
      type="button"
      onClick={handleClick}
      disabled={!onClick || disabled}
    >
      <div className={styles.background}>{Component}</div>
    </button>
  );
};

export default ActionButton;
