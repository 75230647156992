import {
  createSelector,
  createSelectorCreator,
  createStructuredSelector,
  lruMemoize,
} from 'reselect';
import { shallowEqual } from 'react-redux';
import { getCatalogId } from '../../utils/tree/ids';
import { deepEqual } from '../../utils/validation/equality';
import type { RootState } from '../index';

export { createSelector as createReferenceEqualSelector, createStructuredSelector };
export const createDeepEqualResultSelector = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: deepEqual,
});
export const createShallowEqualResultSelector = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: shallowEqual,
});

export const getIdFromArgument = (state: RootState | unknown, id: string) => id;

export const getCatalogIdFromTreeId = (state: RootState, treeId: string) =>
  getCatalogId(treeId);
