import { timer } from '../function';
import { MISSING_ITEM } from '../../store/constants/importitems';

export const compactItemNo = (formatedItemNo) =>
  formatedItemNo && formatedItemNo.replaceAll(/^s|[.-]/g, '');
export const formatItemNo = (itemNoToFormat) =>
  itemNoToFormat && itemNoToFormat.replace(/^s?(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3');
export const isItemNo = (itemNo) =>
  typeof itemNo === 'string' &&
  itemNo.match(/(^s?\d{8}$)|(^\d{3}[.-]\d{3}[.-]\d{2}$)/);

export const separateNameAndDesc = (itemName) => {
  const [, name, description] = (itemName || '').match(/^([A-ZÅÄÖ /]*) (.*)$/) || [
    '',
    MISSING_ITEM,
    MISSING_ITEM,
  ];
  return { name, description };
};

export const downloadBlob = async (blob) => {
  const filename = blob.name || 'download';
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  await timer(150);
  URL.revokeObjectURL(url);
};
