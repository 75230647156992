import { ReadOnlyArray } from '../array/ReadOnlyArray';
import { isEqualByProps } from '../object/compare';
import { VALID_PUBLISHED_STATES } from './defines';
import { STATE_PUBLISHED, STATE_VERIFIED } from './states';

export const editableProps = new ReadOnlyArray([
  'autoCategorisable',
  'categoryComment',
  'categoryNameTranslations',
  'categoryNameTranslationApproved',
  'externalCategoryId',
  'globalCategoryComment',
  'globalCategoryName',
  'globalCategoryPublished',
  'globalPublishDate',
  'mainParentCategoryId',
  'published',
  'categoryType',
]);

export function isCategoriesEqual(first, second) {
  return isEqualByProps(first, second, ...editableProps);
}

export function isUnpublished(category) {
  return (
    !!category?.newCategory ||
    !VALID_PUBLISHED_STATES.includes(category?.publishState || STATE_PUBLISHED)
  );
}

export function isUntranslated(category) {
  return (category?.translateState || STATE_VERIFIED) !== STATE_VERIFIED;
}
