import React, { useCallback } from 'react';
import Tooltip from '@ingka/tooltip';
import Button from '@ingka/button';
import { useDispatch, useSelector } from 'react-redux';
import { searchToggle, selectSearchPanelOpenForTree } from '../../store/slices/tree';
import magnifyingGlassList from '@ingka/ssr-icon/paths/magnifying-glass-list';

import { AppThunkDispatch, RootState } from '../../store';

const SearchToggler = function SearchToggler({ tree }: { tree: string }) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const open = useSelector((state: RootState) =>
    selectSearchPanelOpenForTree(state, tree),
  );
  const toggleSearchAction = useCallback(
    () => dispatch(searchToggle(tree, !open)),
    [dispatch, tree, open],
  );

  return (
    <Tooltip
      interactiveCustomTrigger
      tooltipText={open ? 'Close search pane' : 'Open search pane'}
    >
      <Button
        type="tertiary"
        size="small"
        iconOnly
        aria-label={open ? 'Close search pane' : 'Open search pane'}
        onClick={toggleSearchAction}
        ssrIcon={magnifyingGlassList}
      />
    </Tooltip>
  );
};

export default SearchToggler;
