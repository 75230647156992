import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../..';
import { selectCurrentRetailUnit } from '../../slices/intl';
import { isMasterCatalog } from '../../../utils/catalog';
import { getInvalidImages } from '../api/catalogs';
import {
  selectInvalidImages,
  setFetchingInvalidCategoryImages,
  setInvalidCategoryImages,
} from '../../slices/invalidCategoryImages/invalidCategoryImages';

export const fetchInvalidCategoryImages = createAsyncThunk<
  string[],
  {
    catalogType: string;
    catalogId: string;
    catalogName: string;
    isGlobalMarket: boolean;
  },
  { state: RootState }
>(
  'tree/fetchInvalidCategoryImages',
  async ({ catalogType, catalogId, catalogName, isGlobalMarket }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const isMaster = isMasterCatalog({ catalogType, catalogId, catalogName });
    const retailUnit = selectCurrentRetailUnit(getState());
    const invalidImages = selectInvalidImages(getState());

    const globalFetch = isGlobalMarket && !Number.isNaN(parseInt(catalogId, 10));
    const marketFetch = !isGlobalMarket && isMaster;

    if (invalidImages?.fetching) return [];

    if (!globalFetch && !marketFetch) return [];

    dispatch(setFetchingInvalidCategoryImages({ fetching: true }));

    const categoryIds = await dispatch(getInvalidImages({ catalogId })).unwrap();

    dispatch(setInvalidCategoryImages({ categoryIds, retailUnit }));

    return categoryIds;
  },
);
