import { getNodeInTree, getNodesInTree } from '../../../components/Tree/helpers';
import { getMultiselectPaths } from '../../../components/Tree/helpers/getMultiselectPaths';
import { getCatalogId, makeTreeId } from '../../../utils/tree/ids';
import { getNodeId } from '../../../utils/tree/nodeItem';
import {
  selectTreeDataByCatalogId,
  selectTreeDataByTreeId,
} from '../../selectors/tree';
import { selectedTreeNodesSelector } from '../../selectors/tree/node';
import { selectNodes } from '../tree';
import { removeNode, removeNodes } from './removeNode';

export const selectNode =
  (tree, nodeItem, keepSelection) => async (dispatch, getState) => {
    const {
      catalogId,
      nodeItems: currentNodeItems,
      selectedNodeItem: currentNodeItem,
    } = selectedTreeNodesSelector(getState());
    const currentNode = currentNodeItem && currentNodeItem.node;
    if (
      !tree ||
      (catalogId === getCatalogId(tree) && currentNode === nodeItem?.node)
    ) {
      return undefined;
    }
    const currentTree = makeTreeId(null, catalogId);
    if (
      currentNodeItem?.node?.isNew &&
      getNodeId(currentNodeItem) !== getNodeId(nodeItem)
    ) {
      await dispatch(removeNode(currentTree, currentNodeItem.path));
    }

    const currentState = getState();
    const selectedNodeItem = nodeItem
      ? getNodeInTree({
          treeData: selectTreeDataByTreeId(currentState, tree),
          path: nodeItem.path,
        })
      : null;
    if (
      keepSelection &&
      nodeItem &&
      currentNodeItems.some((ni) => ni.node.id === nodeItem?.node?.id)
    ) {
      const nodeItems = getNodesInTree({
        treeData: selectTreeDataByTreeId(currentState, tree),
        paths: currentNodeItems.map((ni) => ni.path),
      });
      return dispatch(selectNodes(tree, selectedNodeItem, nodeItems));
    }

    const multiSelectPaths = getMultiselectPaths(
      selectTreeDataByCatalogId(currentState, catalogId),
    );
    if (multiSelectPaths.length) {
      dispatch(removeNodes(currentTree, multiSelectPaths));
    }

    return dispatch(selectNodes(tree, selectedNodeItem));
  };
