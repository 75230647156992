import CogAutoIcon from './raw/cog-auto.svg?react';
import { IconProps } from './types';

const Cogs = ({ className, label, style, viewBox }: IconProps) => (
  <CogAutoIcon
    className={className}
    title={label}
    aria-labelledby={label}
    viewBox={viewBox || '0 0 122.88 92.62'}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    width="20px"
    fill={'#fff'}
    height="20px"
  />
);

export default Cogs;
