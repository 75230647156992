import {
  LIST_EXISTS,
  LIST_IMPORT,
  LIST_NOT_VALID_FOR_STRUCTURE,
} from '../../store/constants/importitems';
import {
  CATALOG_TYPE_AUTOMATED,
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_MARKET,
  CATALOG_TYPE_THEMES,
  CATALOG_TYPE_UNCATEGORIZED,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_MISSINGITEMS,
  CATALOG_TYPE_SERIES,
  CATALOG_TYPE_COLLECTION,
} from '../types/catalogTypes';
import AutomatedTreeConfig from './config/AutomatedTreeConfig';
import DefaultTreeConfig from './config/DefaultTreeConfig';
import ItemsListConfig from './config/ItemsListConfig';
import MarketTreeConfig from './config/MarketTreeConfig';
import MasterTreeConfig from './config/MasterTreeConfig';
import NotValidForStructureTreeConfig from './config/NotValidForStructureTreeConfig';
import SeriesTreeConfig from './config/SeriesTreeConfig';
import CollectionsTreeConfig from './config/CollectionsTreeConfig';
import ThemesTreeConfig from './config/ThemesTreeConfig';
import UncategorizedTreeConfig from './config/UncategorizedTreeConfig';

const conf = {
  [CATALOG_TYPE_AUTOMATED]: AutomatedTreeConfig,
  [CATALOG_TYPE_MARKET]: MarketTreeConfig,
  [CATALOG_TYPE_MASTER]: MasterTreeConfig,
  [CATALOG_TYPE_NOTVALIDFORSTRUCTURE]: NotValidForStructureTreeConfig,
  [CATALOG_TYPE_THEMES]: ThemesTreeConfig,
  [CATALOG_TYPE_MISSINGITEMS]: UncategorizedTreeConfig,
  [CATALOG_TYPE_UNCATEGORIZED]: UncategorizedTreeConfig,
  [LIST_EXISTS]: ItemsListConfig,
  [LIST_IMPORT]: ItemsListConfig,
  [LIST_NOT_VALID_FOR_STRUCTURE]: ItemsListConfig,
  [CATALOG_TYPE_SERIES]: SeriesTreeConfig,
  [CATALOG_TYPE_COLLECTION]: CollectionsTreeConfig,
};

export function createTreeConfig({
  catalogId,
  catalogType,
  productsCatalogId,
  productsDraftCatalogId,
  isEditorForCurrentMarket,
  isGlobalMarket,
  isGlobalEditor,
}: {
  catalogId: string;
  catalogType: keyof typeof conf;
  productsCatalogId: string;
  productsDraftCatalogId: string;
  isEditorForCurrentMarket: boolean;
  isGlobalMarket: boolean;
  isGlobalEditor: boolean;
}) {
  const TreeConfig = conf[catalogType] || DefaultTreeConfig;
  return new TreeConfig({
    catalogId,
    productsCatalogId,
    productsDraftCatalogId,
    isEditorForCurrentMarket,
    isGlobalMarket,
    isGlobalEditor,
  });
}
