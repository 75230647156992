import cx from 'classnames';
import styles from './Title.module.scss';
import React from 'react';

const Title = ({
  className,
  title,
}: {
  className?: string;
  title: string | React.ReactNode;
}) => <bdi className={cx(styles.title, className)}>{title}</bdi>;

export default Title;
