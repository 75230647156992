import React, { useCallback } from 'react';
import Tooltip from '@ingka/tooltip';
import PropTypes from 'prop-types';
import Button from '@ingka/button';
import { useDispatch } from 'react-redux';
import { Contract, Expand } from '../Icons/paths';
import { TreePanes, toggleActivePane } from '../../store/slices/pane';
import { AppThunkDispatch } from '../../store';

const ExpandToggler = function ExpandToggler({
  checked,
  pane,
}: {
  checked: boolean;
  pane: TreePanes;
}) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const toggleSizeAction = useCallback(
    () => dispatch(toggleActivePane(pane)),
    [dispatch, pane],
  );
  return (
    <Tooltip
      interactiveCustomTrigger
      tooltipText={checked ? 'Contract pane' : 'Expand pane'}
    >
      <Button
        ssrIcon={checked ? Contract : Expand}
        onClick={toggleSizeAction}
        iconOnly
        type="tertiary"
        size="small"
        aria-label={checked ? 'Contract pane' : 'Expand pane'}
      />
    </Tooltip>
  );
};

ExpandToggler.propTypes = {
  checked: PropTypes.bool,
  pane: PropTypes.string,
};

export default ExpandToggler;
