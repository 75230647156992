import { useSelector } from 'react-redux';
import Badge from './Badge';
import styles from './StatusBadges.module.scss';
import imageStrike from '@ingka/ssr-icon/paths/image-strikethrough';
import { selectIsGlobalMarket } from '../../store/slices/intl';

export const InvalidImageBadge = function InvalidImageBadge({
  compact = false,
}: {
  compact?: boolean;
}) {
  const isGlobal = useSelector(selectIsGlobalMarket);
  // global can have both invalid category and product image
  return (
    <Badge
      tooltip={`${isGlobal ? 'Invalid image' : 'Invalid product image'}`}
      iconPath={imageStrike}
      label={`${isGlobal ? 'Invalid image' : 'Invalid product image'}`}
      compact={compact}
      className={styles.invalidimage}
    />
  );
};
