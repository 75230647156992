import React from 'react';
import cx from 'classnames';
import styles from './Header.module.scss';

const Header = function Header({
  buttons,
  children,
  className,
  ...props
}: {
  buttons: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cx(styles.header, className)} {...props}>
      <div className={styles.content}>{children}</div>
      <div className={styles.buttons}>{Array.isArray(buttons) && buttons}</div>
    </div>
  );
};

export default Header;
