import { oneOf, shape, string } from 'prop-types';
import { NODE_TYPE_CATEGORY, NODE_TYPE_ITEM } from '../types/nodeTypes';

export const SHAPE_SEARCH_CONDITION = shape({
  by: string.isRequired,
  condition: string,
});

export const SHAPE_SEARCH_SUGGESTION = shape({
  id: string.isRequired,
  title: string.isRequired,
  type: oneOf([NODE_TYPE_CATEGORY, NODE_TYPE_ITEM]).isRequired,
  data: shape({}).isRequired,
});
