export const NODE_TYPE_CATALOG = 'catalogue';
export const NODE_TYPE_CATEGORY = 'category';
export const NODE_TYPE_DATE_GROUP = 'date-group';
export const NODE_TYPE_REASON_GROUP = 'reason-group';
export const NODE_TYPE_SUGGESTION = 'suggestion';
export const NODE_TYPE_ITEM = 'item';
export const NODE_TYPE_MULTI_SELECT = 'MULTI-SELECT';
export const NODE_TYPE_SMALL_BANNER_NODE = 'SMALL-BANNER';

export const GROUP_TYPES = [
  NODE_TYPE_DATE_GROUP,
  NODE_TYPE_REASON_GROUP,
  NODE_TYPE_SUGGESTION,
];

export const NON_NODE_ITEM_TYPES = [
  NODE_TYPE_MULTI_SELECT,
  NODE_TYPE_SMALL_BANNER_NODE,
];
