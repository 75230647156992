import React from 'react';
import Tooltip from '@ingka/tooltip';
import cx from 'classnames';
import styles from './StatusBadges.module.scss';
import SSRIcon from '@ingka/ssr-icon';

const Badge = function Badge({
  className,
  compact,
  iconPath,
  CustomIcon,
  label = '',
  tooltip,
  labelCompact = '',
}: {
  className: string;
  compact: boolean;
  iconPath?: (prefix?: string) => React.SVGProps<SVGElement>[];
  CustomIcon?: React.ElementType;
  label?: string;
  tooltip?: string;
  labelCompact?: string;
}) {
  const lbl = compact ? labelCompact : label;

  if (tooltip) {
    return (
      <Tooltip interactiveCustomTrigger tooltipText={tooltip} position="trailing">
        <span className={cx(styles.badge, { [styles.compact]: compact }, className)}>
          {!!iconPath && <SSRIcon paths={iconPath} className={styles.icon} />}
          {!!CustomIcon && <CustomIcon className={styles.icon} />}
          {!!lbl && <span className={styles.label}>{lbl}</span>}
        </span>
      </Tooltip>
    );
  }

  return (
    <span className={cx(styles.badge, { [styles.compact]: compact }, className)}>
      {!!iconPath && <SSRIcon paths={iconPath} className={styles.icon} />}
      {!!lbl && <span className={styles.label}>{lbl}</span>}
    </span>
  );
};

export default Badge;
