import { createAsyncThunk } from '@reduxjs/toolkit';
import { event } from 'react-ga';
import { ANALYTICS_ENABLED } from '../../../utils/analytics';
import { log } from '../../../utils/log';

export const analyticsEvent = createAsyncThunk(
  'analytics/event',
  ({
    category,
    action,
    label,
  }: {
    category: string;
    action: string;
    label: string;
  }) => {
    // @ts-expect-error log not typed
    log('analytics/event', { action, category, label });
    if (ANALYTICS_ENABLED) {
      event({
        action,
        category,
        label,
      });
    }
  },
);
