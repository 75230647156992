import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { treeConfigSelector } from '../../../store/selectors/tree/data';
import { selectedTreeNodeKeysByTreeIdSelector } from '../../../store/selectors/tree/node';
import HoverActions from '.';
import { TreeCatalogs, selectSortedCatalogs } from '../../../store/slices/tree';
import { NodeBase } from '../../../entities/Node';
import type { RootState } from '../../../store';

export default () =>
  (BaseComponent: React.ComponentType<{ node: NodeBase; hover?: boolean }>) => {
    const WithHoverActions = function WithHoverActions(props: {
      node: NodeBase;
      hover?: boolean;
    }) {
      const { node, hover = true } = props;
      const [hasHoverActions, setHasHoverActions] = useState(false);
      const { allowHoverActions } = useSelector((state) =>
        treeConfigSelector(state, node.catalogId),
      );
      const selectedKeys = useSelector<RootState>((state) =>
        selectedTreeNodeKeysByTreeIdSelector(state, node.catalogId),
      ) as string[];

      const sortedCatalogs = useSelector<RootState>((state) =>
        selectSortedCatalogs(state),
      ) as { [key: string]: TreeCatalogs };

      const isActiveSortingCatalog = useMemo(
        () =>
          !Object.keys(sortedCatalogs).length ||
          (sortedCatalogs && sortedCatalogs[node.catalogId]),
        [node, sortedCatalogs],
      );

      useEffect(() => {
        const hasOrNot =
          hover &&
          isActiveSortingCatalog &&
          !!allowHoverActions &&
          (!selectedKeys.includes(node.id) || selectedKeys.length === 1);
        setHasHoverActions(hasOrNot);
      }, [
        hover,
        allowHoverActions,
        node,
        selectedKeys,
        setHasHoverActions,
        isActiveSortingCatalog,
      ]);

      return (
        <>
          <BaseComponent {...props} />
          {/* @ts-expect-error hover actions not typed */}
          {hasHoverActions && <HoverActions node={node} />}
        </>
      );
    };

    return WithHoverActions;
  };
