import { createSlice } from '@reduxjs/toolkit';
import { without } from '../../../utils/object';
import type { RootState } from '../..';

const initialState = {};
const prestateSlice = createSlice({
  name: 'prestate',
  initialState,
  reducers: {
    depopulate(state, action) {
      const { properties = [] } = action.payload;
      return properties.length ? without(state, ...properties) : state;
    },
    populate(state, action) {
      const { selectedNode, sourceTreeState, targetTreeState } =
        action.payload || {};
      if (
        sourceTreeState ||
        targetTreeState ||
        (selectedNode?.catalogId && selectedNode?.path)
      ) {
        return {
          selectedNode,
          sourceTreeState,
          targetTreeState,
        };
      }
      return initialState;
    },
  },
});

export const { depopulate, populate } = prestateSlice.actions;
export default prestateSlice.reducer;

export const selectPrestate = (state: RootState) => state.prestate;
