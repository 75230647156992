import { ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import {
  CATALOG_STATUS_DRAFT,
  CATALOG_STATUS_DRAFT_CREATE_IN_PROGRESS,
} from '../../../utils/statuses/catalogStatus';
import { CATALOG_TYPE_MISSINGITEMS } from '../../../utils/types/catalogTypes';
import { selectDraftStatus } from '.';
import { getDraftStatus } from '../../actions/api/catalogs';
import { fetchCatalog } from '../../actions/catalog/fetchCatalogs';

export const draftStatusReceivedListenerOptions = {
  actionCreator: getDraftStatus.fulfilled,
  effect: async (
    _: UnknownAction,
    listenerApi: ListenerEffectAPI<never, ThunkDispatch<never, never, never>>,
  ) => {
    const { dispatch, getState } = listenerApi;
    const draftStatus = selectDraftStatus(getState());
    if (
      draftStatus?.previousStatus === CATALOG_STATUS_DRAFT_CREATE_IN_PROGRESS &&
      draftStatus?.status === CATALOG_STATUS_DRAFT
    ) {
      await dispatch(fetchCatalog(draftStatus.catalogId));
      await dispatch(fetchCatalog(CATALOG_TYPE_MISSINGITEMS));
    }
  },
};
