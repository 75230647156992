import { arrayify } from '../../../utils/array';
import { toNotValidForStructureNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import { toReasonGroupNode } from '../../../utils/tree/nodeMappers/toReasonGroupNode';
import { getTreeFromFlatData } from '../../../utils/tree/tree-data-utils';
import { smallBannerNode } from '../../../utils/tree/virtualNodes';
import { getNotValidForStructureItems } from '../api/items';
import { updateData } from '../tree';

export const fetchNotValidForStructureItems =
  (tree, catalogId, pageNo) => async (dispatch) => {
    const { content, last, number, totalElements } = await dispatch(
      getNotValidForStructureItems({ pageNo }),
    ).unwrap();
    const groups = arrayify(content).reduce((mapped, item) => {
      const { structureReasonText } = item;
      const group = structureReasonText || 'No reason';
      const subItems = mapped[group] || [];
      subItems.push(item);
      return Object.assign(mapped, { [group]: subItems });
    }, {});
    const flatData = Object.entries(groups).reduce(
      (data, [reason, notValidForStructureItems]) => {
        const reasonGroup = toReasonGroupNode({
          catalogId,
          childItemCount: notValidForStructureItems.length,
          reason,
          expanded: false,
        });
        data.push(
          reasonGroup,
          ...toNotValidForStructureNodes(reasonGroup.id, notValidForStructureItems),
        );
        return data;
      },
      [
        smallBannerNode({
          level: 0,
          parentId: '0',
          bannerId: 'reasonCodes',
          catalogId,
        }),
      ],
    );
    const notValidForStructureNodes = getTreeFromFlatData({ flatData });
    return dispatch(
      updateData(tree, notValidForStructureNodes, {
        meta: {
          lastPage: last,
          pageNumber: number,
          totalNumberOfElements: totalElements,
        },
      }),
    );
  };
