import { useSelector } from 'react-redux';
import IkeaLogo from '../../IkeaLogo/IkeaLogo';
import styles from './ApplicationName.module.scss';
import {
  selectCurrentLanguageCode,
  selectCurrentRetailUnit,
} from '../../../store/slices/intl';
import { Link } from 'react-router-dom';

const ApplicationName = function ApplicationName() {
  const retailUnit = useSelector(selectCurrentRetailUnit);
  const languageCode = useSelector(selectCurrentLanguageCode);

  return (
    <Link
      className={styles.componentwrapper}
      to={`/${retailUnit || 'ZZ'}/${languageCode || 'en'}`}
    >
      <IkeaLogo />
      <div className={styles.textwrapper}>
        <h1 className={styles.title}>Kategorisera</h1>
        <span className={styles.byline}>Handling of the range structure</span>
      </div>
    </Link>
  );
};

export default ApplicationName;
