import {
  getNodeInTree,
  insertNodeAfter,
  updateNodeInTree,
} from '../../../components/Tree/helpers';
import { createNewNode } from '../../../utils/tree/node';
import { NODE_TYPE_CATEGORY } from '../../../utils/types/nodeTypes';
import { selectDraftProductsCatalog } from '../../selectors/catalog/draft';
import { newCategoryEnabledSelector } from '../../selectors/category/ui';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { treeIdByCatalogIdSelector } from '../../selectors/tree/data';
import { selectedTreeNodeSelector } from '../../selectors/tree/node';
import { selectNode } from '../nodes/selectNode';
import { togglePane } from '../pane';
import { updateData } from '../tree';

export const createNewCategory = () => async (dispatch, getState) => {
  const state = getState();
  const { catalogId, nodeItem } = selectedTreeNodeSelector(state);
  const tree = treeIdByCatalogIdSelector(state, catalogId);

  if (!newCategoryEnabledSelector(state, tree)) {
    return undefined;
  }

  const draftCatalog = selectDraftProductsCatalog(state);
  const globalPublishDate =
    catalogId === draftCatalog?.catalogId ? draftCatalog?.activationDate : undefined;
  const newCategory = createNewNode(
    nodeItem.node.catalogId,
    'NEW-CATEGORY',
    NODE_TYPE_CATEGORY,
    {
      category: {
        childCategoryCount: 0,
        childItemCount: 0,
        globalPublishDate,
      },
      isLeaf: true,
      isNew: true,
      isUnpublished: true,
      level: nodeItem.node.level + 1,
      parentId: nodeItem.node.id,
      title: '',
    },
    nodeItem.node.id,
  );

  const currentTreeData = updateNodeInTree({
    node: { ...nodeItem.node, isLeaf: false, expanded: true },
    path: nodeItem.path,
    treeData: selectTreeDataByTreeId(getState(), tree),
  });
  const result = insertNodeAfter({
    afterNodeItem: nodeItem,
    newNode: newCategory,
    treeData: currentTreeData,
  });
  await dispatch(updateData(tree, result.treeData));
  await dispatch(selectNode(tree, getNodeInTree(result)));
  return dispatch(togglePane(true));
};
