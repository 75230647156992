import { useCallback } from 'react';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { openMarketSelector } from '../../../store/actions/modal/openMarketSelector';
import { Globe } from '../../Icons/paths';
import styles from './MarketSelectorButton.module.scss';
import { selectCurrentRetailCode } from '../../../store/slices/intl';

const MarketSelectorButton = function MarketSelectorButton() {
  const dispatch = useDispatch();
  const market = useSelector(selectCurrentRetailCode);
  const onClick = useCallback(() => dispatch(openMarketSelector()), [dispatch]);

  return (
    <Tooltip interactiveCustomTrigger tooltipText="Select market" position="bottom">
      <Button
        className={styles.button}
        onClick={onClick}
        aria-label="Opens market selector dialog"
        ssrIcon={Globe}
        text={market}
        size="small"
        type="tertiary"
      />
    </Tooltip>
  );
};

export default MarketSelectorButton;
