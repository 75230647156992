import { z } from 'zod';
import { CategorySchema } from './categories';

export const CatalogSchema = z.object({
  availableForMarkets: z.boolean(),
  activationDate: z.string().nullable(),
  catalogueId: z.number().transform((val) => `${val}`),
  catalogueIdentifierTranslations: z.record(z.string(), z.string()),
  catalogueName: z.string(),
  catalogueType: z.string(),
  catalogueStatus: z.string(),
  createdUser: z.string().nullable(),
  globalCatalogueIdentifier: z.string(),
  globalCatalogueName: z.string(),
  registeredDate: z.string(),
  updatedDate: z.string().optional(),
  lastAction: z.string().optional(),
  updatedUser: z.string().nullable().optional(),
  topCategories: z.array(z.number().transform((val) => `${val}`)).optional(),
});

export const CatalogsSchema = z.array(CatalogSchema);

export const CatalogCategoriesSchema = z.object({
  catalogue: CatalogSchema,
  categories: z.array(
    CategorySchema.pick({
      categoryId: true,
      childItemCount: true,
      externalCategoryId: true,
      globalCategoryName: true,
      newCategory: true,
      publishState: true,
      translateState: true,
      mainParentCategoryId: true,
      categoryType: true,
    }).extend({
      autoCategorisable: z.boolean().nullable(),
      categoryNameTranslation: z.string().nullable(),
      childItems: z.array(z.string()).optional(),
      childCategories: z.array(
        z
          .number()
          .transform((val) => `${val}`)
          .nullable(),
      ),
    }),
  ),
});

export type CatalogCategories = z.infer<typeof CatalogCategoriesSchema>;

export const DraftCatalogSchema = z.object({
  catalogueId: z.number().nullable(),
  hasDraft: z.boolean(),
  status: z.string().nullable(),
});

export const ToBeDeletedSchema = z.array(
  z.object({
    categoryId: z.number().transform((val) => `${val}`),
    categoryName: z.string(),
    externalCategoryId: z.string(),
  }),
);
