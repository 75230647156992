class CopyPasteNodeError extends Error {
  constructor(message) {
    super(message);

    this.name = 'CopyPasteNodeError';

    Object.setPrototypeOf(this, CopyPasteNodeError.prototype);
  }
}

export default CopyPasteNodeError;
