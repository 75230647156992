import { createAsyncThunk } from '@reduxjs/toolkit';
import { toBeDeletedStatus } from '../api/catalogs';
import { selectIsGlobalMarket } from '../../slices/intl';
import { selectIsEditorForCurrentMarket } from '../../selectors/roles';

export const fetchCatalogRemovalInfo = createAsyncThunk(
  'catalog/fetchCatalogRemovalInfo',
  async ({ catalogId }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state = getState();
    if (!selectIsGlobalMarket(state) || !selectIsEditorForCurrentMarket(state)) {
      return undefined;
    }
    const categories = await dispatch(toBeDeletedStatus({ catalogId })).unwrap();
    return { catalogId, categories };
  },
);
