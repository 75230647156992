import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createComparer } from '../../../utils/object';
import { setMarket } from '../../actions/intl';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';
import { Category } from '../../../entities/Category';
import type { RootState } from '../../index';

const categoryEntityAdapter = createEntityAdapter<Category, string>({
  selectId: (category) => category.categoryId,
  sortComparer: createComparer('globalCategoryName'),
});

const categorySlice = createSlice({
  name: 'category',
  initialState: categoryEntityAdapter.getInitialState(),
  reducers: {
    categoryReceived(state, action: PayloadAction<Category>) {
      categoryEntityAdapter.setOne(state, action.payload);
    },
  },
  extraReducers: (builder) =>
    mergeStateReducerBuilder('state.category', builder).addCase(setMarket, () =>
      categoryEntityAdapter.getInitialState(),
    ),
});

export const { categoryReceived } = categorySlice.actions;
export default categorySlice.reducer;

export const { selectAll: selectCategories, selectById: selectCategory } =
  categoryEntityAdapter.getSelectors((state: RootState) => state.category);
