import Badge from './Badge';
import styles from './StatusBadges.module.scss';

export const SummaryCount = function SummaryCount({
  compact,
  count,
}: {
  compact: boolean;
  count: number;
}) {
  return (
    <Badge
      className={styles.summaryInverted}
      compact={compact}
      label={`${count} actions required`}
      labelCompact={count.toString()}
      tooltip="Actions required"
    />
  );
};
