import { findNodes, removeNodesFromTree } from '../../../components/Tree/helpers';
import { selectTreeDataByTreeId } from '../../selectors/tree';
import { updateData } from '.';

export const removeTreeControlsOfType =
  (tree, type) => async (dispatch, getState) => {
    const state = getState();
    const currentTreeData = selectTreeDataByTreeId(state, tree);
    const paths = findNodes({
      treeData: currentTreeData,
      searchMethod: (ni) => ni.node.type === type,
    }).matches.map((m) => m.path);
    const { treeData } = removeNodesFromTree({ paths, treeData: currentTreeData });
    return dispatch(updateData(tree, treeData));
  };
