import { destructId, getPathById } from '../../../utils/tree/treeNode';
import { depopulate, populate } from '../prestate';

export const prePopulateTreeState = ({
  selectedNodeId,
  sourceTreeState,
  targetTreeState,
}) => {
  const path = selectedNodeId && getPathById(selectedNodeId);
  const catalogId = path && destructId(path[0]).catalogId;
  const selectedNode = path && catalogId ? { catalogId, path } : undefined;
  return populate({
    selectedNode,
    sourceTreeState,
    targetTreeState,
  });
};

export const clearPopulateTreeState = (...properties) => depopulate({ properties });
