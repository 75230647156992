import styles from '../../components/Tree/CommonTree/CommonTree.module.scss';
import { noOperation } from '../function';
import {
  NODE_TYPE_MULTI_SELECT,
  NODE_TYPE_SMALL_BANNER_NODE,
} from '../types/nodeTypes';
import { createNewNode } from './node';

const virtualNode = ({
  catalogId,
  onClick = noOperation,
  parentId,
  type,
  ...rest
}) =>
  createNewNode(
    catalogId,
    type,
    type,
    {
      isLeaf: true,
      ...rest,
      onClick,
      parentId,
    },
    parentId,
  );

export const actionsNode = (props) => {
  const type = NODE_TYPE_MULTI_SELECT;
  return virtualNode({
    role: 'command',
    title: 'Actions node',
    type,
    className: styles.multiSelect,
    ...props,
  });
};

export const smallBannerNode = (props) => {
  const type = NODE_TYPE_SMALL_BANNER_NODE;
  return virtualNode({
    role: 'note',
    title: 'Banner',
    type,
    ...props,
    style: {
      backgroundColor: 'rgb(var(--colour-neutral-3))',
      height: 28,
    },
  });
};
