import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class CollectionsTreeConfig extends DefaultTreeConfig {
  constructor(props: TreeConfigProps) {
    super(props);

    this.categoryNameEditable = this.editable;
    this.newCategory = this.editable;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowItemOperations =
      props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.allowCopyCategory = this.editable;
    this.allowRanking = !props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.moveDisabled = false;
  }
}
