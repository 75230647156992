import React from 'react';
import ExternalLink from '../../../../ExternalLink';

const ReasonCodes = function ReasonCodes() {
  return (
    <>
      {'Read about '}
      <ExternalLink
        href="https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=164955541"
        label="Reason codes"
        text="Reason codes"
      />
    </>
  );
};

export default ReasonCodes;
