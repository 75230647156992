import Button from '@ingka/button';
import styles from './Header.module.scss';
import { PANE_TARGETTREE } from '../../store/constants/panes';
import { matchRoutes, useLocation } from 'react-router-dom';
import { REPORTS_SKIP_TO } from '../Reports/constants';
import { REPORT_ROUTES } from '../../routes';

export const SkipToMain = () => {
  const location = useLocation();
  const isReportRoute = !!matchRoutes(REPORT_ROUTES, location);

  const skipToMain = () => {
    if (isReportRoute) {
      const target = document.getElementById(REPORTS_SKIP_TO);

      return target?.focus();
    }

    const target = document.getElementById(PANE_TARGETTREE);
    if (target) {
      const button = target.querySelector(
        `#${PANE_TARGETTREE}-button`,
      ) as HTMLButtonElement;
      button?.focus();
    }
  };
  return (
    <Button
      aria-label="Skip to main content"
      onClick={skipToMain}
      type="primary"
      className={styles.skip}
      text="Skip to main content"
    />
  );
};
