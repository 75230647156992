import { selectTreeDataByCatalogId } from '../../selectors/tree';
import { updateCategoryStatusFromTreeData } from '../indicator';

export const updateCategoryStatusByCatalogId =
  (catalogId) => async (dispatch, getState) => {
    const state = getState();
    const treeData = selectTreeDataByCatalogId(state, catalogId);

    return dispatch(updateCategoryStatusFromTreeData(treeData, catalogId));
  };
