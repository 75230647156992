import { CATALOG_TYPE_UNCATEGORIZED } from '../../../utils/types/catalogTypes';
import { analyticsEvent } from '../../actions/analytics/analytics';
import { toggleSuggestions } from '../../actions/suggestion/toggleSuggestions';
import { selectCategoriesForPredictions } from '../../selectors/category/predictions';
import { setSource, toggleActivePane } from '.';
import { treeIdByCatalogIdSelector } from '../../selectors/tree/data';

export const paneSetSourceListenerOptions = {
  actionCreator: setSource,
  effect: (action, listenerApi) => {
    const { dispatch, getState } = listenerApi;
    const {
      pane,
      source: { catalogId },
    } = action.payload;
    const state = getState();
    const tree = treeIdByCatalogIdSelector(state, CATALOG_TYPE_UNCATEGORIZED);
    if (tree) {
      const categories = selectCategoriesForPredictions(state);
      const showPredictions = !!categories.size;
      dispatch(toggleSuggestions({ tree, showPredictions }));
    }

    dispatch(
      analyticsEvent({
        category: 'Tree Properties',
        action: 'Switched catalog',
        label: `Switched catalog in pane ${pane} to catalog ${catalogId}`,
      }),
    );
  },
};

export const toggleActivePaneListenerOptions = {
  actionCreator: toggleActivePane,
  effect: (action, listenerApi) => {
    const { dispatch } = listenerApi;
    const { pane } = action.payload;
    dispatch(
      analyticsEvent({
        category: 'Tree Properties',
        action: 'Expand/Contract pane',
        label: `Toggled active pane ${pane}`,
      }),
    );
  },
};
