import { format } from 'date-fns/format';

export const toDateString = (date, asGMT) => {
  const inputDate = new Date(date);
  if (asGMT) {
    return inputDate.toISOString().split('T')[0];
  }
  return format(inputDate, 'yyyy-MM-dd');
};

export const toDateLocaleString = (date) => new Date(date).toLocaleDateString();
export const toDateIsoString = (date) => new Date(date).toISOString().split('T')[0];
export const toDateTimeString = (date) =>
  format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
export const toCETString = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return `${format(new Date(d), 'yyyy-MM-dd HH:mm:ss')} CET`;
};
export const toDatePickerValue = (date, asGMT) => {
  const ticks = Date.parse(date);
  return ticks ? toDateString(ticks, asGMT) : '';
};

export const toJsonValue = (dateStr) => {
  const ticks = Date.parse(dateStr);
  return ticks ? new Date(ticks).toISOString() : null;
};
