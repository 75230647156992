import { z } from 'zod';
import { Category } from '../../entities/Category';
import { getCatalogTypeFromDto } from '../../utils/catalog';
import { STATE_UNPUBLISHED, STATE_VERIFIED } from '../../utils/category/states';
import { getItemId } from '../../utils/item';
import {
  CATALOG_STATUS_DRAFT_ACTIVATION_IN_PROGRESS,
  CATALOG_STATUS_DRAFT_CREATE_IN_PROGRESS,
} from '../../utils/statuses/catalogStatus';
import { CATALOG_TYPE_AUTOMATED } from '../../utils/types/catalogTypes';
import { CategorySchema } from '../../schemas/categories';
import { Catalog } from '../../entities/Catalog';
import { ItemSchema } from '../../schemas/items';
import { CatalogSchema, DraftCatalogSchema } from '../../schemas/catalogs';

export function catalogFromDto({
  catalogueId,
  catalogueIdentifierTranslations,
  catalogueName,
  catalogueStatus,
  catalogueType,
  globalCatalogueIdentifier,
  ...catalog
}: z.infer<typeof CatalogSchema>): Catalog {
  const catalogName =
    catalogueType === CATALOG_TYPE_AUTOMATED
      ? `${catalogueName} (AB Test)`
      : catalogueName;
  return {
    ...catalog,
    catalogId: `${catalogueId}`,
    catalogIdentifierTranslations: catalogueIdentifierTranslations,
    catalogName,
    catalogStatus: catalogueStatus,
    catalogType: getCatalogTypeFromDto({ catalogueType }),
    globalCatalogIdentifier: globalCatalogueIdentifier,
  };
}

export function catalogStatusFromDto({
  catalogueId,
  status,
  hasDraft,
}: z.infer<typeof DraftCatalogSchema>) {
  return {
    catalogId: `${catalogueId}`,
    isProcessingDraftCatalog: [
      CATALOG_STATUS_DRAFT_CREATE_IN_PROGRESS,
      CATALOG_STATUS_DRAFT_ACTIVATION_IN_PROGRESS,
    ].includes(status || ''),
    status,
    hasDraft,
  };
}

export function catalogToDto({
  catalogId,
  catalogIdentifierTranslations,
  catalogName,
  catalogStatus,
  catalogType,
  globalCatalogIdentifier,
  ...catalog
}: Catalog) {
  return {
    catalogueId: Number(catalogId),
    catalogueIdentifierTranslations: catalogIdentifierTranslations,
    catalogueName: catalogName,
    catalogueStatus: catalogStatus,
    catalogueType: catalogType,
    globalCatalogueIdentifier: globalCatalogIdentifier,
    ...catalog,
  };
}

export function categoryFromDto(category: z.infer<typeof CategorySchema>) {
  const dtoFromCategory = category as Category;
  dtoFromCategory.categoryNameTranslationApproved =
    dtoFromCategory.translateState === STATE_VERIFIED;
  dtoFromCategory.published = dtoFromCategory.publishState !== STATE_UNPUBLISHED;

  return dtoFromCategory;
}

export function categoryToDto({
  autoCategorisable,
  categoryComment,
  categoryId,
  categoryNameTranslations,
  categoryNameTranslationApproved,
  externalCategoryId,
  globalCategoryComment,
  globalCategoryName,
  globalCategoryPublished,
  globalPublishDate,
  published,
  mainParentCategoryId,
  categoryType,
}: Category) {
  return {
    autoCategorisable,
    categoryComment,
    categoryId: Number(categoryId),
    categoryNameTranslations,
    categoryNameTranslationApproved,
    externalCategoryId,
    globalCategoryComment,
    globalCategoryName,
    globalCategoryPublished,
    globalPublishDate,
    mainParentCategoryId,
    published,
    categoryType,
  };
}
export type ItemDTO = ReturnType<typeof itemFromDto>;

export function itemFromDto<T>(
  itemDTO: z.infer<typeof ItemSchema>,
  customProps?: T,
) {
  const countrySpecificItemCount =
    itemDTO.countrySpecificItemCount ??
    (itemDTO.itemCountrySpecificList?.length || 0);
  const itemCountrySpecificList = (itemDTO.itemCountrySpecificList || []).map(
    (i) => ({ ...i, itemName: i.itemNameOfficial }),
  );

  return {
    ...itemDTO,
    ...customProps,
    countrySpecificItemCount,
    itemCountrySpecificList,
    itemId: getItemId(itemDTO) || '',
  };
}
