import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useClosestAncestor } from '../../../hooks/useClosestAncestor';
import {
  selectCurrentCommand,
  selectSearchFocusIdForCatalog,
} from '../../../store/selectors/tree';
import { CATEGORIZATION, NODE_COPY, NODE_CUT } from '../../../utils/command';

const classNames = {
  [CATEGORIZATION]: 'rc-tree-treenode-cut',
  [NODE_COPY]: 'rc-tree-treenode-copy',
  [NODE_CUT]: 'rc-tree-treenode-cut',
};

export default () => (BaseComponent) => {
  const WithActionsDirective = function WithActionsDirective(props) {
    const { node } = props;
    const isSearchFocus =
      useSelector((state) =>
        selectSearchFocusIdForCatalog(state, node.catalogId),
      ) === node.id;

    const { directive, nodeItems } = useSelector(selectCurrentCommand);
    const [elementRef, ancestorRef] = useClosestAncestor('.rc-tree-treenode');
    useEffect(() => {
      if (!ancestorRef.current) {
        return;
      }
      ancestorRef.current.classList.remove(
        classNames[NODE_COPY],
        classNames[NODE_CUT],
      );
      if (directive && nodeItems.some((ni) => ni.node.id === node.id)) {
        const className = classNames[directive];
        if (className) {
          ancestorRef.current.classList.add(className);
        }
      }
    }, [ancestorRef, directive, node, nodeItems]);

    useLayoutEffect(() => {
      if (isSearchFocus && elementRef.current) {
        elementRef.current.scrollIntoView();
      }
    }, [isSearchFocus, elementRef]);

    return <BaseComponent ref={elementRef} {...props} />;
  };

  WithActionsDirective.propTypes = {
    node: PropTypes.shape({
      catalogId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  };

  return WithActionsDirective;
};
