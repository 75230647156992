import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../index';

const toasterSlice = createSlice({
  name: 'toaster',
  initialState: '' as string,
  reducers: {
    toastMessage(_, action: { payload: string }) {
      // We use new String because we want the toast to show
      // even if it has exact same value as the previous toast

      return new String(action.payload).toString();
    },
  },
});

export const { toastMessage } = toasterSlice.actions;
export default toasterSlice.reducer;

export const selectCurrentToastMessage = (state: RootState) => state.toaster;
