import Flags from '../../../components/StatusBadges/mappings';
import { findFirstNode } from '../../../components/Tree/helpers';
import { unique } from '../../../utils/array';
import { VALID_PUBLISHED_STATES } from '../../../utils/category/defines';
import { selectSearchCatalogHits, selectTreeDataByTreeId } from '.';
import {
  STATE_AUTOCATEGORISABLE,
  // STATE_CATEGORY_TYPE_UNDEFINED,
  STATE_INVALID_CATEGORY_IMAGE,
  STATE_NEW,
  STATE_VERIFIED,
} from '../../../utils/category/states';
import { selectCatalogs } from '../catalog';
import { createShallowEqualResultSelector, getCatalogIdFromTreeId } from '../utils';
import {
  selectAutoCategorisable,
  selectCategoryAccessories,
  selectCategoryFurniture,
  selectCategorySystem,
  selectCategoryUndefined,
  selectInvalidImages,
  selectUnpublishedCategories,
  selectUntranslatedCategories,
} from '../../slices/indicator';

export const selectSearchCatalogList = createShallowEqualResultSelector(
  selectSearchCatalogHits,
  selectCatalogs,
  (hits, catalogs) =>
    hits
      .map((catalogId) =>
        catalogs.find((catalog) => catalog.catalogId === catalogId),
      )
      .filter((c) => !!c),
);

export const selectSearchAvailableFlags = createShallowEqualResultSelector(
  selectTreeDataByTreeId,
  selectUnpublishedCategories,
  selectUntranslatedCategories,
  selectAutoCategorisable,
  selectInvalidImages,
  selectCategoryAccessories,
  selectCategorySystem,
  selectCategoryFurniture,
  selectCategoryUndefined,
  getCatalogIdFromTreeId,
  (
    treeData,
    unpublishedCategories,
    untranslatedCategories,
    autoCategorisableCategories,
    invalidImages,
    categoryTypeAccessories,
    categoryTypeSystem,
    categoryTypeFurniture,
    categoryTypeUndefined,
    catalogId,
  ) => {
    const flags = unique(
      [
        ...unpublishedCategories,
        ...untranslatedCategories,
        ...autoCategorisableCategories,
        ...invalidImages,
        ...categoryTypeAccessories,
        ...categoryTypeSystem,
        ...categoryTypeFurniture,
        ...categoryTypeUndefined,
      ]
        .filter((c) => c.catalogId === catalogId)
        .map((c) => c.categoryId),
    ).reduce((categoryStates, categoryId) => {
      const foundNode = findFirstNode({
        treeData,
        searchMethod: (ni) => ni.node?.categoryId === categoryId,
        ignoreCollapsed: false,
      });

      if (!foundNode) return categoryStates;

      const {
        node: { category = null, invalidCategoryImage = null },
      } = foundNode;
      if (!category) {
        return categoryStates;
      }
      if (category.autoCategorisable) {
        categoryStates[STATE_AUTOCATEGORISABLE] =
          (categoryStates[STATE_AUTOCATEGORISABLE] || 0) + 1;
      }

      if (category.categoryType) {
        const type = `CATEGORY_TYPE_${category.categoryType}`;

        categoryStates[type] = (categoryStates[type] || 0) + 1;
      }

      /* Disable undefined categorytype
      if (category.categoryType === null) {
        // eslint-disable-next-line no-param-reassign
        categoryStates[STATE_CATEGORY_TYPE_UNDEFINED] =
          (categoryStates[STATE_CATEGORY_TYPE_UNDEFINED] || 0) + 1;
      }
      */
      const hasChildren =
        !!category?.childCategoryCount || !!category.childItemCount;

      if (invalidCategoryImage && hasChildren) {
        categoryStates[STATE_INVALID_CATEGORY_IMAGE] =
          (categoryStates[STATE_INVALID_CATEGORY_IMAGE] || 0) + 1;
      }

      if (category.newCategory) {
        categoryStates[STATE_NEW] = (categoryStates[STATE_NEW] || 0) + 1;
      }
      if (
        category.publishState &&
        !VALID_PUBLISHED_STATES.includes(category.publishState) &&
        !!Flags[category.publishState]
      ) {
        categoryStates[category.publishState] =
          (categoryStates[category.publishState] || 0) + 1;
      }
      if (category.translateState && category.translateState !== STATE_VERIFIED) {
        categoryStates[category.translateState] =
          (categoryStates[category.translateState] || 0) + 1;
      }

      return categoryStates;
    }, {});
    return flags;
  },
);
