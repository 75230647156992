import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addNodesInTree,
  getNodeInTree,
  updateNodeInTree,
} from '../../../components/Tree/helpers';
import { getPathById } from '../../../utils/tree/treeNode';
import { selectTreeDataByTreeId } from '../../slices/tree';
import { getItem } from '../api/items';
import { updateData } from '../tree';
import { selectSearchableCatalogIds } from '../../selectors/catalog/ids';
import { countrySpecificToItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';
import { NodeBase, NodeBaseItem } from '../../../entities/Node';
import type { RootState } from '../..';

export const fetchItem = createAsyncThunk(
  'tree/fetchItem',
  async ({ tree, node }: { tree: string; node: NodeBase }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      catalogId,
      id,
      item: { itemNo, itemType },
    } = node;
    const path = getPathById(id);

    const searchableCatalogs: string[] = selectSearchableCatalogIds(
      getState() as RootState,
    );

    const isSearchableCatalog = searchableCatalogs.includes(catalogId);

    const { item } = await dispatch(
      getItem({
        catalogId: isSearchableCatalog ? catalogId : '',
        itemNo,
        itemType: itemType || '',
      }),
    ).unwrap();

    const currentTreeData = selectTreeDataByTreeId(getState() as RootState, tree);
    const currentNodeItem = getNodeInTree({
      treeData: currentTreeData,
      path,
    }) as NodeBaseItem;

    const { itemCountrySpecificList, ...newItem } = item;

    let treeData = updateNodeInTree({
      node: {
        ...currentNodeItem.node,
        item: { ...currentNodeItem.node.item, ...newItem },
      },
      path,
      treeData: currentTreeData,
    }) as NodeBase[];

    if (!isSearchableCatalog && itemCountrySpecificList?.length) {
      const childNodes = countrySpecificToItemNodes({
        catalogId,
        isLeaf: true,
        items: itemCountrySpecificList,
        level: node.level + 1,
        parentId: node.id,
      });

      treeData = addNodesInTree(childNodes, treeData).treeData;
    }

    return dispatch(updateData(tree, treeData, { selectNodeAtPath: path }));
  },
);
