import { ENVIRONMENT_STAMP } from '../../utils/env';
import styles from './Header.module.scss';
import textStyles from './ApplicationName/ApplicationName.module.scss';
import IkeaLogo from '../IkeaLogo/IkeaLogo';

const style = ENVIRONMENT_STAMP
  ? { backgroundColor: 'rgb(var(--color-environment-stamp))' }
  : undefined;

const FakeHeader = () => (
  <a href={window.location.href}>
    <div
      className={styles.headerComponent}
      style={{ ...style, justifyContent: 'flex-start' }}
    >
      <IkeaLogo />
      <div className={textStyles.textwrapper}>
        <h1 className={textStyles.title}>Kategorisera</h1>
        <span className={textStyles.byline}>Handling of the range structure</span>
      </div>
    </div>
  </a>
);

export default FakeHeader;
