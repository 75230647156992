import { createSlice } from '@reduxjs/toolkit';
import { arrayify } from '../../../utils/array';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import {
  STATE_AUTOCATEGORISABLE,
  STATE_CATEGORY_TYPE_ACCESSORIES,
  STATE_CATEGORY_TYPE_FURNITURE,
  // STATE_CATEGORY_TYPE_SYSTEM,
  // STATE_CATEGORY_TYPE_UNDEFINED,
  STATE_INVALID_CATEGORY_IMAGE,
  STATE_UNPUBLISHED,
  STATE_UNTRANSLATED,
} from '../../../utils/category/states';
import { ReadOnlyObject } from '../../../utils/object/ReadOnlyObject';
import { getCategoryStatus } from '../../../utils/tree/getCategoryStatus';
import { getCountedRelations } from '../../../utils/tree/path';
import { setMarket } from '../../actions/intl';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';
import {
  createReferenceEqualSelector,
  getIdFromArgument,
} from '../../selectors/utils';
import type { RootState } from '../..';
import { NodeBase } from '../../../entities/Node';

interface IndicatorState {
  categoryStatus: {
    [key: string]: unknown;
  };
  unpublishedCategories: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  untranslatedCategories: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  autoCategorisable: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  invalidCategoryImages: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  categoryTypeFurniture: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  categoryTypeSystem: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  categoryTypeAccessories: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
  categoryTypeUndefined: {
    catalogId: string;
    categoryId: string;
    paths: string[];
  }[];
}

const initialState: IndicatorState = {
  categoryStatus: {},
  unpublishedCategories: [],
  untranslatedCategories: [],
  autoCategorisable: [],
  categoryTypeAccessories: [],
  categoryTypeFurniture: [],
  categoryTypeSystem: [],
  categoryTypeUndefined: [],
  invalidCategoryImages: [],
};
const indicatorSlice = createSlice({
  name: 'indicator',
  initialState,
  reducers: {
    updateCategoryStatusFromTreeData: {
      prepare(treeData: NodeBase[], catalogId: string) {
        return {
          meta: null,
          error: null,
          payload: {
            catalogId,
            ...getCategoryStatus(treeData),
          },
        };
      },
      reducer(state: IndicatorState, action) {
        const {
          catalogId,
          unpublishedCategories,
          untranslatedCategories,
          autoCategorisable,
          categoryTypeSystem,
          categoryTypeFurniture,
          categoryTypeAccessories,
          // categoryTypeUndefined,
          invalidCategoryImages,
        } = action.payload;

        state.categoryStatus[catalogId] = {
          [STATE_UNPUBLISHED]: getCountedRelations(unpublishedCategories),
          [STATE_UNTRANSLATED]: getCountedRelations(untranslatedCategories),
          [STATE_AUTOCATEGORISABLE]: getCountedRelations(autoCategorisable),
          [STATE_CATEGORY_TYPE_ACCESSORIES]: getCountedRelations(
            categoryTypeAccessories,
          ),
          [STATE_CATEGORY_TYPE_FURNITURE]:
            getCountedRelations(categoryTypeFurniture),
          /* Disable undefined categorytype
          [STATE_CATEGORY_TYPE_SYSTEM]: getCountedRelations(categoryTypeSystem),
          [STATE_CATEGORY_TYPE_UNDEFINED]:
            getCountedRelations(categoryTypeUndefined),
          */
          [STATE_INVALID_CATEGORY_IMAGE]: getCountedRelations(invalidCategoryImages),
        };
        state.unpublishedCategories = state.unpublishedCategories
          .filter((c) => c.catalogId !== catalogId)
          .concat(unpublishedCategories);
        state.untranslatedCategories = state.untranslatedCategories
          .filter((c) => c.catalogId !== catalogId)
          .concat(untranslatedCategories);

        state.autoCategorisable = state.autoCategorisable
          .filter((c) => c.catalogId !== catalogId)
          .concat(autoCategorisable);

        state.invalidCategoryImages = state.invalidCategoryImages
          .filter((c) => c.catalogId !== catalogId)
          .concat(invalidCategoryImages);

        state.categoryTypeAccessories = state.categoryTypeAccessories
          .filter((c) => c.catalogId !== catalogId)
          .concat(categoryTypeAccessories);

        state.categoryTypeFurniture = state.categoryTypeFurniture
          .filter((c) => c.catalogId !== catalogId)
          .concat(categoryTypeFurniture);

        state.categoryTypeSystem = state.categoryTypeSystem
          .filter((c) => c.catalogId !== catalogId)
          .concat(categoryTypeSystem);

        /* Disable undefined categorytype
        state.categoryTypeUndefined = state.categoryTypeUndefined
          .filter((c) => c.catalogId !== catalogId)
          .concat(categoryTypeUndefined);
        */
      },
    },
  },
  extraReducers: (builder) =>
    mergeStateReducerBuilder('state.indicator', builder).addCase(setMarket, () => ({
      ...initialState,
    })),
});

export const { updateCategoryStatusFromTreeData } = indicatorSlice.actions;
export default indicatorSlice.reducer;

const createIndicatorSelector = (state: RootState) => state.indicator;

export const selectCategoriesStatusByCatalogId = createReferenceEqualSelector(
  createIndicatorSelector,
  getIdFromArgument,
  (state, catalogId) => state.categoryStatus[catalogId] || ReadOnlyObject.Empty,
);

export const selectUnpublishedCategories = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.unpublishedCategories;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectUnpublishedCategoriesByCategoryId = createReferenceEqualSelector(
  createIndicatorSelector,
  getIdFromArgument,
  (state, categoryId) =>
    arrayify(state.unpublishedCategories).filter((c) => c.categoryId === categoryId),
);

export const selectUntranslatedCategories = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.untranslatedCategories;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectAutoCategorisable = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.autoCategorisable;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectInvalidImages = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.invalidCategoryImages;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectCategoryAccessories = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.categoryTypeAccessories;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);
export const selectCategorySystem = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.categoryTypeSystem;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectCategoryFurniture = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.categoryTypeFurniture;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectCategoryUndefined = createReferenceEqualSelector(
  createIndicatorSelector,
  (state) => {
    const categories = state.categoryTypeUndefined;
    return categories.length ? categories : ReadOnlyArray.Empty;
  },
);

export const selectUntranslatedCategoriesByCategoryId = createReferenceEqualSelector(
  createIndicatorSelector,
  getIdFromArgument,
  (state, categoryId) =>
    arrayify(state.untranslatedCategories).filter(
      (c) => c.categoryId === categoryId,
    ),
);
