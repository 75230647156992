import { ItemDTO } from '../../../store/mappers/dtoMappers';
import { CatalogCategoriesFromDTO } from '../../../store/mappers/treeDto';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';
import { warn } from '../../../utils/log';
import { toCatalogNode } from '../../../utils/tree/nodeMappers/toCatalogNode';
import {
  CategoryNode,
  toCategoryNodes,
} from '../../../utils/tree/nodeMappers/toCategoryNodes';
import { toItemNodes } from '../../../utils/tree/nodeMappers/toItemNodes';

export function makeTreeData(
  catalog: CatalogCategoriesFromDTO['catalog'],
  categories: CatalogCategoriesFromDTO['categories'],
  languageCode: string,
  invalidCategoryImages: string[],
  items?: { [key: string]: ItemDTO },
) {
  const catalogNode = toCatalogNode({
    catalog,
    expanded: true,
    languageCode,
  });
  const topCategories = getTopCategories(catalog, categories);

  const topNodeConfiguration = {
    catalogId: catalogNode.catalogId,
    categories: topCategories,
    languageCode,
    level: 1,
    parentId: catalogNode.id,
    invalidCategoryImages,
  };
  const topCategoryNodes = toCategoryNodes(topNodeConfiguration);
  const childCategoryNodes = makeSubcategoryNodes(
    categories,
    languageCode,
    invalidCategoryImages,
    topCategoryNodes,
    items,
  );

  catalogNode.children = childCategoryNodes;
  return [catalogNode];
}

function getTopCategories(
  catalog: CatalogCategoriesFromDTO['catalog'],
  categories: CatalogCategoriesFromDTO['categories'],
) {
  return (catalog?.topCategories || ReadOnlyArray.Empty)
    .filter((categoryId: string) => {
      if (categories[categoryId]) {
        return true;
      }
      // @ts-expect-error warn not typed
      warn('Missing topCategory with id:', categoryId);
      return false;
    })
    .map((categoryId: string) => categories[categoryId]);
}

function getChildCategories(
  categories: CatalogCategoriesFromDTO['categories'],
  categoryId: string,
) {
  return categories[categoryId].childCategories
    .filter((childId) => {
      if (!childId) return false;

      if (categories[childId]) {
        return true;
      }
      // @ts-expect-error warn not typed
      warn('Missing childCategory with id:', childId);
      return false;
    })
    .map((childId) => categories[childId!]);
}

function makeSubcategoryNodes(
  categories: CatalogCategoriesFromDTO['categories'],
  languageCode: string,
  invalidCategoryImages: string[],
  categoryNodes?: CategoryNode[],
  items?: { [key: string]: ItemDTO },
) {
  if (!categoryNodes) return [];
  return categoryNodes.map((categoryNode) => {
    const subNodeConfiguration: {
      catalogId: string;
      languageCode: string;
      level: number;
      parentId: string;
      invalidCategoryImages: string[];
      categories?: ReturnType<typeof getChildCategories>;
      items?: ItemDTO[];
    } = {
      catalogId: categoryNode.catalogId,
      languageCode,
      level: categoryNode.level + 1,
      parentId: categoryNode.id,
      invalidCategoryImages,
    };
    const childCategories = getChildCategories(categories, categoryNode?.categoryId);

    if (childCategories.length) {
      subNodeConfiguration.categories = childCategories;
      const childCategoryNodes = toCategoryNodes(subNodeConfiguration);
      categoryNode.children.splice(
        0,
        categoryNode.children.length,
        ...makeSubcategoryNodes(
          categories,
          languageCode,
          invalidCategoryImages,
          childCategoryNodes,
          items,
        ),
      );
    }
    const childItems = getChildItems(categories, categoryNode.categoryId, items);

    if (childItems?.length) {
      subNodeConfiguration.items = childItems;
      const childItemNodes = toItemNodes(subNodeConfiguration);
      categoryNode.children.splice(
        0,
        categoryNode.children.length,
        ...childItemNodes,
      );
    }

    return categoryNode;
  });
}

function getChildItems(
  categories: CatalogCategoriesFromDTO['categories'],
  categoryId: string,
  items?: { [key: string]: ItemDTO },
) {
  const childItems = categories[categoryId]?.childItems;
  if (!items || !childItems) return [];

  return childItems
    .filter((itemId) => {
      if (items[`${itemId}`]) {
        return true;
      }
      // @ts-expect-error warn not typed
      warn('Missing item with id:', itemId);
      return false;
    })
    .map((itemId) => items[`${itemId}`]);
}
