export const ANALYTICS_PROPERTIES_PANE = 'Properties pane';

export const ANALYTICS_MARKET_DATES = 'Market dates';

export const ANALYTICS_CATEGORY_IMAGES = 'Category images';

export const ANALYTICS_EXTERNAL_LINK = 'External links';

export const ANALYTICS_USER_PROFILE = 'User profile';

export const ANALYTICS_MARKET = 'Market';

export const ANALYTICS_SEARCH = 'Search';

export const ANALYTICS_TREE = 'Tree';

export const ANALYTICS_IMAGE_OVERRIDES = 'Image overrides';

export const ANALYTICS_AUTOCATEGORIZED = 'Auto categorized';

export const ANALYTICS_MISSING_IMAGES = 'Missing images';

export const ANALYTICS_NOT_VALID_FOR = 'Not valid for structure';

export const ANALYTICS_AB_CATALOG = 'AB catalog';
export const ANALYTICS_DRAFT_CATALOG = 'Draft catalog';

export const ANALYTICS_SERIES_COLLECTIONS = 'Series and Collections';
