import {
  getNodeInTree,
  removeNodesFromTree,
  updateNodeInTree,
  walkTree,
} from '../../components/Tree/helpers';
import { NODE_TYPE_DATE_GROUP, NODE_TYPE_SUGGESTION } from '../types/nodeTypes';

export function updateDateGroups(treeData) {
  const remove = [];
  const update = [];
  const callback = (nodeItem) => {
    if (nodeItem.node.type === NODE_TYPE_DATE_GROUP) {
      if (!nodeItem.node.children.length) {
        remove.push(nodeItem.path);
      } else if (
        nodeItem.node.group.childItemCount !== nodeItem.node.children.length
      ) {
        update.push(nodeItem.path);
      }
    }
    return true;
  };

  walkTree({
    treeData,
    callback,
  });

  const newTreeData = update.reduce(
    (td, path) => {
      const { node } = getNodeInTree({ treeData: td, path });
      return updateNodeInTree({
        node: {
          ...node,
          group: {
            childItemCount: node.children.reduce(
              (sum, child) =>
                sum +
                (child.type === NODE_TYPE_SUGGESTION
                  ? child.group.childItemCount
                  : 1),
              0,
            ),
          },
        },
        path,
        treeData: td,
      });
    },
    removeNodesFromTree({ paths: remove, treeData }).treeData,
  );

  return newTreeData;
}
