import React from 'react';
import Button from '@ingka/button';
import { useComponentId } from '../../../hooks/useComponentId';
import SSRIcon from '@ingka/ssr-icon';

interface SearchPanelButtonProps {
  disabled: boolean;
  iconPath: (prefix?: string) => React.SVGProps<SVGElement>[];
  label: string;
  Icon: React.ComponentType<unknown>;
  onClick: () => void;
}

const SearchPanelButton = ({
  label,
  iconPath,
  disabled,
  ...props
}: SearchPanelButtonProps) => {
  const id = useComponentId();
  return (
    <Button
      size="small"
      iconOnly
      type="plain"
      disabled={disabled}
      id={id}
      aria-label={label}
      tabIndex={0}
      {...props}
    >
      {[<SSRIcon paths={iconPath} key={`icon-${label}`} />]}
    </Button>
  );
};

export default SearchPanelButton;
