import { isAnyOf, ListenerEffectAPI, PayloadAction } from '@reduxjs/toolkit';
import { isUnpublished, isUntranslated } from '../../../utils/category';
import { extend } from '../../../utils/object';
import { makeTreeId } from '../../../utils/tree/ids';
import { getCategoryTitle } from '../../../utils/tree/treeNode';
import {
  createCategory,
  getCategory,
  updateCategory,
} from '../../actions/api/categories';
import { categoryReceived } from '../../actions/category';
import { updateCategoryStatusByCatalogId } from '../../actions/category/updateCategoryStatus';
import { updatePropsInMatchingNodes } from '../../actions/nodes/updatePropsInMatchingNodes';
import { selectedTreeNodeSelector } from '../../selectors/tree/node';
import { selectCatalogs } from '../catalog';
import {
  selectUnpublishedCategoriesByCategoryId,
  selectUntranslatedCategoriesByCategoryId,
} from '../indicator';
import type { AppDispatch, RootState } from '../../index';
import { Category } from '../../../entities/Category';

export const categoryReceivedListenerOptions = {
  matcher: isAnyOf(
    createCategory.fulfilled,
    getCategory.fulfilled,
    updateCategory.fulfilled,
  ),
  effect: async (
    action: PayloadAction<{
      catalogId: string;
      category: Category;
      languageCode: string;
    }>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  ) => {
    const { dispatch, getState } = listenerApi;
    const state = getState();
    const { catalogId, category, languageCode } = action.payload;
    const selectedNodeItem = selectedTreeNodeSelector(state);
    await dispatch(
      updatePropsInMatchingNodes(
        makeTreeId(null, catalogId),
        extend(
          {
            category,
            title: getCategoryTitle(category, languageCode),
          },
          { isDirty: false },
        ),
        (ni: { node: { categoryId: string } }) =>
          ni.node.categoryId && ni.node.categoryId === category.categoryId,
        selectedNodeItem?.nodeItem?.path,
      ),
    );
    dispatch(categoryReceived(category));
    const unpublishedStatus = isUnpublished(category);
    const untranslatedState = isUntranslated(category);
    const unpublishedCats = selectUnpublishedCategoriesByCategoryId(
      state,
      category.categoryId,
    ) as { catalogId: string }[];
    const untranslatedCats = selectUntranslatedCategoriesByCategoryId(
      state,
      category.categoryId,
    ) as { catalogId: string }[];

    if (
      !!unpublishedCats.length !== unpublishedStatus ||
      !!untranslatedCats.length !== untranslatedState
    ) {
      const catalogIds =
        unpublishedCats.length || untranslatedCats.length
          ? [
              ...unpublishedCats.map((c) => c.catalogId),
              ...untranslatedCats.map((c) => c.catalogId),
            ]
          : selectCatalogs(state).map((c) => c.catalogId);
      catalogIds.forEach((catId) =>
        dispatch(updateCategoryStatusByCatalogId(catId)),
      );
    }
  },
};
