import { removeTrailingSlashes } from './string';

export const BUILD_VERSION = `Software release: ${
  import.meta.env.VITE_COMMIT_SHORT_SHA
}`;
export const ENVIRONMENT_STAMP = import.meta.env.VITE_ENVIRONMENT_STAMP;
export const IS_DEV =
  ENVIRONMENT_STAMP === 'staging' || ENVIRONMENT_STAMP === 'development';

export const CENTRUM_URL = removeTrailingSlashes(import.meta.env.VITE_CENTRUM_API);

export const ENV_PROD = import.meta.env.NODE_ENV === 'production';
export const ENV_DEV = import.meta.env.NODE_ENV === 'development';
export const ENV_TEST = import.meta.env.NODE_ENV === 'test';

export const LOG_LEVEL = import.meta.env.VITE_DEBUG_LEVEL;
