import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { fetchItems } from '../../../../store/actions/item/fetchItems';
import styles from './LoadMoreItems.module.scss';
import { analyticsEvent } from '../../../../store/actions/analytics/analytics';
import { ANALYTICS_TREE } from '../../../../store/actions/analytics/analyticsCategories';
import { NodeBase } from '../../../../entities/Node';
import { AppThunkDispatch } from '../../../../store';
import { Spin } from '../../../Spin/Spin';
import SSRIcon from '@ingka/ssr-icon';
import circlePath from '@ingka/ssr-icon/paths/circlepath-two-triangles';

const LoadMoreItems = function LoadMoreItems({
  node,
  tree,
}: {
  node: NodeBase;
  tree: string;
}) {
  const categoryId = node.category?.categoryId;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [paging, setPaging] = useState({
    pageNo: 0,
    lastPage: false,
  });

  const onClickAction = useCallback(async () => {
    setLoading(true);
    dispatch(
      analyticsEvent({
        category: ANALYTICS_TREE,
        action: 'Load more items clicked',
        label: `Load more items clicked for category ${node.id}`,
      }),
    );

    // expandNode has fetched first page, but if the page is reloaded
    // the node is expanded without fetching page 0
    const pageNo =
      paging.pageNo === 0 && node.children.length > 0 ? 1 : paging.pageNo;

    const pagination = await dispatch(fetchItems({ tree, node, pageNo })).unwrap();

    setPaging({ pageNo: pagination.pageNo + 1, lastPage: pagination.lastPage });
    setLoading(false);
  }, [dispatch, node, tree, paging]);

  if (loading && !node?.children?.length)
    return (
      <div>
        <em>Loading items</em>{' '}
        <Spin spin={loading}>
          <SSRIcon paths={circlePath} />
        </Spin>
      </div>
    );

  return (
    <button
      key={categoryId}
      aria-label="Load more items"
      className={cx(styles.loadMore, { [styles.loading]: loading })}
      disabled={loading || paging.lastPage}
      onClick={onClickAction}
      type="button"
    >
      <span className={styles.text}>
        {`Showing ${node?.children?.length} of ${node?.category?.childItemCount} | Load more items`}
      </span>
      <Spin spin={loading}>
        <SSRIcon width={20} height={20} paths={circlePath} />
      </Spin>
    </button>
  );
};

export default LoadMoreItems;
