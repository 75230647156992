import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';

const auth0Slice = createSlice({
  name: 'auth0',
  initialState: {} as {
    client?: Auth0ContextInterface<User>;
  },
  reducers: {
    setAuthContext: (state, action: PayloadAction<Auth0ContextInterface<User>>) => ({
      ...state,
      client: action.payload,
    }),
  },
});

export const { setAuthContext } = auth0Slice.actions;

export default auth0Slice.reducer;
