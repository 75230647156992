import { BUILD_VERSION } from './env';
import { clear, greet } from './log';

const welcome =
  '\nWelcome to\n\n' +
  ' __  __ _______ _______ _______ _______ _______ ______ _______ _______ _______ ______ _______ \n' +
  '|  |/  |   _   |_     _|    ___|     __|       |   __ \\_     _|     __|    ___|   __ \\   _   |\n' +
  '|     <|       | |   | |    ___|    |  |   -   |      <_|   |_|__     |    ___|      <       |\n' +
  '|__|\\__|___|___| |___| |_______|_______|_______|___|__|_______|_______|_______|___|__|___|___|\n' +
  '                                                                                              \n' +
  '\n\n';

clear();
greet(welcome, BUILD_VERSION);
