import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { extend } from '../../../utils/object';
import { mergeState } from '../../actions/app/state';

function getMergeStateReducer<T = unknown>(path: string) {
  return function mergeStateReducer(state: unknown, action: PayloadAction<T>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const merge = path.split(/\.+/).reduce((obj, p) => obj?.[p], action.payload);
    return merge ? extend(state, merge) : state;
  };
}

export function mergeStateReducerBuilder<T = unknown>(
  path: string,
  builder: ActionReducerMapBuilder<T>,
) {
  return builder.addCase(mergeState, getMergeStateReducer(path));
}
