import React, { useCallback, useMemo, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TreeContext } from '../../../contexts/TreeContext';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NODE_TYPE_MULTI_SELECT,
} from '../../../utils/types/nodeTypes';
import ActionButton from './ActionButton';
import clipboard from '@ingka/ssr-icon/paths/clipboard';
import clipboardStrikethrough from '@ingka/ssr-icon/paths/clipboard-strikethrough';
import copy from '@ingka/ssr-icon/paths/copy';
import scissors from '@ingka/ssr-icon/paths/scissors';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import takeBack from '@ingka/ssr-icon/paths/take-back';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down';

import { useHoverActionsNodeConfig } from './useHoverActionsNodeConfig';
import styles from './HoverActions.module.scss';

const HoverActions = function HoverActions({ node }) {
  const { actions, onHoverOnly, showPaste } = useHoverActionsNodeConfig(node);
  const { setLoading } = useContext(TreeContext);

  const setIsLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);
  const callback = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const rightButtons = useMemo(
    () => getRightButtons(actions, node.id, setIsLoading, callback),
    [node.id, actions, callback, setIsLoading],
  );

  return (
    <>
      <div
        className={cx(styles.container, styles.left, {
          [styles.show]: !onHoverOnly,
        })}
      >
        {!!showPaste && (
          <ActionButton
            iconPath={actions.pasteAction ? clipboard : clipboardStrikethrough}
            onClick={() => {
              setIsLoading(true);
              actions.pasteAction(callback);
            }}
            label="Paste"
          />
        )}
      </div>
      <div
        className={cx(styles.container, styles.right, {
          [styles.show]: !onHoverOnly,
        })}
      >
        {rightButtons.map(({ iconPath, action, label, id }) => (
          <ActionButton
            id={id}
            key={id}
            iconPath={iconPath}
            label={label}
            onClick={action}
          />
        ))}
      </div>
    </>
  );
};

HoverActions.propTypes = {
  node: PropTypes.shape({
    catalogId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      NODE_TYPE_CATALOG,
      NODE_TYPE_CATEGORY,
      NODE_TYPE_ITEM,
      NODE_TYPE_MULTI_SELECT,
    ]).isRequired,
  }).isRequired,
};

export default HoverActions;

function getRightButtons(actions, nodeId, setLoading, callback) {
  const buttons = [];
  const id = (suffix) => `${nodeId}-${suffix}`;
  if (actions.nodeMenuCopy) {
    buttons.push({
      id: id('copy'),
      iconPath: copy,
      action: actions.nodeMenuCopy,
      label: 'Copy',
    });
  }
  if (actions.markForCategorization || actions.nodeMenuCut) {
    buttons.push({
      id: id('cut'),
      iconPath: scissors,
      action: actions.markForCategorization || actions.nodeMenuCut,
      label: 'Cut',
    });
  }
  if (actions.openAlterSuggestionsDialog) {
    buttons.push({
      id: id('take-back'),
      iconPath: takeBack,
      action: actions.openAlterSuggestionsDialog,
      label: 'Alter',
    });
  }
  if (actions.openDialogRemoveItem) {
    buttons.push({
      id: id('remove'),
      iconPath: trashCan,
      action: actions.openDialogRemoveItem,
      label: 'Remove',
    });
  }
  if (actions.nodeMenuMoveUp) {
    buttons.push({
      id: id('move-up'),
      iconPath: arrowUp,
      action: () => {
        setLoading(id('move-up'));
        actions.nodeMenuMoveUp(callback);
      },
      label: 'Move',
    });
  }
  if (actions.nodeMenuMoveDown) {
    buttons.push({
      id: id('move-down'),
      iconPath: arrowDown,
      action: () => {
        setLoading(id('move-down'));
        actions.nodeMenuMoveDown(callback);
      },
      label: 'Move',
    });
  }
  return buttons;
}
