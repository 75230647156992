import { createAsyncThunk } from '@reduxjs/toolkit';
import create from '../../../utils/storage';
import {
  saveSortOrder,
  selectTreeDataByCatalogId,
  updateData,
} from '../../slices/tree';
import { selectCatalog, selectCatalogs } from '../../slices/catalog';
import { toastMessage } from '../../slices/toaster';
import { moveNodeInTreeData } from './helpers';
import { selectIsGlobalMarket } from '../../slices/intl';
import { moveCategory as moveCategoryApi } from '../api/categories';

export const moveCategory = createAsyncThunk(
  'tree/moveCategory',
  async ({ direction, tree, nodeItem, callback }, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = getState();
    const { node } = nodeItem;
    const { catalogId } = node;
    const currentTree = selectTreeDataByCatalogId(state, catalogId);

    const { newParentId, oldParentId, treeData, placedAfterId } = moveNodeInTreeData(
      {
        direction,
        node,
        treeData: currentTree,
      },
    );

    if (selectIsGlobalMarket(state)) {
      await dispatch(
        moveCategoryApi({
          catalogId: node.catalogId,
          payload: [
            {
              categoryId: node.categoryId,
              oldParentCategoryId: oldParentId,
              newParentCategoryId: newParentId,
              placeAfterCategoryId: placedAfterId,
            },
          ],
        }),
      );
    } else {
      const move = {
        currentTree,
        newTree: treeData,
        catalogId,
        parentId: node.parentId,
        categoryId: node.categoryId,
      };

      dispatch(saveSortOrder(move));
    }

    dispatch(updateData(tree, treeData));
    callback();

    const thisCatalog = selectCatalog(state, catalogId);
    if (thisCatalog.catalogStatus !== 'DRAFT') {
      const otherCatalogs = selectCatalogs(state).filter(
        (catalog) => catalog.catalogId !== catalogId,
      );
      if (
        otherCatalogs.some(
          (catalog) =>
            catalog.catalogName === thisCatalog.catalogName &&
            catalog.catalogStatus === 'DRAFT',
        )
      ) {
        const storage = create(sessionStorage, 'draft_catalog_warning');
        if (!storage.readData('shown')) {
          dispatch(
            toastMessage(
              `This catalog also exists as a draft. Be aware that any changes might be lost when the draft is published.`,
            ),
          );
          storage.storeData('shown', true);
        }
      }
    }
  },
);
