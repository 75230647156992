import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { Catalog } from '../../../entities/Catalog';
import { comparer } from '../../../utils/catalog/comparer';
import { setMarket } from '../../actions/intl';
import type { RootState } from '../../index';
import { mergeStateReducerBuilder } from '../../reducers/generic/mergeState';

const catalogEntityAdapter = createEntityAdapter<Catalog, string>({
  selectId: (catalog) => catalog.catalogId,
  sortComparer: comparer,
});

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: catalogEntityAdapter.getInitialState(),
  reducers: {
    catalogReceived(state, action) {
      catalogEntityAdapter.setOne(state, action.payload);
    },
    catalogsReceived(state, action) {
      catalogEntityAdapter.setAll(state, action.payload);
    },
    removeCatalogs: {
      prepare(...catalogIds: string[]) {
        return { payload: catalogIds, meta: null, error: null };
      },
      reducer(state, action) {
        const catalogIds = action.payload;
        catalogEntityAdapter.removeMany(state, catalogIds);
      },
    },
  },
  extraReducers: (builder) =>
    mergeStateReducerBuilder('state.catalog', builder).addCase(setMarket, () =>
      catalogEntityAdapter.getInitialState(),
    ),
});

export const { catalogReceived, catalogsReceived, removeCatalogs } =
  catalogSlice.actions;
export default catalogSlice.reducer;

export const {
  selectAll: selectCatalogs,
  selectById: selectCatalog,
  selectEntities: selectCatalogEntities,
} = catalogEntityAdapter.getSelectors((state: RootState) => state.catalog);
