import { isAnyOf } from '@reduxjs/toolkit';
import { getNodeInTree, updateNodeInTree } from '../../../components/Tree/helpers';
import { isItemNo } from '../../../utils/data/utils';
import { extend } from '../../../utils/object';
import {
  expandNode,
  pasteNode,
  search,
  selectSearchConditionForTree,
  selectTreeDataByCatalogId,
  updateData,
} from '.';
import { getCatalogId, makeTreeId } from '../../../utils/tree/ids';
import { SEARCH_BY } from '../../../utils/tree/search';
import { destructId, getPathById } from '../../../utils/tree/treeNode';
import {
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NODE_TYPE_MULTI_SELECT,
} from '../../../utils/types/nodeTypes';
import { analyticsEvent } from '../../actions/analytics/analytics';
import { fetchChildNodes } from '../../actions/nodes/fetchChildNodes';
import { clearPopulateTreeState } from '../../actions/nodes/prePopulateTreeState';
import { selectNode } from '../../actions/nodes/selectNode';
import { deleteCategories } from '../../actions/tree/deleteCategories';
import { removeTreeControlsOfType } from '../../actions/tree/removeTreeControls';
import {
  searchItemAndAddToCatalog,
  searchNonSearchableCatalogs,
} from '../../actions/tree/search';
import { treeRemoveItems } from '../../actions/tree/updates';
import { selectSearchableCatalogIds } from '../../selectors/catalog/ids';
import { selectedTreeNodeSelector } from '../../selectors/tree/node';
import { selectPrestate } from '../prestate';
import {
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../../../utils/types/catalogTypes';
import { ANALYTICS_SEARCH } from '../../actions/analytics/analyticsCategories';

export const treeSearchListenerOptions = {
  actionCreator: search,
  effect: (action, listenerApi) => {
    const { dispatch, getState } = listenerApi;
    const state = getState();
    const {
      meta: { tree },
    } = action;
    const { by, condition } = selectSearchConditionForTree(state, tree);
    const searchableCatalogIds = selectSearchableCatalogIds(state);

    const catalogId = getCatalogId(tree);
    const isItemNumber = isItemNo(condition);

    if (
      searchableCatalogIds.includes(catalogId) &&
      by === SEARCH_BY.TEXT &&
      isItemNumber
    ) {
      dispatch(searchItemAndAddToCatalog(tree, condition));
    } else if (catalogId === CATALOG_TYPE_UNCATEGORIZED && isItemNumber) {
      dispatch(searchNonSearchableCatalogs(tree, condition, 'uncategorised'));
    } else if (catalogId === CATALOG_TYPE_NOTVALIDFORSTRUCTURE && isItemNumber) {
      dispatch(searchNonSearchableCatalogs(tree, condition, 'notValidForStructure'));
    }

    dispatch(
      analyticsEvent({
        category: ANALYTICS_SEARCH,
        action: 'Search for item/catalog',
        label: `Executed the query search by: ${by}, condition: ${condition} in tree ${tree}`,
      }),
    );
  },
};

const typesWithAsyncContent = [NODE_TYPE_CATEGORY, NODE_TYPE_ITEM];
export const treeExpandNodeListenerOptions = {
  actionCreator: expandNode,
  effect: (action, listenerApi) => {
    if (typesWithAsyncContent.includes(action.payload.treeNode?.node?.type)) {
      const { dispatch } = listenerApi;
      const {
        meta: { tree },
        payload: { treeNode },
      } = action;
      dispatch(fetchChildNodes(tree, treeNode));
    }
  },
};

function updateTreeState({ dispatch, getState }, treeState) {
  const [property, state] = Object.entries(treeState)[0];
  if (!property || !state) {
    return;
  }
  const { catalogId } = destructId(state[0]);
  const treeData = selectTreeDataByCatalogId(getState(), catalogId);
  if (
    !Array.isArray(treeData) ||
    !treeData.length ||
    !getNodeInTree({ treeData, path: getPathById(state[0]) })
  ) {
    return;
  }

  const updatedTreeData = state.reduce((td, nodeId) => {
    const path = getPathById(nodeId);
    const { node } = getNodeInTree({ treeData, path });
    return updateNodeInTree({
      node: extend(node, { expanded: true }),
      treeData: td,
      path,
    });
  }, treeData);

  dispatch(clearPopulateTreeState(property));
  dispatch(updateData(makeTreeId(null, catalogId), updatedTreeData));
}

export const treeUpdateDataListenerOptions = {
  actionCreator: updateData,
  effect: (action, listenerApi) => {
    const { dispatch, getState } = listenerApi;
    const state = getState();
    let catalogId;
    let updatedNode;
    const { meta } = action;
    if (meta.selectNodeAtPath) {
      const selected = selectedTreeNodeSelector(state);
      if (
        selected.catalogId !== getCatalogId(meta.tree) ||
        selected.nodeItem?.path.join('#') !== meta.selectNodeAtPath.join('#')
      ) {
        return;
      }
      catalogId = selected.catalogId;
      updatedNode = getNodeInTree({
        treeData: selectTreeDataByCatalogId(state, catalogId),
        path: meta.selectNodeAtPath,
      });
    } else {
      const prestate = selectPrestate(state);
      if (prestate) {
        const { selectedNode, sourceTreeState, targetTreeState } = prestate;

        const path = selectedNode?.path;
        catalogId = selectedNode?.catalogId;
        if (path && catalogId) {
          updatedNode = getNodeInTree({
            treeData: selectTreeDataByCatalogId(state, catalogId),
            path,
          });
          if (updatedNode) {
            dispatch(clearPopulateTreeState('selectedNode'));
          }
        }
        if (Array.isArray(sourceTreeState)) {
          updateTreeState(listenerApi, { sourceTreeState });
        }
        if (Array.isArray(targetTreeState)) {
          updateTreeState(listenerApi, { targetTreeState });
        }
      }
    }
    if (updatedNode) {
      const tree = makeTreeId(null, catalogId);
      dispatch(selectNode(tree, updatedNode, true));
    }
  },
};

export const treeActionsListenerOptions = {
  matcher: isAnyOf(deleteCategories, treeRemoveItems, pasteNode),
  effect: (action, listenerApi) => {
    const { dispatch } = listenerApi;
    const {
      meta: { tree },
    } = action;
    if (tree) {
      dispatch(removeTreeControlsOfType(tree, NODE_TYPE_MULTI_SELECT));
    }
  },
};
