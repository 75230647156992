export const STATE_AVAILABLE_TO_MARKET = 'AVAILABLE';
export const STATE_NOT_AVAILABLE_TO_MARKET = 'NOT_AVAILABLE';
export const STATE_NEW = 'NEW';
export const STATE_UNPUBLISHED = 'UNPUBLISHED';
export const STATE_PUBLISH_DATE_GLOBAL = 'PUBLISH_DATE_GLOBAL';
export const STATE_FUTURE_PUBLISH_DATE = 'FUTURE_PUBLISH_DATE';
export const STATE_PUBLISHED = 'PUBLISHED';

export const STATE_UNTRANSLATED = 'UNTRANSLATED';
export const STATE_VERIFIED = 'VERIFIED';
export const STATE_NOT_VERIFIED = 'NOT_VERIFIED';
export const STATE_NOT_VERIFIED_AUTO_TRANSLATED = 'NOT_VERIFIED_AUTO_TRANSLATED';
export const STATE_NOT_VERIFIED_RENAMED = 'NOT_VERIFIED_RENAMED';

export const STATE_AUTOCATEGORISABLE = 'AUTOCATEGORISABLE';

export const STATE_CATEGORY_TYPE_SYSTEM = 'CATEGORY_TYPE_SYSTEM';
export const STATE_CATEGORY_TYPE_ACCESSORIES = 'CATEGORY_TYPE_ACCESSORIES';
export const STATE_CATEGORY_TYPE_FURNITURE = 'CATEGORY_TYPE_FURNITURE';
export const STATE_CATEGORY_TYPE_UNDEFINED = 'CATEGORY_TYPE_UNDEFINED';

export const STATE_INVALID_CATEGORY_IMAGE = 'INVALID_CATEGORY_IMAGE';
