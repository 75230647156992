import { ReadOnlyArray } from '../../array/ReadOnlyArray';
import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class AutomatedTreeConfig extends DefaultTreeConfig {
  constructor(props: TreeConfigProps) {
    super(props);
    this.allowedSources = ReadOnlyArray.Empty;
    this.editable = false;
    this.indentTree = false;
    this.allowHoverActions = props.isGlobalEditor;
    this.allowRanking = props.isGlobalEditor;
  }
}
