import { CatalogCategoriesFromDTO } from '../../store/mappers/treeDto';
import { NON_NODE_ITEM_TYPES } from '../types/nodeTypes';
import { createId, destructId } from './treeNode';

export const ROOT_NODE_ID = '0';

type NodeInputData = {
  categoryId: string;
  id?: string;
  invalidCategoryImage?: boolean;
  allowChildItems?: boolean;
  suggestionId?: string; // suggestion node
  suggestion?: {
    // suggestion node
    suggestionId: string;
    childItems: string[];
    categories: CatalogCategoriesFromDTO['categories'][string][];
  };
  title: string;
  catalog?: CatalogCategoriesFromDTO['catalog']; // catalog node
  catalogType?: string; // catalog node
  category: CatalogCategoriesFromDTO['categories'][string];
  group?: { childItemCount: number };
  children: NodeInputData[];
  expanded?: boolean;
  level: number;
  parentId?: string;
  isLeaf?: boolean;
  role?: string;
  tooltip?: string;
  isNew?: boolean;
  isUnpublished?: boolean;
  type?: string;
};

type TreeNode = NodeInputData & {
  key: string;
  id: string;
  catalogId: string;
};

export function createNewNode(
  catalogId: string,
  contentId: string,
  type: string,
  {
    allowChildItems = true,
    children = [],
    expanded = false,
    isLeaf = false,
    level = 0,
    parentId = ROOT_NODE_ID,
    role = 'treeitem',
    title,
    tooltip = undefined,
    ...rest
  }: NodeInputData,
  useParentId?: string,
): TreeNode {
  const id = createId({ parentId: useParentId, type, contentId });

  return {
    ...rest,
    allowChildItems,
    catalogId,
    children,
    expanded: !!expanded,
    id,
    isLeaf,
    key: id,
    level,
    parentId,
    role,
    title,
    tooltip,
    type,
  };
}

export function createExtendedNode(
  id: string,
  node: NodeInputData,
  rest: Record<string, unknown>,
) {
  return {
    ...node,
    ...rest,
    id,
    key: id,
  };
}

export const createUpdatedNode = ({
  node,
  nextParentNode,
  props,
}: {
  node: NodeInputData;
  nextParentNode: { id: string; catalogId: string; level: number };
  props?: Record<string, unknown>;
}) => {
  const parentId = nextParentNode.id;
  const contentId = destructId(node.id).value;
  const id = createId({ parentId, type: node.type, contentId });
  if (NON_NODE_ITEM_TYPES.includes(node?.type || '')) {
    return createExtendedNode(id, node, { parentId });
  }

  const { catalogId } = nextParentNode;
  const level = nextParentNode.level + 1;
  const newNode = createExtendedNode(id, node, {
    ...props,
    catalogId,
    children: node.children?.map((c) =>
      createUpdatedNode({ node: c, nextParentNode: { id, catalogId, level } }),
    ),
    parentId,
    level,
  });
  return newNode;
};

// checkable?: boolean;
// children?: DataNode[];
// disabled?: boolean;
// disableCheckbox?: boolean;
// icon?: IconType;
// isLeaf?: boolean;
// key: string | number;
// title?: React.ReactNode;
// selectable?: boolean;
// switcherIcon?: IconType;
// /** Set style of TreeNode. This is not recommend if you don't have any force requirement */
// className?: string;
// style?: React.CSSProperties;
