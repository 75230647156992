import { VALID_PUBLISHED_STATES } from '../category/defines';
import {
  STATE_UNPUBLISHED,
  STATE_UNTRANSLATED,
  STATE_VERIFIED,
} from '../category/states';

export function getMissing(treeData, category) {
  const found = getNode(
    treeData,
    (node) => node?.categoryId === category?.categoryId,
  );
  const set = new Set([]);
  if (found) {
    childItemsCount(found, set);
  }
  const count = set.size - (category?.childItemCount || 0);
  const hasSubCategories = count > 0;
  return { count, hasSubCategories };
}

export function getUnpublished(categoriesStatus, category) {
  const isPublished = VALID_PUBLISHED_STATES.includes(category?.publishState);
  const count =
    (categoriesStatus[STATE_UNPUBLISHED]?.[category?.categoryId] || 0) -
    (isPublished ? 0 : 1);
  const hasSubCategories = count > 0;
  return { count, hasSubCategories };
}

export function getUntranslated(categoriesStatus, category) {
  const isTranslated = category?.translateState === STATE_VERIFIED;
  const count =
    (categoriesStatus[STATE_UNTRANSLATED]?.[category?.categoryId] || 0) -
    (isTranslated ? 0 : 1);
  const hasSubCategories = count > 0;
  return { count, hasSubCategories };
}

function getNode(treeData, func) {
  for (let i = 0; i < treeData.length; i++) {
    const element = treeData[i];
    if (func(element)) {
      return element;
    }
    const childElement = getNode(element.children, func);
    if (func(childElement)) {
      return childElement;
    }
  }
  return null;
}

function childItemsCount(node, set) {
  if (node.category?.childItemCount) {
    return node.children.forEach((itemNode) => {
      set.add(itemNode.item.itemId);
    });
  }
  return node.children.forEach((categoryNode) => {
    childItemsCount(categoryNode, set);
  });
}
