import { withSearchResults } from '../../../components/Tree/helpers';
import { ReadOnlyArray } from '../../../utils/array/ReadOnlyArray';

export function clearSearch(treeData = []) {
  const searchFocusId = null;
  const searchFocusIndex = null;
  const searchMatches = ReadOnlyArray.Empty;

  return {
    searchCondition: null,
    searchFocusId,
    searchFocusIndex,
    searchMatches,
    searchSuggestions: ReadOnlyArray.Empty,
    searchQuery: null,
    treeData: withSearchResults({
      treeData,
      searchFocusId,
      searchFocusIndex,
      searchMatches,
    }),
  };
}
