import React from 'react';
import cx from 'classnames';
import CatalogIcon from '../../../Icons/CatalogIcon';
import styles from './TreeSelectorNode.module.scss';
import { Catalog } from '../../../../entities/Catalog';

const TreeSelectorNode = function TreeSelectorNode({
  catalog,
  text,
  meta,
}: {
  catalog: Catalog;
  text: string;
  meta: {
    total: number;
    status: JSX.Element | null;
    indicators: JSX.Element | null;
  };
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <span className={styles.icon}>
          <CatalogIcon className={styles.icon} catalogType={catalog.catalogType} />
        </span>
        <span className={styles.text}>{text}</span>
      </div>
      <div className={cx(styles.block, styles.status)}>
        <span className={styles.icon} />
        <span className={styles.meta}>
          {meta.indicators}
          {meta.status}
          {meta.total || ''}
        </span>
      </div>
    </div>
  );
};

export default TreeSelectorNode;
