import Cogs from '../../svg/Cogs';
import Badge from './Badge';
import styles from './StatusBadges.module.scss';

export const AutoCategorisedBadge = function AutoCategorisedBadge({
  compact = false,
}: {
  compact?: boolean;
}) {
  return (
    <Badge
      tooltip="Auto-categorisable"
      CustomIcon={Cogs}
      label="Auto-categorisable"
      compact={compact}
      className={styles.autocategorized}
    />
  );
};
