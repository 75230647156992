import { Catalog } from '../../entities/Catalog';
import { isEqualByProps } from '../object/compare';
import {
  CATALOG_STATUS_ACTIVE,
  CATALOG_STATUS_DRAFT,
} from '../statuses/catalogStatus';
import * as catalogTypes from '../types/catalogTypes';

export const editableProps = [
  'activationDate',
  'catalogIdentifierTranslations',
  'availableForMarkets',
] as const;

export const STATUS = {
  ANY: '',
  DRAFT: CATALOG_STATUS_DRAFT,
  LIVE: CATALOG_STATUS_ACTIVE,
} as const;

type CatalogStatuses = (typeof STATUS)[keyof typeof STATUS];

export const getCatalogTypeFromDto = ({
  catalogueType,
}: {
  catalogueType: string;
}) =>
  Object.values(catalogTypes).find((value) => value === catalogueType) ||
  catalogTypes.CATALOG_TYPE_UNCATEGORIZED;

export const isAutomatedCatalog = (catalog: Catalog) =>
  isCatalogOfType(catalogTypes.CATALOG_TYPE_AUTOMATED, catalog);

export const isMasterCatalog = (catalog: Catalog, status?: CatalogStatuses) =>
  isCatalogOfType(catalogTypes.CATALOG_TYPE_MASTER, catalog, status);

export const isThemesCatalog = (catalog: Catalog, status?: CatalogStatuses) =>
  isCatalogOfType(catalogTypes.CATALOG_TYPE_THEMES, catalog, status);

export const isSeriesCatalog = (catalog: Catalog, status?: CatalogStatuses) =>
  isCatalogOfType(catalogTypes.CATALOG_TYPE_SERIES, catalog, status);

export const isCollectionCatalog = (catalog: Catalog, status?: CatalogStatuses) =>
  isCatalogOfType(catalogTypes.CATALOG_TYPE_COLLECTION, catalog, status);

export const isSeriesOrCollectionCatalog = (catalog: Catalog) =>
  isCollectionCatalog(catalog, '') || isSeriesCatalog(catalog, '');

export const isCatalogDraft = (catalog: Catalog) =>
  isCatalogOfStatus(catalog, STATUS.DRAFT);

export const isCatalogLive = (catalog: Catalog) =>
  isCatalogOfStatus(catalog, STATUS.LIVE);

function isCatalogOfType(type: string, catalog: Catalog, status?: CatalogStatuses) {
  return isCatalogOfStatus(catalog, status) && catalog.catalogType === type;
}

function isCatalogOfStatus(catalog: Catalog, status?: CatalogStatuses) {
  return !status || catalog.catalogStatus === status;
}

export function isCatalogsEqual(first: Catalog, second: Catalog) {
  return isEqualByProps(first, second, ...editableProps);
}
