import { createSlice } from '@reduxjs/toolkit';
import { createReferenceEqualSelector } from '../../selectors/utils';
import type { RootState } from '../../index';

interface AppState {
  LOADING: boolean;
  STARTED: boolean;
  FAILED: boolean;
}

const initialState = {
  LOADING: true,
  STARTED: false,
  FAILED: false,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState: initialState as AppState,
  reducers: {
    appStart(state) {
      return {
        ...state,
        LOADING: true,
      };
    },
    appStartFailed(state) {
      return {
        ...state,
        FAILED: true,
        LOADING: false,
      };
    },
    appStartSuccess() {
      return {
        LOADING: false,
        STARTED: true,
        FAILED: false,
      };
    },
  },
});

export const { appStart, appStartFailed, appStartSuccess } = appSlice.actions;
export default appSlice.reducer;

const appSelector = (state: RootState) => state.app;

export const selectAppIsLoading = createReferenceEqualSelector(
  appSelector,
  (state) => !!state.LOADING,
);
export const selectAppIsStarted = createReferenceEqualSelector(
  appSelector,
  (state) => !!state.STARTED,
);

export const selectAppHasFailed = createReferenceEqualSelector(
  appSelector,
  (state) => !!state.FAILED,
);
