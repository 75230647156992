import InlineMessage from '@ingka/inline-message';
import FakeHeader from '../Header/FakeHeader';
import { useSelector } from 'react-redux';
import { selectErrors } from '../../store/slices/error';
import styles from './AppStartFailed.module.scss';

export const AppStartFailed = () => {
  const errors = useSelector(selectErrors);

  return (
    <>
      <FakeHeader />
      <InlineMessage
        className={styles.banner}
        variant="negative"
        title="Failed to load Kategorisera, please try to reload the application."
        body={
          <>
            If the problem persists contact #rc-range-categorisation-consumers
            <span className={styles.list}>
              {errors.map((error, index) => (
                <span key={index}>{error}</span>
              ))}
            </span>
          </>
        }
      ></InlineMessage>
    </>
  );
};
