import { NODE_TYPE_ITEM } from '../../../utils/types/nodeTypes';
import { walkTree } from '.';

export function getUncategorizedItems(treeData) {
  const items = [];
  const callback = ({ node }) => {
    if (node.type === NODE_TYPE_ITEM) {
      items.push(node.item);
    }
  };
  walkTree({ callback, ignoreCollapsed: false, treeData });
  return items;
}
